import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private http: HttpClient
  ) {
  }

  createTicket(params: any) {
    let url = environment.serverUrl + '/api/ticket/add';
    return this.http.post(url, params).toPromise()
  }

  getMoreTickets(page) {
    let url = `${environment.serverUrl}/api/mcservices/ticket`
    if (page)
      url += `?page=${page}`
    return this.http.get(url).toPromise()
  }

  getAllServices() {
    let url = `${environment.serverUrl}/api/ticket/services`
    return this.http.get(url).toPromise()
  }

  generateTicketListComponent(params) {
    let data = params.data
    let metadata = Object.assign(params)
    delete metadata.data
    let output = []
    let childOutput = []

    let ticketListComponent = {
      is_core_component: true,
      content: null,
      subcontent: null,
      child: [],
      type: 'ticketList',
      customFields: null
    }

    for (let item of data) {
      let defaultStructure = {
        is_core_component: true,
        content: null,
        subcontent: null,
        type: 'ticket',
        customFields: item
      }
      childOutput.push(defaultStructure)
    }
    ticketListComponent.child = childOutput
    output.push(ticketListComponent)
    return output
  }

}
