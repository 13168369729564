import { Component, OnInit, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileItem } from 'ng2-file-upload';
import * as Entities from 'html-entities';
const entities = new Entities.XmlEntities();
const Compress = require('compress.js');
const compress = new Compress();
import * as _ from 'underscore';

import {
  LoggerService,
  ChatboxService,
  MicrophoneService,
  SessionService,
  ModalService,
  TranslationService,
  UploaderService,
} from '../../services';

@Component({
  selector: 'app-inputbox',
  templateUrl: './inputbox.component.html',
  styleUrls: ['./inputbox.component.css'],
})

export class InputboxComponent implements OnInit {

  userInput: string = '';
  oriInput: string = '';
  INPUTPLACEHOLDER: string = '';
  isDisabled: boolean = false;
  alternate_intents: any;
  liveChat: boolean;
  isRecording: boolean = false;
  oriLength: number;
  iconClass: string = 'inputBoxButtons';
  micCompatibility: boolean = true;
  inputBoxClass: string = 'inputBoxStyle';
  sending: boolean = false;
  showLiveChatMessage: boolean = false;
  navigation: string;
  channel: string = '';
  intentConfidence: string = '#null:0';
  isProd: boolean = this.chatboxService.isProd();
  isLiveChat: boolean = false;
  isEnded: boolean = false;
  subs = [];
  tempFiles: any = [];
  public uploader: any;
  fileIndex: number = 0;
  imageExtentions: any = ['png', 'jpg', 'jpeg']
  public skin: string;


  constructor(
    private chatboxService: ChatboxService,
    private microphoneService: MicrophoneService,
    private translationService: TranslationService,
    private sessionService: SessionService,
    private elementRef: ElementRef,
    private modalService: ModalService,
    private logger: LoggerService,
    private uploaderService: UploaderService,
  ) {
    this.initSubscriptions()
    this.initTranslation()
  }

  async initSubscriptions() {
    this.subs.push(this.chatboxService.$chatRecord.subscribe((res) => {
      if (res.context) {
        this.intentConfidence = '#null:';
        let intents = []
        if (res.context.strongIntents) {
          intents = res.context.strongIntents
        }
        if (res.context.moderateIntents) {
          intents = intents.concat(res.context.moderateIntents)
        }
        for (let i in intents) {
          let intent = intents[i]
          if (intent) {
            if (0 !== parseInt(i))
              this.intentConfidence += " alt:";
            else
              this.intentConfidence = ''

            let score
            if (intent.confidence)
              score = intent.confidence.toFixed(4)
            this.intentConfidence += `${intent.intent}:${score}`;
          }
        }
        this.intentConfidence += ` CMS:${res.context.respKeyIndex}`
      }
    }))

    this.subs.push(this.sessionService.$isLiveChat.subscribe((res) => {
      if (res === 'ended') {
        this.isEnded = true
      }
      this.isLiveChat = res
    }))

    this.subs.push(this.chatboxService.$isDisabled.subscribe((flag: boolean) => {
      this.isDisabled = flag
    }))

    this.subs.push(this.sessionService.$isQueing.subscribe((flag: boolean) => {
      this.showLiveChatMessage = flag;
    }))
  }

  openSurveyModal() {
    this.modalService.openModal('review')
  }

  async initTranslation() {
    if (!this.translationService.translateText('SETTINGS.INPUTPLACEHOLDER')) {
      return setTimeout(() => {
        this.initTranslation()
      }, 250)
    }
    this.INPUTPLACEHOLDER = this.translationService.translateText('SETTINGS.INPUTPLACEHOLDER')
  }

  async initUploader() {
    this.uploader = new FileUploader({
      url: `${environment.serverUrl}/api/uploader/scan`,
      authToken: this.sessionService.getConversationID(),
      parametersBeforeFiles: true,
      autoUpload: true,
      // additionalParameter: { url: `${ environment.serverUrl } / api / uploader / upload` }
    });
    this.uploader.onAfterAddingFile = async (file: any) => {
      this.logger.info(file);
      // this.logger.info(file.file.rawFile)
      const arrayBuffer = await new Response(file._file).arrayBuffer();
      const base64FromBlob = this.uploaderService.base64ArrayBuffer(arrayBuffer)
      let extension = file.file.name.split(".")
      let sizeInMB = file.file.size / 1000 / 1000;
      let size = 2, quality = .80
      if (sizeInMB < 1.5) {
        quality = 1
      }
      if (this.imageExtentions.indexOf(extension[extension.length - 1].toLowerCase()) > -1) {
        let results = await compress.compress([file.file.rawFile], {
          size: size,
          quality: quality,
        })
        for (var i = 0; i < results.length; ++i) {
          const img = results[i]
          const base64str = img.data
          const imgExt = img.ext
          const compressedFile = Compress.convertBase64ToFile(base64str, imgExt)
          file.file.rawFile = compressedFile
          file.file.id = this.fileIndex
          file._file = compressedFile;
          this.tempFiles.push({
            content: img.prefix + img.data,
            filename: img.alt,
            status: 'pending',
            id: file.file.id
          })
        }
      } else {
        file.file.id = this.fileIndex
        this.tempFiles.push({
          content: `data:${file.file.type};base64, ${base64FromBlob}`,
          filename: file.file.name,
          status: 'pending',
          id: file.file.id
        })
      }
      this.fileIndex++;
      file.withCredentials = false;
    };
    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      if (!response) {
        let eMessage = 'scanning failed, please try again later'
        this.logger.error(eMessage)
        alert(eMessage)
        return
      }

      try {
        let resp = JSON.parse(response)
        let foundTempFile = _.find(this.tempFiles, { id: item.file.id })
        if (!resp.ok) {
          foundTempFile.status = 'failed'
          console.error('scanning failed, please try again later')
          return
        }
        if (!resp.isInfected) {
          resp.content = `data:${resp.filetype};base64, ${resp.base64str}`
          resp.filename = item.file.name
          if (foundTempFile) {
            foundTempFile.status = 'success'
            foundTempFile.uid = resp.uid
            foundTempFile.type = (resp.isImageType ? 'image' : 'file-others')
          }
        } else {
          let tempIdx = this.tempFiles.length
          this.tempFiles.push({
            content: ``,
            filename: item.file.name,
            status: 'success',
            type: 'invalid',
            id: 'virus' + tempIdx,
            class: 'virus-alert',
          })
          this.tempFiles[tempIdx].class += ' show'
          setTimeout(() => {
            if (this.tempFiles[tempIdx]) {
              this.tempFiles[tempIdx].class = 'virus-alert hide'
              setTimeout(() => {
                this.tempFiles.splice(tempIdx, 1)
              }, 1000)
            }
          }, 5000)
        }
      } catch (e) {
        console.error(e)
        console.error('scanning failed, please try again later')
      }
    }
  }

  async manualUpload() {
    let manualFiles = JSON.parse(JSON.stringify(this.tempFiles))
    this.tempFiles = []
    for (let tFile of manualFiles) {
      if (tFile.type === 'invalid')
        continue

      this.chatboxService.add({
        fromMe: true,
        id: `file-${tFile.id}`,
        status: 'pending',
        output: {
          json: [{
            type: tFile.type,
            content: tFile.content,
            subcontent: tFile.filename
          }]
        },
        type: 'image'
      })

      delete tFile.content
      let resp: any = await this.uploaderService.upload(tFile)
      let updated = _.find(this.chatboxService.chatRecord, { id: `file-${tFile.id}` })

      if (resp.ok) {
        if (updated) {
          updated.status = 'success';
          updated.output[0].content = resp.filepath;
        }
      } else {
        if (updated) {
          updated.status = 'failed';
        }
      }
    }
    this.chatboxService.parseMessageToLiveChat()
  }

  removePreview(idx) {
    // this.uploader.queue[idx].remove()
    this.tempFiles.splice(idx, 1)
  }



  ngOnInit() {
    this.micCompatibility = this.microphoneService.checkCompatibility();
    this.channel = this.sessionService.getSessionChannel();
    this.skin = this.sessionService.getSkin();
    if (this.skin === 'mc') {
      this.inputBoxClass = 'inputBoxStyle-customer';
    } else {
      this.inputBoxClass = 'inputBoxStyle-corp';
    }
    if (this.micCompatibility && !this.isProd) {
      this.inputBoxClass += ' withMic';
    }
  }

  cancelLiveChat() {
    //cancel or disconect from livechat either in queue or already in a session
    this.showLiveChatMessage = false;
    this.chatboxService.cancelLiveChat().subscribe((res) => {
      this.showLiveChatMessage = false;
    });
  }

  activateSpeechRecognition(): void {
    if (this.isDisabled)
      return;

    if (!this.oriLength)
      this.oriLength = this.userInput.length;

    this.isRecording = true;
    this.oriInput = this.userInput;
    this.microphoneService.record()
      .subscribe(
        (value) => {
          this.userInput = this.oriInput + value;
          this.sessionService.resetTimeout();
        },
        (err) => {
          this.logger.error(err);
          this.blink(false);
          if (err.error == "no-speech") {
            this.logger.error("--restarting service--");
            this.activateSpeechRecognition();
          }
        },
        //completion
        () => {
          this.blink(false);
          this.logger.info("--complete--");
          this.oriInput = String(this.userInput);
        });
  }

  getRandomIndexFromArray(array: any) {
    try {
      let length = array.length;
      return Math.floor(Math.random() * length);
    } catch (e) {
      console.error(e)
    }
  }

  promptUpload() {
    let fileUpload: any = this.elementRef.nativeElement.querySelector('#fileUpload');
    setTimeout(() => {
      this.initUploader();
      fileUpload.click();
    }, 300)
  }

  stopSpeechRecognition(): void {
    this.microphoneService.DestroySpeechObject();
    this.isRecording = false;
    this.blink(false);
  }

  ngOnDestroy() {
    this.microphoneService.DestroySpeechObject();
    this.isRecording = false;
    this.blink(false);

    this.subs.forEach((sub: any) => {
      sub.unsubscribe();
    })
  }

  validate(e: any) {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.sendMessage('');
      return false;
    }
    if (this.sessionService.getLiveChat())
      this.chatboxService.triggerTypingEvent();
    this.sessionService.resetTimeout();
  }

  sendMessage(message: string) {

    if (this.sessionService.getLiveChat() && message === 'hello') {
      return
    }

    this.microphoneService.DestroySpeechObject();
    this.isRecording = false;
    this.blink(false);
    let text = message || this.userInput.trim();
    text = entities.encode(text)

    let messageObj = {
      content: text,
      fromMe: true,
      type: 'text',
      id: `text-${this.chatboxService.chatRecord.length}`,
      status: 'pending',
      isButton: undefined,
      resetAll: undefined,
      isMainMenu: undefined,
    }

    if (message === 'hello') {
      messageObj.resetAll = true
      messageObj.isButton = true
      messageObj.isMainMenu = true
    }
    this.sending = true;

    if (text) {
      this.userInput = '';
      setTimeout(() => {
        this.userInput = '';
      }, 200)
      this.chatboxService.sendRequest(messageObj);
    }

    if (!text && this.tempFiles.length === 0) {
      this.modalService.openModal('emptyInput');
      this.userInput = '';
    }

    if (this.tempFiles) {
      let flag = true;
      this.tempFiles.map(x => {
        flag = flag && x.status !== 'pending' && x.status !== 'failed'
      })
      if (flag)
        this.manualUpload()
    }
  }

  blink(bool: boolean) {
    let aObj = this;
    setTimeout(() => {
      aObj.isRecording = false;
    }, 250)
  }

  translateText(key: string) {
    return this.translationService.translateText(key);
  }
}
