import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { SessionService } from './session.service';
import { LoggerService } from './logger.service';
import { environment } from '../../environments/environment';

@Injectable()
export class SocketService {

  public $liveChatListener: any;
  private socket: SocketIOClient.Socket;
  private pollingInterval: any;

  constructor(
    private sessionService: SessionService,
    private logger: LoggerService
  ) {
  }

  init() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        this.create()
        this.logger.info(`initialising websocket ${this.sessionService.getConversationID()}`);
        if (this.sessionService.getConversationID()) {
          let observable = new Observable(observer => {
            this.socket.on(this.sessionService.getConversationID(), data => {
              observer.next(data);
            });
            this.pollData()
          })
          this.$liveChatListener = observable;
          resolve();
        }
      }, 1000)
    })
  }

  pollData() {
    if (!this.pollingInterval) {
      clearInterval(this.pollingInterval)
    }
    this.pollingInterval = setInterval(() => {
      this.socket.emit('getHistory', function(data) {
      })
    }, 1500)
  }

  getInstance(): Observable<any> {
    return this.$liveChatListener;
  }

  close() {
    this.logger.info(`disconnecting websocket ${this.sessionService.getConversationID()}`);
    this.socket.disconnect();
  }

  create() {
    this.logger.info(`createing a new websocket connection ${this.sessionService.getConversationID()}`);
    this.socket = io(`${environment.serverUrl}?token=${this.sessionService.getSessionToken()}`, { secure: true });
  }

}
