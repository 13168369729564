import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ui-image-buttons',
  templateUrl: './ui-image-buttons.component.html',
  styleUrls: ['./ui-image-buttons.component.css']
})
export class UiImageButtonsComponent implements OnInit {

  @Input() model: any
  @Input() parent: any
  @Output() emitEvent = new EventEmitter<any>()

  constructor() { }

  ngOnInit() {

  }

  parseEmitEvent($event: string) {
    this.emitEvent.emit({ text: $event, isButton: true })
  }

}
