<div class="row">
  <div class="col folder-subcontent" [innerHtml]="model.subcontent"></div>
</div>

<div [ngClass]="highlighted">
  <div class="hyper-link-send-message folderSpacing">

    <div class="row" (click)="toggleCollapse()">
      <div class="col-1 chevron">
        <i class="fa fa-chevron-up" title="chevron top" aria-hidden="true" [hidden]="isCollapsed()"></i>
        <i class="fa fa-chevron-down" title="chevron bottom" aria-hidden="true" [hidden]="!isCollapsed()"></i>
      </div>
      <div class="col" [innerHtml]="model.content"></div>
    </div>
  </div>

  <div [ngbCollapse]="isCollapsed()" *ngIf="isLoaded">
    <div *ngFor="let child of model.child">
      <div *ngIf="child.type ==='text'">
        <app-ui-text [model]="child"></app-ui-text>
      </div>
      <app-ui-hyperlink *ngIf="child.type ==='hyperlink'" [model]="child" (emitEvent)="parseEmitEvent($event)"></app-ui-hyperlink>
      <app-ui-folder *ngIf="child.type ==='folder'" [model]="child" (emitEvent)="parseEmitEvent($event)"></app-ui-folder>
    </div>
  </div>
</div>
