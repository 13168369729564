import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from "rxjs";
import {
  ModalService,
  TranslationService,
  ChatboxService,
  LoggerService,
  SessionService,
  LivechatService,
} from '../../services';
declare var $;

@Component({
  selector: 'app-review-modal',
  templateUrl: './review-modal.component.html',
  styleUrls: ['./review-modal.component.css']
})

export class ReviewModalComponent implements OnInit {

  @ViewChild('reviewModal') reviewModal: ElementRef;
  @ViewChild('disconnectedModal') disconnectedModal: ElementRef;
  private modalSubscription: Subscription;
  private currentModal: any;
  public description: string;
  public THANKSOPERATOR: any = []
  private isSubmitting: boolean = false;
  surveys = []

  constructor(
    private ngbModal: NgbModal,
    private modalService: ModalService,
    private translationService: TranslationService,
    private chatboxService: ChatboxService,
    private logger: LoggerService,
    private sessionService: SessionService,
    private livechatService: LivechatService,
  ) {
  }

  ngOnInit() {
    this.modalSubscription = this.modalService.$modals.subscribe((modal: any) => {
      if (modal.event === "open") {
        if (modal.name === 'review') {
          this.initQuestions()
          this.currentModal = this.ngbModal.open(this.reviewModal, { size: 'lg', centered: true, keyboard: false, backdrop: "static" });
        }
      }
    })
  }

  async initQuestions() {
    this.surveys = []
    for (let username in this.livechatService.operators) {
      let questions = JSON.parse(JSON.stringify(this.livechatService.questions))
      let thanksNote = this.translate('REVIEW_MODAL.THANKS').replace(/<<operator>>/gi, this.livechatService.operators[username])
      this.surveys.push({ operator: username, questions: questions, title: thanksNote })
    }
  }

  markRating(question: any, rating: number): void {
    question.value = rating
  }

  setRadio(question: any, val: boolean): void {
    let flag = 0
    if (val) {
      flag = 1
    }
    question.value = flag
  }

  async sendRating(close: boolean) {
    this.isSubmitting = true

    if (this.isSubmitting) {
      this.isSubmitting = true
      this.logger.info(this.surveys)
      let resp = await this.livechatService.submitSurvey({ surveys: this.surveys })
      this.currentModal.close();
      this.chatboxService.$isBackgroundMode.next(false);
      let messageObj = {
        content: this.translationService.translateText('REVIEW_MODAL.THANKYOUREVIEW'),
        type: 'text',
        fromMe: false
      };
      this.chatboxService.add(messageObj);
      this.isSubmitting = false;
    }

  }

  translate(key: string) {
    return this.translationService.translateText(key);
  }

  ngOnDestroy() {
    if (this.modalSubscription)
      this.modalSubscription.unsubscribe();
  }

}
