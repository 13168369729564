<ng-template #followupModal let-c="close" size="lg" let-d="dismiss">
  <div class="modal-header">
    <span class="fa fa-lg fa-times pull-left" (click)="continue()"></span>
    <span class="modal-header-text">{{translate('FOLLOWUP_MODAL.TITLE')}}</span>
  </div>

  <div class="modal-body" style="padding=15px;">
    <div class="content">
      <span [innerHTML]="description | safehtml"></span>
    </div>
    <div class="sub-content" style="margin-top:15px;">
      <span [innerHTML]="translate('FOLLOWUP_MODAL.QUESTION_TYPE') | safehtml"></span>
    </div>
    <div class="input-box">
      <div class="dropdown-box">
        <select id="feedback-theme-select" name="feedback-theme-select" class="dropdown" [(ngModel)]="topic">
          <option *ngFor="let option of followUpOptions" [value]="option.title" [disabled]="option.disabled">{{option.title}}</option>
        </select>
      </div>
      <span class="dropdown-icon">
        <svg viewBox="0 0 18 18" role="presentation" aria-hidden="true" focusable="false" style="height: 16px; width: 16px; display: block; fill: rgb(72, 72, 72);">
          <path d="m16.29 4.3a1 1 0 1 1 1.41 1.42l-8 8a1 1 0 0 1 -1.41 0l-8-8a1 1 0 1 1 1.41-1.42l7.29 7.29z" fill-rule="evenodd"></path>
        </svg>
      </span>
    </div>

    <div class="textarea-border">
      <div style="margin-bottom: 8px;margin-top:15px;">
        <label class="textarea-text" for="feedback-text-area">
          <div class="sub-content">
            <span>{{translate('FOLLOWUP_MODAL.DESCRIPTION')}}</span>
          </div>
        </label>
      </div>
      <textarea class="textarea-box" id="feedback-text-area" name="feedback-text-area" [ngModel]="content" (ngModelChange)="validator('content',$event)" placeholder="{{translate('FOLLOWUP_MODAL.DESCRIPTION_PLACEHOLDER')}}" rows="6" maxlength="1500"></textarea>
    </div>

    <div class="sub-content" style="margin-top:15px;">
      <span>{{translate('FOLLOWUP_MODAL.CONTACT_METHOD')}}</span>
    </div>

    <div class="input-box">
      <div class="dropdown-box"><select id="feedback-theme-select" name="feedback-theme-select" class="dropdown" [(ngModel)]="type">
          <option value="" disabled="">{{translate('FOLLOWUP_MODAL.CHOICE')}}</option>
          <option value="email">{{translate('FOLLOWUP_MODAL.EMAIL')}}</option>
          <option value="phone">{{translate('FOLLOWUP_MODAL.PHONE')}}</option>
        </select>
      </div>
      <span class="dropdown-icon"><svg viewBox="0 0 18 18" role="presentation" aria-hidden="true" focusable="false" style="height: 16px; width: 16px; display: block; fill: rgb(72, 72, 72);">
          <path d="m16.29 4.3a1 1 0 1 1 1.41 1.42l-8 8a1 1 0 0 1 -1.41 0l-8-8a1 1 0 1 1 1.41-1.42l7.29 7.29z" fill-rule="evenodd"></path>
        </svg></span>
    </div>
    <input class="input-box" type="text" name="FirstName" style="padding: 11px;" [placeholder]="type" [ngStyle]="{'background-color':type=='' ? 'rgb(235, 235, 235)' : 'rgb(255, 255, 255)'}" [disabled]="type==''" [ngModel]="reply" (ngModelChange)="validator('reply',$event)" />
    <div class="modal-footer">
      <button type="button" class="btn btn-md custom-button" [disabled]="disable()" aria-busy="false" (click)="submit()">
        <span>{{translate('FOLLOWUP_MODAL.SUBMIT')}}</span>
      </button>
    </div>
  </div>
</ng-template>
