import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslationService } from '../../services/translation.service'
import { McservicesService } from '../../services/mcservices.service'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-ui-service-list',
  templateUrl: './ui-service-list.component.html',
  styleUrls: ['./ui-service-list.component.css']
})
export class UiServiceListComponent implements OnInit {

  @Input() model: any;
  @Input() parent: any;
  filteredModel: any = [];
  currentLength: number = 0;
  maxLength: number = 0;
  showMore: boolean = false;
  limit: number = 5;
  constructor(
    private translationService: TranslationService,
    private mcservicesService: McservicesService,
    private sessionService: SessionService,
  ) { }

  ngOnInit() {
    if (this.model) {
      this.limit = this.sessionService.getListItemPerPage()
      this.maxLength = this.model.child.length
      this.runPagination()
    }
  }

  viewMore() {
    this.runPagination()
  }

  async runPagination() {
    for (let i = this.currentLength; i < this.currentLength + this.limit; ++i) {
      if (this.model.child[i]) {
        this.filteredModel.push(this.model.child[i])
      }
    }

    this.currentLength = Math.min(this.model.child.length, this.currentLength + this.limit)
    if (this.currentLength >= this.maxLength && this.model.customFields.next) {
      let page = +this.model.customFields.page
      let moreContent
      let moreModel
      switch (this.model.customFields.type) {
        case 'mpls':
          moreContent = await this.mcservicesService.getMPLSService(++page)
          moreModel = await this.mcservicesService.generateServiceListComponent(moreContent)
          moreModel = moreModel[0]

          this.model.customFields = moreModel.customFields
          this.model.child = this.model.child.concat(moreModel.child)
          break;
        case 'internet':
          moreContent = await this.mcservicesService.getInternetService(++page)
          moreModel = await this.mcservicesService.generateServiceListComponent(moreContent)
          moreModel = moreModel[0]

          this.model.customFields = moreModel.customFields
          this.model.child = this.model.child.concat(moreModel.child)
          break;
        case 'datahouse':
          moreContent = await this.mcservicesService.getDataHouseService(++page)
          moreModel = await this.mcservicesService.generateServiceListComponent(moreContent)
          moreModel = moreModel[0]

          this.model.customFields = moreModel.customFields
          this.model.child = this.model.child.concat(moreModel.child)
          break;
        case 'security':
          moreContent = await this.mcservicesService.getSecurityService(++page)
          moreModel = await this.mcservicesService.generateServiceListComponent(moreContent)
          moreModel = moreModel[0]

          this.model.customFields = moreModel.customFields
          this.model.child = this.model.child.concat(moreModel.child)
          break;
      }
      this.maxLength = this.model.child.length

    } else {
      if (this.currentLength < this.maxLength) {
        this.showMore = true
      } else {
        this.showMore = false
      }
    }
  }

  translate(key: string) {
    return this.translationService.translateText(key)
  }

}
