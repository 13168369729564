<div class="modal-container">

  <div class="modal-nav-bar">
    <div class="close-icon" (click)="closeModal()">
      <img class="svg-cross" src="/assets/images/cross.svg" />
    </div>
    <h5>{{i18nWords['CHANGE_REQUEST.LABEL_CONFIGURATION_CHANGE']}} > {{i18nWords['CHANGE_REQUEST.LABEL_TRUST']}}</h5>
  </div>

  <form [formGroup]="changeRequestFormGroup" (ngSubmit)="submitForm()" #formDir="ngForm">
    <ngb-accordion id="step1Accordion" [closeOthers]="true" activeIds="panel-contact" #acc="ngbAccordion" (panelChange)="updateChevron($event, acc)">
      <ngb-panel id="panel-contact" class="panel-list">
        <ng-template ngbPanelTitle>
          <div class="font-weight-bold leftAligned panel-title">{{i18nWords['CHANGE_REQUEST.LABEL_CONTACT_INFORMATION']}}</div>
          <img class="panel-arrow-down" src="/assets/images/chevron-up.svg" [hidden]="!chevronState['panel-contact']" />
          <img class="panel-arrow-down" src="/assets/images/chevron-down.svg" [hidden]="chevronState['panel-contact']" />
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['TICKETING.GID']}}</b></small></div>
            <div class="col-md-9 col-lg-9 col-sm-12 pad-left-20">{{user.userID}}</div>
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['TICKETING.COMPANY_NAME']}}</b></small></div>
            <div class="col-md-9 col-lg-9 col-sm-12 pad-left-20">{{user.company}}</div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
              <small><b>{{i18nWords['TICKETING.CONTACT_NAME']}}</b><sup>*</sup></small>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-12">
              <input type="text" class="form-control inputTextField" formControlName="contactName" [placeholder]="i18nWords['TICKETING.PLACEHOLDER_CONTACT_NAME']" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_SITE_ADDRESS']}}</b><sup>*</sup></small></div>
            <div class="col-md-9 col-lg-9 col-sm-12"><input type="text" class="form-control inputTextField" formControlName="siteAddress" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_SITE_ADDRESS']" /></div>
          </div>

          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['TICKETING.CONTACT_NUMBER']}}</b><sup>*</sup></small></div>
            <div class="col-md-9 col-lg-9 col-sm-12"><input type="text" class="form-control inputTextField" formControlName="contactNumber" [placeholder]="i18nWords['TICKETING.PLACEHOLDER_CONTACT_NUMBER']" /></div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
              <small><b>{{i18nWords['TICKETING.EMAIL']}}</b><sup>*</sup></small>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-12">
              <input type="text" class="form-control inputTextField" formControlName="contactEmail" [placeholder]="i18nWords['TICKETING.PLACEHOLDER_EMAIL']" />
            </div>
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="panel-category" class="panel-list">
        <ng-template ngbPanelTitle>
          <div class="font-weight-bold leftAligned panel-title">{{i18nWords['CHANGE_REQUEST.LABEL_SERVICE_CATEGORY']}}</div>
          <img class="panel-arrow-down" src="/assets/images/chevron-up.svg" [hidden]="!chevronState['panel-category']" />
          <img class="panel-arrow-down" src="/assets/images/chevron-down.svg" [hidden]="chevronState['panel-category']" />
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row">
            <div class="col-md-12 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_SERVICE_CATEGORY']}}</b></small></div>
          </div>
          <div class="row">
            <ul class="checkbox-list offset-md-1 col-md-11 offset-lg-3 col-lg-9 col-sm-12" *ngIf="changeRequestFormGroup.controls.serviceCategories">
              <li class="inputLabel" *ngFor="let option of changeRequestFormGroup.controls.serviceCategories.controls;let i = index;">
                <label class="checkbox-label">
                  <input type="checkbox" [formControl]="option">
                  {{trustChangeOtions[i].title}}
                </label>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_HOSTNAME']}}</b><sup>*</sup></small></div>
            <div class="col-md-9 col-lg-9 col-sm-12"><input type="text" class="form-control inputTextField" formControlName="hostname" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_HOSTNAME']" /></div>
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel id="panel-description" class="panel-list">
        <ng-template ngbPanelTitle>
          <div class="font-weight-bold leftAligned panel-title">{{i18nWords['CHANGE_REQUEST.LABEL_CHANGE_REQUEST_DETAILS']}}</div>
          <img class="panel-arrow-down" src="/assets/images/chevron-up.svg" [hidden]="!chevronState['panel-description']" />
          <img class="panel-arrow-down" src="/assets/images/chevron-down.svg" [hidden]="chevronState['panel-description']" />
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row">
            <div class="col-md-12 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_CHANGE_REQUEST_DETAILS']}}</b></small></div>
          </div>
          <div class="row">
            <ul class="checkbox-list offset-md-1 col-md-11 offset-lg-3 col-lg-9 col-sm-12" *ngIf="changeRequestFormGroup.controls.configuration">
              <li class="inputLabel" *ngFor="let option of changeRequestFormGroup.controls.configuration.controls;let i = index;">
                <label class="checkbox-label">
                  <input type="checkbox" [formControl]="option">
                  {{changeRequestOptions[i].title}}
                </label>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-4 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_TARGET_COMPLETION_DATE']}}</b><sup>*</sup></small></div>
            <div class="col-md-8 col-lg-9 col-sm-12">
              <div class="input-group">
                <input type="text" class="form-control inputTextField" formControlName="completionDate" placeholder="YYYY-MM-DD HH:mm" aria-describedby="completionDateLabel" />
                <div class="input-group-append">
                  <span class="input-group-text inputTextField" id="completionDateLabel" (click)="toggleDatePicker()"><i class="fa fa-calendar"></i></span>
                </div>
              </div>
              <dl-date-time-picker [hidden]="hideDatePicker" startView="day" maxView="year" minView="minute" minuteStep="5" [(ngModel)]="selectedDate" (change)="dateEvents($event)" [ngModelOptions]="{standalone: true}">
              </dl-date-time-picker>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_DETAILS_REMARKS']}}</b></small></div>
            <div class="col-md-8 col-lg-9 col-sm-12">
              <textarea name="remark" rows="4" formControlName="remark" cols="26" class="textarea-content inputTextField form-control"></textarea>
            </div>
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel class="panel-list" name="panel-optional">
        <ng-template ngbPanelTitle>
          <div class="font-weight-bold leftAligned panel-title">{{i18nWords['CHANGE_REQUEST.LABEL_CHANGE_REQUEST_DETAILS_OPTIONAL']}}</div>
          <img class="panel-arrow-down" src="/assets/images/chevron-up.svg" [hidden]="!chevronState['panel-optional']" />
          <img class="panel-arrow-down" src="/assets/images/chevron-down.svg" [hidden]="chevronState['panel-optional']" />
        </ng-template>

        <ng-template ngbPanelContent>
          <!-- Optional Policy Panel -->
          <div name="policyPanel" class="sub-panel" *ngIf="policyPanel">
            <div class="row sub-panel-title">
              <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                <div class="closePanel" (click)="removeOptional('policy')">
                  <img src="assets/images/dark_cross.svg" />
                </div>
                <span class="optional-title">
                  {{i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY']}}
                </span>
              </div>
            </div>

            <div class="dynamic-content" *ngFor="let policy of policyPanel.child;let i = index">

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_1']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="policy.income" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_1']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_2']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="policy.source" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_2']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_3']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="policy.outgoing" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_3']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_4']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="policy.destination" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_4']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_5']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="policy.port" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_5']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_6']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="policy.action" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY_6']" />
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                  <div class="add-optional" (click)="addSubOptional('policy')" [hidden]="i !== policyPanel.child.length -1">
                    <img src="/assets/images/plus.svg" />
                  </div>
                  <div class="add-optional" (click)="removeSubOptional('policy',i)" [hidden]="i === policyPanel.child.length -1">
                    <img src="/assets/images/min.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_DETAILS_REMARKS']}}</b></small></div>
              <div class="col-md-9 col-lg-9 col-sm-12">
                <textarea name="remarks" rows="4" [(ngModel)]="policyPanel.remark" [ngModelOptions]="{standalone: true}" cols="26" class="textarea-content inputTextField form-control"></textarea>
              </div>
            </div>
          </div>

          <!-- Optional NAT Panel -->
          <div name="nat_configurationPanel" class="sub-panel" *ngIf="nat_configurationPanel">
            <div class="row sub-panel-title">
              <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                <div class="closePanel" (click)="removeOptional('nat_configuration')">
                  <img src="assets/images/dark_cross.svg" />
                </div>
              </div>
              <span class="optional-title">
                {{i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION']}}
              </span>
            </div>

            <div class="dynamic-content" *ngFor="let nat_configuration of nat_configurationPanel.child;let i = index">

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_1']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="nat_configuration.externalIp" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_1']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_2']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="nat_configuration.mappedIp" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_2']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_3']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="nat_configuration.externalPort" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_3']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_4']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="nat_configuration.mappedPort" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_4']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_5']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="nat_configuration.protpcol" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_5']" />
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                  <div class="add-optional" (click)="addSubOptional('nat_configuration')" [hidden]="i !== nat_configurationPanel.child.length -1">
                    <img src="/assets/images/plus.svg" />
                  </div>
                  <div class="add-optional" (click)="removeSubOptional('nat_configuration',i)" [hidden]="i === nat_configurationPanel.child.length -1">
                    <img src="/assets/images/min.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_DETAILS_REMARKS']}}</b></small></div>
              <div class="col-md-9 col-lg-9 col-sm-12">
                <textarea name="remarks" rows="4" [(ngModel)]="nat_configurationPanel.remark" [ngModelOptions]="{standalone: true}" cols="26" class="textarea-content inputTextField form-control"></textarea>
              </div>
            </div>
          </div>

          <!-- Optional Email Panel -->
          <div name="email_reportPanel" class="sub-panel" *ngIf="email_reportPanel">
            <div class="row sub-panel-title">
              <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                <div class="closePanel" (click)="removeOptional('email_report')">
                  <img src="assets/images/dark_cross.svg" />
                </div>
                <span class="optional-title">
                  {{i18nWords['CHANGE_REQUEST.LABEL_FORM_EMAIL_REPORT']}}
                </span>
              </div>
            </div>

            <div class="dynamic-content" *ngFor="let email_report of email_reportPanel.child;let i = index">

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_EMAIL_REPORT_1']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="email_report.recipient" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_EMAIL_REPORT_1']" />
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                  <div class="add-optional" (click)="addSubOptional('email_report')" [hidden]="i !== email_reportPanel.child.length -1">
                    <img src="/assets/images/plus.svg" />
                  </div>
                  <div class="add-optional" (click)="removeSubOptional('email_report',i)" [hidden]="i === email_reportPanel.child.length -1">
                    <img src="/assets/images/min.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_DETAILS_REMARKS']}}</b></small></div>
              <div class="col-md-9 col-lg-9 col-sm-12">
                <textarea name="remarks" rows="4" [(ngModel)]="email_reportPanel.remark" [ngModelOptions]="{standalone: true}" cols="26" class="textarea-content inputTextField form-control"></textarea>
              </div>
            </div>
          </div>

          <!-- Optional Routing Panel -->
          <div name="routingPanel" class="sub-panel" *ngIf="routingPanel">
            <div class="row sub-panel-title">
              <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                <div class="closePanel" (click)="removeOptional('routing')">
                  <img src="assets/images/dark_cross.svg" />
                </div>
                <span class="optional-title">
                  {{i18nWords['CHANGE_REQUEST.LABEL_FORM_ROUTING']}}
                </span>
              </div>
            </div>

            <div class="dynamic-content" *ngFor="let routing of routingPanel.child;let i = index">

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_ROUTING_1']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="routing.ip" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_ROUTING_1']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_ROUTING_2']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="routing.netmask" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_ROUTING_2']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_ROUTING_3']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="routing.gateway" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_ROUTING_3']" />
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                  <div class="add-optional" (click)="addSubOptional('routing')" [hidden]="i !== routingPanel.child.length -1">
                    <img src="/assets/images/plus.svg" />
                  </div>
                  <div class="add-optional" (click)="removeSubOptional('routing',i)" [hidden]="i === routingPanel.child.length -1">
                    <img src="/assets/images/min.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_DETAILS_REMARKS']}}</b></small></div>
              <div class="col-md-9 col-lg-9 col-sm-12">
                <textarea name="remarks" rows="4" [(ngModel)]="routingPanel.remark" [ngModelOptions]="{standalone: true}" cols="26" class="textarea-content inputTextField form-control"></textarea>
              </div>
            </div>
          </div>

          <!-- Optional IP Whitelist Panel -->
          <div name="ip_whitelistPanel" class="sub-panel" *ngIf="ip_whitelistPanel">
            <div class="row sub-panel-title">
              <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                <div class="closePanel" (click)="removeOptional('ip_whitelist')">
                  <img src="assets/images/dark_cross.svg" />
                </div>
                <span class="optional-title">
                  {{i18nWords['CHANGE_REQUEST.LABEL_FORM_IP_WHITELIST']}}
                </span>
              </div>
            </div>

            <div class="dynamic-content" *ngFor="let ip_whitelist of ip_whitelistPanel.child;let i = index">

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_IP_WHITELIST_1']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="ip_whitelist.ip" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_IP_WHITELIST_1']" />
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                  <div class="add-optional" (click)="addSubOptional('ip_whitelist')" [hidden]="i !== ip_whitelistPanel.child.length -1">
                    <img src="/assets/images/plus.svg" />
                  </div>
                  <div class="add-optional" (click)="removeSubOptional('ip_whitelist',i)" [hidden]="i === ip_whitelistPanel.child.length -1">
                    <img src="/assets/images/min.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_DETAILS_REMARKS']}}</b></small></div>
              <div class="col-md-9 col-lg-9 col-sm-12">
                <textarea name="remarks" rows="4" [(ngModel)]="ip_whitelistPanel.remark" [ngModelOptions]="{standalone: true}" cols="26" class="textarea-content inputTextField form-control"></textarea>
              </div>
            </div>
          </div>


          <!-- Optional IP Scanning Panel -->
          <div name="ip_scanningPanel" class="sub-panel" *ngIf="ip_scanningPanel">
            <div class="row sub-panel-title">
              <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                <div class="closePanel" (click)="removeOptional('ip_scanning')">
                  <img src="assets/images/dark_cross.svg" />
                </div>
                <span class="optional-title">
                  {{i18nWords['CHANGE_REQUEST.LABEL_FORM_IP_SCANNING']}}
                </span>
              </div>
            </div>

            <div class="dynamic-content" *ngFor="let ip_scanning of ip_scanningPanel.child;let i = index">

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_IP_SCANNING_1']}}</b></small>
                </div>
                <div class="col-md-9 col-lg-9 col-sm-12">
                  <input type="text" class="form-control inputTextField" [(ngModel)]="ip_scanning.scanning" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_IP_SCANNING_1']" />
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                  <div class="add-optional" (click)="addSubOptional('ip_scanning')" [hidden]="i !== ip_scanningPanel.child.length -1">
                    <img src="/assets/images/plus.svg" />
                  </div>
                  <div class="add-optional" (click)="removeSubOptional('ip_scanning',i)" [hidden]="i === ip_scanningPanel.child.length -1">
                    <img src="/assets/images/min.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_DETAILS_REMARKS']}}</b></small></div>
              <div class="col-md-9 col-lg-9 col-sm-12">
                <textarea name="remarks" rows="4" [(ngModel)]="ip_scanningPanel.remark" [ngModelOptions]="{standalone: true}" cols="26" class="textarea-content inputTextField form-control"></textarea>
              </div>
            </div>
          </div>

          <!-- Optional Case Filtering Panel -->
          <div name="case_filteringPanel" class="sub-panel" *ngIf="case_filteringPanel">
            <div class="row sub-panel-title">
              <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                <div class="closePanel" (click)="removeOptional('case_filtering')">
                  <img src="assets/images/dark_cross.svg" />
                </div>
                <span class="optional-title">
                  {{i18nWords['CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING']}}
                </span>
              </div>
            </div>

            <div class="dynamic-content" *ngFor="let case_filtering of case_filteringPanel.child;let i = index">

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_1']}}</b></small>
                </div>
                <div class="input-group col-md-9 col-lg-9 col-sm-12">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input type="checkbox" aria-label="Checkbox for caes_filtering.casename" [(ngModel)]="case_filtering.casenameChecked" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                  <input type="text" class="form-control inputTextField" [(ngModel)]="case_filtering.casename" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_1']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_2']}}</b></small>
                </div>
                <div class="input-group col-md-9 col-lg-9 col-sm-12">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input type="checkbox" aria-label="Checkbox for caes_filtering.attackerIp" [(ngModel)]="case_filtering.attackerIpChecked" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                  <input type="text" class="form-control inputTextField" [(ngModel)]="case_filtering.attackerIp" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_2']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_3']}}</b></small>
                </div>
                <div class="input-group col-md-9 col-lg-9 col-sm-12">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input type="checkbox" aria-label="Checkbox for case_filtering.targetIp" [(ngModel)]="case_filtering.targetIpChecked" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                  <input type="text" class="form-control inputTextField" [(ngModel)]="case_filtering.targetIp" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_3']" />
                </div>
              </div>

              <div class="row">
                <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
                  <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_4']}}</b></small>
                </div>
                <div class="input-group col-md-9 col-lg-9 col-sm-12">
                  <div class="input-group-prepend">
                    <div class="input-group-text">
                      <input type="checkbox" aria-label="Checkbox for case_filtering.baseEventName" [(ngModel)]="case_filtering.baseEventNameChecked" [ngModelOptions]="{standalone: true}">
                    </div>
                  </div>
                  <input type="text" class="form-control inputTextField" [(ngModel)]="case_filtering.baseEventName" [ngModelOptions]="{standalone: true}" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_4']" />
                </div>
              </div>

              <hr />
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 inputLabel">
                  <div class="add-optional" (click)="addSubOptional('case_filtering')" [hidden]="i !== case_filteringPanel.child.length -1">
                    <img src="/assets/images/plus.svg" />
                  </div>
                  <div class="add-optional" (click)="removeSubOptional('case_filtering',i)" [hidden]="i === case_filteringPanel.child.length -1">
                    <img src="/assets/images/min.svg" />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_DETAILS_REMARKS']}}</b></small></div>
              <div class="col-md-9 col-lg-9 col-sm-12">
                <textarea name="remarks" rows="4" [(ngModel)]="case_filteringPanel.remark" [ngModelOptions]="{standalone: true}" cols="26" class="textarea-content inputTextField form-control"></textarea>
              </div>
            </div>
          </div>

          <div class="row" [hidden]="hideOptional">
            <div class="col-md-12 col-lg-12 col-sm-12 optional-select">
              <select class="col-lg-4 col-md-4 col-sm-10 form-control inline-block" [(ngModel)]="selectedOptional" [ngModelOptions]="{standalone: true}">
                <option *ngFor="let option of optionalChangeRequest" [hidden]="option.added" [selected]="option.selected" [value]="option.title">
                  {{option.title}}
                </option>
              </select>
              <button class="col-lg-1 col-md-2 col-sm-2 form-control inline-block add-button" (click)="addOptional($event)">{{i18nWords['CHANGE_REQUEST.LABEL_FORM_ADD']}}</button>
            </div>
          </div>

        </ng-template>
      </ngb-panel>

    </ngb-accordion>
  </form>
  <div class="modal-footer">
    <button id="submitBtn" type="submit" class="btn btn-success btn-md pull-right" (click)="submitForm()">{{i18nWords['TICKETING.CREATE']}}<i [ngClass]="loader"></i></button>
  </div>
</div>
