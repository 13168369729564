<ng-template #reviewModal let-c="close" size="lg" let-d="dismiss">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">{{translate('SETTINGS.REVIEW')}}</h5>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="c('cancel clicked')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body" *ngIf="surveys">
      <div *ngIf="surveys && surveys.length === 1;else multipleSurveys">
        <div class="centered">
          <small class="rating-topic">{{surveys[0].title}}</small>
        </div>
        <p class="rating-content" style="padding: '0px 20px 0px 20px'">{{description}}</p>

        <div *ngFor="let q of surveys[0].questions">
          <div class="row">
            <div class="col-5 topPadding">
              <span>{{q.question}}</span>
            </div>

            <div class="col centered">
              <span *ngIf="q.type ==='rating'" class="rating-bar">
                <span *ngFor="let x of [1,2,3,4,5]">
                  <i class="fa fa-star-o" aria-hidden="true" (click)="markRating(q,x)" [hidden]="q.value >= x"></i>
                  <i class="fa fa-star fill" aria-hidden="true" (click)="markRating(q,x)" [hidden]="q.value < x"></i>
                </span>
                <div *ngIf="q.value" class="rating-note">
                  <small [hidden]="q.value !== 1">{{translate("REVIEW_MODAL.VALUE_1")}}</small>
                  <small [hidden]="q.value !== 2">{{translate("REVIEW_MODAL.VALUE_2")}}</small>
                  <small [hidden]="q.value !== 3">{{translate("REVIEW_MODAL.VALUE_3")}}</small>
                  <small [hidden]="q.value !== 4">{{translate("REVIEW_MODAL.VALUE_4")}}</small>
                  <small [hidden]="q.value !== 5">{{translate("REVIEW_MODAL.VALUE_5")}}</small>
                </div>
              </span>

              <div *ngIf="q.type ==='radio'" class="topPadding">
                <mat-radio-group>
                  <mat-radio-button class="radioButton" [checked] = "q.value === 1" value="true" (change)="setRadio(q,true)">{{translate('SETTINGS.OPTION_YES')}}</mat-radio-button>
                  <mat-radio-button class="radioButton" value="false" (change)="setRadio(q,false)">{{translate('SETTINGS.OPTION_NO')}}</mat-radio-button>
                </mat-radio-group>
              </div>

            </div>

          </div>
        </div>

        <div class="pull-right">
          <button id="submitBtn" class="btn btn-sm btn-outline-success btn-rating" (click)="sendRating(true)">{{translate('REVIEW_MODAL.SUBMIT')}}</button>
        </div>

      </div>

      <ng-template #multipleSurveys>
        <mat-horizontal-stepper [labelPosition]="'bottom'" #stepper>
          <mat-step *ngFor="let survey of surveys;let i = index" [completed]='false'>
            <div class="centered">
              <small class="rating-topic">{{survey.title}}</small>
            </div>
            <ng-template matStepLabel>
              <span matTooltip="{{survey.operator}}">{{survey.operator}}</span>
            </ng-template>
            <p class="rating-content" style="padding: '0px 20px 0px 20px'">{{description}}</p>

            <div *ngFor="let q of survey.questions">
              <div class="row">
                <div class="col-5 topPadding">
                  <span>{{q.question}}</span>
                </div>
                <div class="col centered">
                  <span *ngIf="q.type ==='rating'" class="rating-bar">
                    <span *ngFor="let x of [1,2,3,4,5]">
                      <i class="fa fa-star-o" aria-hidden="true" (click)="markRating(q,x)" [hidden]="q.value >= x"></i>
                      <i class="fa fa-star fill" aria-hidden="true" (click)="markRating(q,x)" [hidden]="q.value < x"></i>
                    </span>
                    <div *ngIf="q.value" class="rating-note centered">
                      <small [hidden]="q.value !== 1">{{translate("REVIEW_MODAL.VALUE_1")}}</small>
                      <small [hidden]="q.value !== 2">{{translate("REVIEW_MODAL.VALUE_2")}}</small>
                      <small [hidden]="q.value !== 3">{{translate("REVIEW_MODAL.VALUE_3")}}</small>
                      <small [hidden]="q.value !== 4">{{translate("REVIEW_MODAL.VALUE_4")}}</small>
                      <small [hidden]="q.value !== 5">{{translate("REVIEW_MODAL.VALUE_5")}}</small>
                    </div>
                  </span>
                  <div *ngIf="q.type ==='radio'" class="topPadding">
                    <mat-radio-group>
                      <mat-radio-button class="radioButton" value="true" (change)="setRadio(q,true)">{{translate('SETTINGS.OPTION_YES')}}</mat-radio-button>
                      <mat-radio-button class="radioButton" value="false" (change)="setRadio(q,false)">{{translate('SETTINGS.OPTION_NO')}}</mat-radio-button>
                    </mat-radio-group>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="surveys.length !== 1">
              <div *ngIf="i === 0" class="pull-right">
                <button mat-button matStepperNext>{{translate("REVIEW_MODAL.NEXT")}}</button>
              </div>

              <div *ngIf="i > 0 && i !== (surveys.length -1)" class="pull-right">
                <button mat-button class="btn-outline-info btn " matStepperPrevious>{{translate("REVIEW_MODAL.BACK")}}</button>
                <button mat-button class="btn-outline-info btn " matStepperNext>{{translate("REVIEW_MODAL.NEXT")}}</button>
              </div>

              <div *ngIf="i === (surveys.length -1)" class="pull-right">
                <button mat-button matStepperPrevious>{{translate("REVIEW_MODAL.BACK")}}</button>
                <button id="submitBtn" class="btn btn-sm btn-outline-success btn-rating" (click)="sendRating(true)">{{translate('REVIEW_MODAL.SUBMIT')}}</button>
              </div>
            </div>

          </mat-step>
        </mat-horizontal-stepper>
      </ng-template>

    </div>
  </div>
</ng-template>
