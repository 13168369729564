<div class="main-list" *ngFor="let child of filteredModel">
  <app-ui-service [model]="child" [parent]='model'></app-ui-service>
</div>


<div class="main-list centered" style="text-align:right">
  <div class='timestamp'>
    {{parent.timestamp | date: 'shortTime'}}
  </div>
</div>

<div *ngIf="showMore" class="main-list centered" style="position:relative;margin-bottom:20px;">
  <button class="btn btn-md custom-button" (click)="viewMore()">{{translate('SETTINGS.SHOWMORE')}}</button>
</div>
