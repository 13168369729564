import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import {
  ModalService,
  TranslationService,
  ChatboxService,
  LoggerService,
  SessionService
} from '../../services';

@Component({
  selector: 'app-disconnected-modal',
  templateUrl: './disconnected-modal.component.html',
  styleUrls: ['./disconnected-modal.component.css']
})
export class DisconnectedModalComponent implements OnInit {

  @ViewChild('disconnectedModal') disconnectedModal: ElementRef;
  private subscribers: any = {};
  private currentModal: any;

  constructor(
    private ngbModal: NgbModal,
    private modalService: ModalService,
    private translationService: TranslationService,
  ) {

  }

  ngOnInit() {
    this.subscribers['modal'] = this.modalService.$modals.subscribe((modal: any) => {
      if ('open' === modal.event) {
        if (modal.name === 'disconnected')
          this.currentModal = this.ngbModal.open(this.disconnectedModal, { centered: true, keyboard: false, backdrop: "static" });
      }
    })
  }

  ngOnDestroy() {
    for (let subscriber of this.subscribers) {
      let sub: any = subscriber
      sub.unsubscribe()
    }
  }

  translate(key: string) {
    return this.translationService.translateText(key);
  }

}
