import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ModalService,
  ChatboxService,
  SessionService,
  TranslationService,
} from '../../services'

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.css']
})
export class TimeoutModalComponent implements OnInit {
  private currentModal: any;
  @ViewChild('timeoutModal') timeoutModal: ElementRef;

  constructor(
    private ngbModal: NgbModal,
    private modalService: ModalService,
    private chatboxService: ChatboxService,
    private sessionService: SessionService,
    private translationService: TranslationService,
  ) { }

  ngOnInit() {
    this.modalService.$modals.subscribe((modal: any) => {
      if (modal.event === "open") {
        if (modal.name === 'timeout')
          this.currentModal = this.ngbModal.open(this.timeoutModal, { centered: true, keyboard: false, backdrop: "static" });
      }
    })
  }

  disconnect() {
    this.chatboxService.$isDisabled.next(true);
    this.chatboxService.$isBackgroundMode.next(false);
    this.sessionService.closeSession();
    if (this.currentModal)
      this.currentModal.close();
    this.modalService.openModal('disconnect');
  }

  continue() {
    this.sessionService.resetTimeout();
    this.currentModal.close();
    this.chatboxService.$isBackgroundMode.next(false);
  }

  translate(key: string) {
    return this.translationService.translateText(key);
  }

}
