import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ui-hyperlink',
  templateUrl: './ui-hyperlink.component.html',
  styleUrls: ['./ui-hyperlink.component.css']
})
export class UiHyperlinkComponent implements OnInit {

  @Input() model: any
  @Input() parent: any
  @Output() emitEvent = new EventEmitter<any>()

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.model.content = this.model.content
    this.model.subcontent = this.model.subcontent
  }

  sendText($event: string) {
    this.emitEvent.next($event)
  }

}
