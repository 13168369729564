import { Component, OnInit } from '@angular/core';
import { LivechatService } from '../../services/livechat.service'
declare var $;

@Component({
  selector: 'app-video-call-panel',
  templateUrl: './video-call-panel.component.html',
  styleUrls: ['./video-call-panel.component.css']
})
export class VideoCallPanelComponent implements OnInit {

  public source: any;
  public showPanel: boolean = false;
  private subs: any = []
  private init: boolean = false;
  private nextScripts: any = [];
  private oriScripts: any = [];
  constructor(
    private livechatService: LivechatService
  ) {
    this.initSubscribers();
  }

  async initSubscribers() {
    this.subs.push(this.livechatService.$videoCallInstance.subscribe(async (params) => {
      if (params) {
        this.source = await this.livechatService.getVideoCallHTML(params.token, params.isApp)
        setTimeout(() => {
          let allNextScripts: any = document.querySelector('app-video-call-panel').getElementsByTagName('script')
          this.nextScripts = []
          for (let script of allNextScripts) {
            if (script.src) {
              if (!this.init) {
                this.oriScripts.push(script.src);
              }
              this.nextScripts.push(script.src);
            }
          }
          this.init = true;
          setTimeout(() => {
            for (let src of this.nextScripts) {
              this.deleteJS(src);
            }
          }, 250)
          setTimeout(() => {
            for (let src of this.nextScripts.concat(this.oriScripts)) {
              this.reload_js(src);
            }
            this.showPanel = true;
          }, 500)
        }, 250)

      } else {
        this.showPanel = false;
      }
    }))
  }

  reload_js(src) {
    $('<script>').attr('src', src).appendTo('head');
  }

  deleteJS(src) {
    $('script[src="' + src + '"]').remove();
  }

  insertScript(src) {
    var scriptEle = document.createElement('script');
    scriptEle.type = 'text/javascript';
    scriptEle.async = true;
    scriptEle.defer = true;
    scriptEle.src = src;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(scriptEle, s);
    // document.querySelector('#__next').appendChild(scriptEle);
  }

  ngOnInit() {
  }

}
