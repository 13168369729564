<div class="carousel-chevron-left pointer" (click)="scrollLeft()" [hidden]="hideLeft">
  <span class="fa fa-lg fa-chevron-left pull-left"></span>
</div>

<div class="col_container scroll">
  <div class="carousel_container" [style.min-width]="scrollMinWidth">
    <div class="carousel_col" *ngFor="let child of model.child">
      <div class="carousel_image">
        <img [src]="child.action" />
      </div>
      <p class="carousel_main_title" [innerHtml]="child.content" [hidden]="!child.content"></p>
      <app-ui-dice [model]="child.child" [parent]="parent" (emitEvent)="parseEmitEvent($event,parent)"></app-ui-dice>
    </div>
  </div>
  <div class='timestamp'>
    {{parent.timestamp | date: 'shortTime'}}
  </div>
</div>

<div class="carousel-chevron-right pointer" (click)="scrollRight()" [hidden]="hideRight">
  <span class="fa fa-lg fa-chevron-right"></span>
</div>
