import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  FolderService
  , LoggerService
} from '../../services';

@Component({
  selector: 'app-ui-folder-item',
  templateUrl: './ui-folder-item.component.html',
  styleUrls: ['./ui-folder-item.component.css']
})
export class UiFolderItemComponent implements OnInit {

  @Input() model: any;
  @Input() parent: any;
  @Input() context: any;
  @Output() emitEvent = new EventEmitter<any>();
  isCollapse: any = {};
  isLoaded: boolean = false;
  highlighted: string;

  constructor(
    private folderService: FolderService,
    private logger: LoggerService,
  ) { }

  ngOnInit() {
    // this.logger.info(this.model)
    this.model.folderId = this.folderService.nextFolderId();
    this.setTrueCollapse();
    this.isLoaded = true;
    // if (this.model.customFields)
    //   if (this.model.customFields.collapsed === false) {
    //     setTimeout(() => {
    //       this.toggleCollapse();
    //     }, 800)
    //   }
  }

  parseEmitEvent($event: any) {
    if ($event.text)
      this.emitEvent.next({ text: $event.text, isButton: $event.isButton })
    else
      this.emitEvent.next($event)
  }

  /**
   * toggle collapse for folder type contents
   * @param folderId:string
   */
  toggleCollapse() {
    this.isCollapse[this.model.folderId] = !this.isCollapse[this.model.folderId];
  }

  setTrueCollapse() {
    this.isCollapse[this.model.folderId] = true;
  }

  isCollapsed() {
    return this.isCollapse[this.model.folderId];
  }


}
