import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild, Renderer2, ChangeDetectionStrategy } from '@angular/core'
import {
  DebounceService,
  LoggerService
} from '../../services';
declare var $;
declare var Select;
import { TimelineMax, Expo } from "gsap/TweenMax";

@Component({
  selector: 'app-ui-dice',
  templateUrl: './ui-dice.component.html',
  styleUrls: ['./ui-dice.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiDiceComponent implements OnInit {

  @Input() model: any;
  @Input() parent: any;
  @Output() emitEvent = new EventEmitter<any>();
  select: any;
  tl: any;
  done: boolean = true;
  topCaret: string = '';
  bottomCaret: string = '';

  constructor(
    private elementRef: ElementRef,
    private debounceService: DebounceService,
    private logger: LoggerService,
    private renderer: Renderer2,
  ) {
    this.topCaret = `<i class="fa fa-caret-up" aria-hidden="true" style="position: absolute;right: 10px;color: #438bc6;"></i>`
    this.bottomCaret = `<i class="fa fa-caret-down" aria-hidden="true" style="position: absolute;right: 10px;top: 10px;color: #438bc6; "></i>`
  }

  ngOnInit() {
    let el: any = this.elementRef.nativeElement.querySelector(".scrollSelect");
    let data = [];
    let select
    let a2Obj = this
    let id = 1;
    for (let child of this.model) {
      data.push({
        id: id,
        text: String(child.content)
      });
      ++id;
    }
    if (data.length > 0) {
      select = new Select(el, {
        data: data
      });
      this.select = select;
    }
    let el2: any = this.elementRef.nativeElement.querySelector(".scroll-select-container");
    let div = this.renderer.createElement('div')
    this.renderer.addClass(div, 'selectedItem')
    this.renderer.appendChild(el2, div)
    this.renderer.listen(div, 'click', (event) => {
      this.parseEmitEvent()
    })

    setTimeout(() => {
      this.runAnimation(0)
    }, 1000)
    select.on('change', function(v) {
      if (a2Obj.tl) {
        a2Obj.tl.pause()
        a2Obj.tl.reverse()
      }
      let index = v - 1
      if (index < 0)
        index = 0
      a2Obj.runAnimation(index)
    })

    this.renderer.listen(el2, 'scroll', (event) => {
      let mainPanel: any = document.body.querySelector('.aimchatbot-main')
      if (mainPanel)
        mainPanel.style['overflow-y'] = 'hidden'
      if (this.done) {
        this.done = false
        this.debounceService.debounce(() => {
          if (mainPanel)
            mainPanel.style['overflow-y'] = 'auto'
          this.done = true
        }, 500, false)()
      }
    })

    let selectTop: any = this.elementRef.nativeElement.querySelector(".scroll-select-top");
    let selectBottom: any = this.elementRef.nativeElement.querySelector(".scroll-select-bottom");
    if (selectTop) {
      selectTop.addEventListener('click', function() {
        select.prev()
      })
      selectBottom.addEventListener('click', function() {
        select.next()
      })
    }
  }

  runAnimation(index) {
    let delta = 0
    this.renderCaret(+index + 1)
    let eles = this.elementRef.nativeElement.querySelectorAll('li>span')
    let ele = $(eles[index])
    if (ele.width() > 100) {
      ele.addClass('longText')
      let calWidth = ele.width() - 178 + 80 + delta
      let speed = calWidth / 30
      if (speed < 5)
        speed = 5
      this.tl = new TimelineMax({ repeat: -1 })
      this.tl
        .to(ele, speed, {
          display: 'block',
          x: `-${calWidth}px`,
        }).to(ele, speed, {
          display: 'block',
          x: "20px",
        })
    }
  }

  renderCaret(index) {
    let carets = ''
    index -= 1
    if (index < this.model.length - 1) {
      carets += this.bottomCaret
    }

    if (this.model.length > 1) {
      if (index === 0) {
        carets += this.bottomCaret
      } else if (index !== this.model.length - 1 && index !== 0) {
        if (!carets)
          carets += this.bottomCaret
        carets += this.topCaret
      } else if (index === this.model.length - 1) {
        carets += this.topCaret
      }
    } else {
      carets = ''
    }

    let selectedItemDiv = this.elementRef.nativeElement.querySelector(".selectedItem")
    selectedItemDiv.innerHTML = carets
  }


  parseEmitEvent() {
    try {
      let selectedIndex = this.select.value();
      selectedIndex -= 1;
      let selectedOption = this.model[selectedIndex];
      this.emitEvent.next({ text: selectedOption.action, isButton: true });
    } catch (e) {
      this.select.prev()
    }
  }

}
