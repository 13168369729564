import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ui-hyperlink-list',
  templateUrl: './ui-hyperlink-list.component.html',
  styleUrls: ['./ui-hyperlink-list.component.css']
})
export class UiHyperlinkListComponent implements OnInit {

  @Input() model: any
  @Input() parent: any
  @Output() emitEvent = new EventEmitter<any>()

  constructor() { }

  ngOnInit() {
    this.model.content = this.model.content
    this.model.subcontent = this.model.subcontent
  }

  sendText($event: string) {
    this.emitEvent.next({ text: $event, isButton: true })
  }

}
