import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import *  as moment from 'moment'
import *  as _ from 'underscore'

import {
  TranslationService,
  ModalService,
  SessionService,
  TicketService,
  ChatboxService,
  McservicesService,
  MessageDecoderService
} from '../../services'

import {
  emailValidator,
} from '../../directives';

@Component({
  selector: 'app-change-request-form-trust-content',
  templateUrl: './change-request-form-trust-content.component.html',
  styleUrls: ['./change-request-form-trust-content.component.scss']
})
export class ChangeRequestFormTrustContentComponent implements OnInit {

  @ViewChild('acc') step1Accordion: NgbAccordion;
  trustChangeOtions: any = [];
  optionalChangeRequest: any = []
  selectedOptional: string = '';
  changeRequestOptions: any = [];
  public hideOptional: boolean = false;
  public policyPanel: any;
  public nat_configurationPanel: any;
  public email_reportPanel: any;
  public routingPanel: any;
  public ip_whitelistPanel: any;
  public ip_scanningPanel: any;
  public case_filteringPanel: any;
  public changeRequestFormGroup: any;
  public hideDatePicker: boolean = true;
  public i18nWords: any = {};
  public chevronState: any = {
    'panel-contact': true
  };
  loading: boolean = false;
  loader: string = '';
  private i18nKeys: any = [
    'TICKETING.CREATE',
    'TICKETING.GID',
    'TICKETING.COMPANY_NAME',
    'TICKETING.CONTACT_NAME',
    'TICKETING.CONTACT_NUMBER',
    'TICKETING.EMAIL',
    'TICKETING.PLACEHOLDER_EMAIL',
    'TICKETING.PLACEHOLDER_CONTACT_NUMBER',
    'TICKETING.PLACEHOLDER_CONTACT_NAME',

    'CHANGE_REQUEST.LABEL_TRUST',
    'CHANGE_REQUEST.LABEL_CONTACT_INFORMATION',
    'CHANGE_REQUEST.LABEL_TARGET_COMPLETION_DATE',
    'CHANGE_REQUEST.LABEL_DETAILS_REMARKS',
    'CHANGE_REQUEST.LABEL_CHANGE_REQUEST_DETAILS',
    'CHANGE_REQUEST.LABEL_CHANGE_REQUEST_DETAILS_OPTIONAL',
    'CHANGE_REQUEST.LABEL_CONFIGURATION_CHANGE',
    'CHANGE_REQUEST.LABEL_SERVICE_CATEGORY',
    'CHANGE_REQUEST.LABEL_SITE_ADDRESS',
    'CHANGE_REQUEST.LABEL_HOSTNAME',

    'CHANGE_REQUEST.LABEL_FORM_POLICY',
    'CHANGE_REQUEST.LABEL_FORM_POLICY_1',
    'CHANGE_REQUEST.LABEL_FORM_POLICY_2',
    'CHANGE_REQUEST.LABEL_FORM_POLICY_3',
    'CHANGE_REQUEST.LABEL_FORM_POLICY_4',
    'CHANGE_REQUEST.LABEL_FORM_POLICY_5',
    'CHANGE_REQUEST.LABEL_FORM_POLICY_6',

    'CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION',
    'CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_1',
    'CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_2',
    'CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_3',
    'CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_4',
    'CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION_5',

    'CHANGE_REQUEST.LABEL_FORM_EMAIL_REPORT',
    'CHANGE_REQUEST.LABEL_FORM_EMAIL_REPORT_1',

    'CHANGE_REQUEST.LABEL_FORM_ROUTING',
    'CHANGE_REQUEST.LABEL_FORM_ROUTING_1',
    'CHANGE_REQUEST.LABEL_FORM_ROUTING_2',
    'CHANGE_REQUEST.LABEL_FORM_ROUTING_3',

    'CHANGE_REQUEST.LABEL_FORM_IP_WHITELIST',
    'CHANGE_REQUEST.LABEL_FORM_IP_WHITELIST_1',

    'CHANGE_REQUEST.LABEL_FORM_IP_SCANNING',
    'CHANGE_REQUEST.LABEL_FORM_IP_SCANNING_1',

    'CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING',
    'CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_1',
    'CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_2',
    'CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_3',
    'CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING_4',

    'CHANGE_REQUEST.LABEL_FORM_ADD',
    'CHANGE_REQUEST.CREATING'
  ];
  public user: any;
  defaultData: any = {
    contactName: '',
    contactEmail: '',
    siteAddress: '',
    contactNumber: '',
    completionDate: '',
    hostname: '',
    changeRequestDetails: [],
    remark: '',
  }

  constructor(
    private modalService: ModalService,
    private translationService: TranslationService,
    private sessionService: SessionService,
    private mcservicesService: McservicesService,
    private fb: FormBuilder,
    private messageDecoderService: MessageDecoderService,
    private chatboxService: ChatboxService,
  ) { }

  ngOnInit() {
    this.user = this.sessionService.getUserSession()
    this.initTranslation()
    this.initForm()
  }

  initTranslation() {
    let loaded = true
    for (const key of this.i18nKeys) {
      if (key === this.translationService.translateText(key)) {
        loaded = false
        continue
      }
      this.i18nWords[key] = this.translationService.translateText(key)
    }

    if (this.optionalChangeRequest.length === 0) {
      this.optionalChangeRequest = [{
        title: this.i18nWords['CHANGE_REQUEST.LABEL_FORM_POLICY'],
        value: 'policy',
        selected: true,
        added: false
      },
      {
        title: this.i18nWords['CHANGE_REQUEST.LABEL_FORM_NAT_CONFIGURATION'],
        value: 'nat_configuration',
        selected: false,
        added: false
      },
      {
        title: this.i18nWords['CHANGE_REQUEST.LABEL_FORM_EMAIL_REPORT'],
        value: 'email_report',
        selected: false,
        added: false
      },
      {
        title: this.i18nWords['CHANGE_REQUEST.LABEL_FORM_ROUTING'],
        value: 'routing',
        selected: false,
        added: false
      },
      {
        title: this.i18nWords['CHANGE_REQUEST.LABEL_FORM_IP_WHITELIST'],
        value: 'ip_whitelist',
        selected: false,
        added: false
      },
      {
        title: this.i18nWords['CHANGE_REQUEST.LABEL_FORM_IP_SCANNING'],
        value: 'ip_scanning',
        selected: false,
        added: false
      },
      {
        title: this.i18nWords['CHANGE_REQUEST.LABEL_FORM_CASE_FILTERING'],
        value: 'case_filtering',
        selected: false,
        added: false
      }]
      this.selectedOptional = this.optionalChangeRequest[0].title
    }

    if (!loaded) {
      setTimeout(() => {
        this.initTranslation()
      }, 1000)
    }
  }

  async initForm() {
    this.initFormGroup()
    this.changeRequestOptions = await this.mcservicesService.getTrustChangeType()
    this.trustChangeOtions = await this.mcservicesService.getTrustServiceType()
    const serviceCategoriesOptions = this.trustChangeOtions.map(control => new FormControl(false));
    const changeRequestOptions = this.changeRequestOptions.map(control => new FormControl(false));
    this.initFormGroup({
      'serviceCategories': new FormArray(serviceCategoriesOptions),
      'configuration': new FormArray(changeRequestOptions),
    })
  }

  initFormGroup(params?: any) {
    let defaultFormGroup = {
      'contactName': new FormControl(this.defaultData.contactName, [Validators.required]),
      'siteAddress': new FormControl(this.defaultData.siteAddress, [Validators.required]),
      'contactEmail': new FormControl(this.defaultData.contactEmail, [Validators.required, emailValidator()]),
      'contactNumber': new FormControl(this.defaultData.contactNumber, [Validators.required, Validators.minLength(8)]),

      'hostname': new FormControl(this.defaultData.hostname, [Validators.required]),
      'completionDate': new FormControl(this.defaultData.completionDate, [Validators.required]),
      'changeRequestDetails': new FormControl(this.defaultData.changeRequestDetails),
      'remark': new FormControl(this.defaultData.remark)
    }
    const combinedFormGroup = Object.assign(defaultFormGroup, params)
    this.changeRequestFormGroup = new FormGroup(combinedFormGroup)
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  async validateForm() {
    var toExpandPanels = [];
    // expand subsection 1 if necessary
    if (!this.changeRequestFormGroup.get('contactName').valid ||
      !this.changeRequestFormGroup.get('siteAddress').value ||
      !this.changeRequestFormGroup.get('contactEmail').value ||
      !this.changeRequestFormGroup.get('contactNumber').value) {
      toExpandPanels.push('panel-contact');
    }

    if (!this.changeRequestFormGroup.get('hostname').valid) {
      toExpandPanels.push('panel-category');
    }

    // expand subsection 2 if necessary
    if (!this.changeRequestFormGroup.get('completionDate').valid) {
      toExpandPanels.push('panel-description');
    }
    if (toExpandPanels.length > 0) {
      this.step1Accordion.activeIds = toExpandPanels;
    }
  }


  async submitForm() {
    this.markFormGroupTouched(this.changeRequestFormGroup)
    this.validateForm()
    // console.log(this.changeRequestFormGroup)
    if ('VALID' === this.changeRequestFormGroup.status) {
      let changeRequestData = Object.assign({}, this.changeRequestFormGroup.value)
      changeRequestData.tcChangeType = []
      changeRequestData.tcServiceType = []
      changeRequestData.tcPolicys = (this.policyPanel ? this.policyPanel.child.map(x => {
        x.remark = this.policyPanel.remark
        return x
      }) : [])
      changeRequestData.tcNats = (this.nat_configurationPanel ? this.nat_configurationPanel.child.map(x => {
        x.remark = this.nat_configurationPanel.remark
        return x
      }) : [])
      changeRequestData.tcEmailRecipients = (this.email_reportPanel ? this.email_reportPanel.child.map(x => {
        x.remark = this.email_reportPanel.remark
        return x
      }) : [])
      changeRequestData.tcIpwhiteLists = (this.ip_whitelistPanel ? this.ip_whitelistPanel.child.map(x => {
        x.remark = this.ip_whitelistPanel.remark
        return x
      }) : [])
      changeRequestData.tcIpscanServices = (this.ip_scanningPanel ? this.ip_scanningPanel.child.map(x => {
        x.remark = this.ip_scanningPanel.remark
        return x
      }) : [])
      changeRequestData.tcRoutings = (this.routingPanel ? this.routingPanel.child.map(x => {
        x.remark = this.routingPanel.remark
        return x
      }) : [])
      changeRequestData.tcCaseFilters = (this.case_filteringPanel ? this.case_filteringPanel.child.map(x => {
        x.remark = this.case_filteringPanel.remark
        return x
      }) : [])

      this.changeRequestFormGroup.value.serviceCategories.map((x, i) => {
        if (x) {
          changeRequestData.tcServiceType.push(this.trustChangeOtions[i].value)
        }
        return x
      })
      this.changeRequestFormGroup.value.configuration.map((x, i) => {
        if (x) {
          changeRequestData.tcChangeType.push(this.changeRequestOptions[i].value)
        }
        return x
      })
      if (!this.loading) {
        this.loading = true
        this.loader = 'fa fa-circle-o-notch fa-spin'
        let resp: any = await this.mcservicesService.createTrustChangeRequest(changeRequestData)
        if (resp.ok) {
          let output = Object.assign({ fromMe: false }, this.messageDecoderService.generateTextTypeComponent({text:this.i18nWords['CHANGE_REQUEST.CREATING']}))
          this.chatboxService.add(output)
          this.closeModal()
        } else {
          this.loading = false;
          this.loader = ''
        }
      }
    }
  }

  toggleDatePicker() {
    this.hideDatePicker = !this.hideDatePicker
  }

  closeModal() {
    this.modalService.closeModal('changeRequest-trust')
  }

  dateEvents(event) {
    if (event.value) {
      this.hideDatePicker = true
      this.changeRequestFormGroup.controls['completionDate'].setValue(moment(event.value).format("YYYY-MM-DD HH:mm"))
    }
  }

  updateChevron(event, acc) {
    this.chevronState = {}
    this.chevronState[event.panelId] = event.nextState
  }

  addOptional(e) {
    e.preventDefault();
    let found = _.find(this.optionalChangeRequest, { title: this.selectedOptional })
    let index = this.optionalChangeRequest.indexOf(found)
    found.added = true;
    found.selected = false;
    this[`${found.value}Panel`] = {
      child: [{}],
      remark: '',
      title: this.selectedOptional
    }
    let nextFound = _.find(this.optionalChangeRequest, { added: false })
    if (nextFound) {
      this.selectedOptional = nextFound.title
      nextFound.selected = true;
    } else {
      this.hideOptional = true
    }
  }

  removeOptional(subpanelName) {
    const title = this[`${subpanelName}Panel`].title
    let found = _.find(this.optionalChangeRequest, { title: title })
    let index = this.optionalChangeRequest.indexOf(found)
    found.added = false;
    found.selected = false;
    if (this.hideOptional) {
      let nextFound = _.find(this.optionalChangeRequest, { added: false })
      if (nextFound) {
        nextFound.selected = true
      }
    }
    this.hideOptional = false;
    delete this[`${subpanelName}Panel`]
  }

  addSubOptional(subpanelName) {
    this[`${subpanelName}Panel`].child.push({})
  }

  removeSubOptional(subpanelName, index) {
    this[`${subpanelName}Panel`].child.splice(index, 1)
  }

}
