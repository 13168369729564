import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatSliderModule,
  MatSlideToggleModule,
  MatFormFieldModule,
  MatInputModule,
  MatRadioModule,
  MatStepperModule,
  MatButtonModule,
  MatIconModule,
  MatBadgeModule,
  MatTooltipModule,
  MatSelectModule,
} from '@angular/material';
import { NgSelectModule } from '@ng-select/ng-select';

import { DlDateTimePickerDateModule } from '../assets/js/angular-bootstrap-datetimepicker';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CookieModule } from 'ngx-cookie';

import { AvatarModule } from 'ngx-avatar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FileUploadModule } from 'ng2-file-upload';

import { TokenInterceptor } from './helper';

import { AppComponent } from './app.component';
import { NgbModule, NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';

import {
  InputboxComponent,
  CustomerChatboxComponent,
  ChatboxpanelComponent,
  ReviewModalComponent,
  InAppModalComponent,
  TimeoutModalComponent,
  DisconnectedModalComponent,
  IframeModalComponent,
  CustomerStatusbarComponent,
  EmptyInputModalComponent,
  FollowupModalComponent,
  CreateTicketModalComponent,
  CreateTicketModalContentComponent,
  ChangeRequestFormModalComponent,
  ChangeRequestFormConnectivityContentComponent,
  ChangeRequestFormTrustContentComponent,
  ChangeRequestFormCloudContentComponent,
  EnlargedImageModalComponent,
  VideoCallPanelComponent,
  MarqueeComponent
} from './components'

import {
  TranslationService,
  MicrophoneService,
  ModalService,
  ChatboxService,
  SessionService,
  LoggerService,
  ConsoleLoggerService,
  SocketService,
  DebounceService,
  FolderService,
  MessageDecoderService,
  TicketService,
  McservicesService,
  LivechatService,
  UploaderService,
  FileService,
  RedirectService
} from './services';

import {
  ShortenstringPipe,
  SafehtmlPipe,
  SafeUrlResourcePipe
} from './pipes';

import {
  UiTextComponent,
  UiButtonComponent,
  UiCarouselComponent,
  UiListComponent,
  UiHyperlinkComponent,
  UiHyperlinkListComponent,
  UiFolderComponent,
  UiFolderItemComponent,
  UiImageComponent,
  UiButtonListComponent,
  UiIframeComponent,
  UiDiceComponent,
  UiImageButtonsComponent,
  UiListItemComponent,
  UiRankingComponent,
  UiLineBreakerComponent,
  UiTicketListComponent,
  UiTicketComponent,
  UiServiceListComponent,
  UiServiceComponent,
  UiModelsComponent,
  UiFileComponent,
} from './ui-models';


import {
  NumbersDirective,
  EmailValidatorDirective,
  ContactNumberDirective,
} from './directives';


const appRoutes: Routes = [
  { path: '**', component: AppComponent }
];

@NgModule({
  declarations: [
    AppComponent,

    InputboxComponent,
    CustomerChatboxComponent,
    ChatboxpanelComponent,
    CustomerStatusbarComponent,

    ReviewModalComponent,
    TimeoutModalComponent,
    IframeModalComponent,
    InAppModalComponent,
    EmptyInputModalComponent,
    FollowupModalComponent,
    DisconnectedModalComponent,
    CreateTicketModalComponent,
    CreateTicketModalContentComponent,

    UiTextComponent,
    UiButtonComponent,
    UiCarouselComponent,
    UiFolderItemComponent,
    UiImageComponent,
    UiButtonListComponent,
    UiIframeComponent,
    UiModelsComponent,
    UiListComponent,
    UiListItemComponent,
    UiDiceComponent,
    UiImageButtonsComponent,
    UiRankingComponent,
    UiLineBreakerComponent,
    UiFolderComponent,
    UiHyperlinkListComponent,
    UiHyperlinkComponent,
    UiTicketListComponent,
    UiTicketComponent,
    UiServiceListComponent,
    UiServiceComponent,
    UiFileComponent,

    ShortenstringPipe,
    SafehtmlPipe,
    SafeUrlResourcePipe,

    NumbersDirective,
    EmailValidatorDirective,
    ContactNumberDirective,
    EnlargedImageModalComponent,
    VideoCallPanelComponent,
    MarqueeComponent,
    ChangeRequestFormModalComponent,
    ChangeRequestFormConnectivityContentComponent,
    ChangeRequestFormTrustContentComponent,
    ChangeRequestFormCloudContentComponent
  ],
  entryComponents: [
    CreateTicketModalContentComponent,
    ChangeRequestFormConnectivityContentComponent,
    ChangeRequestFormTrustContentComponent,
    ChangeRequestFormCloudContentComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatRadioModule,
    MatInputModule,
    MatStepperModule,
    MatButtonModule,
    MatBadgeModule,
    MatIconModule,
    MatTooltipModule,
    MatSelectModule,
    CookieModule.forRoot(),
    NgbModule.forRoot(),
    RouterModule.forRoot(
      appRoutes,
    ),
    FileUploadModule,
    AvatarModule,
    DlDateTimePickerDateModule,
    NgSelectModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    TranslationService,
    ChatboxService,
    MicrophoneService,
    ModalService,
    SessionService,
    DebounceService,
    FolderService,
    MessageDecoderService,
    { provide: LoggerService, useClass: ConsoleLoggerService },
    NgbDropdownConfig,
    SocketService,
    TicketService,
    McservicesService,
    LivechatService,
    UploaderService,
    FileService,
    RedirectService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export class NgbdDropdownConfig {
  constructor(config: NgbDropdownConfig) {
    // customize default values of dropdowns used by this component tree
    config.placement = 'top-left';
    config.autoClose = true;
  }
}
