import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from './session.service'
import * as _ from 'underscore'
@Injectable({
  providedIn: 'root'
})
export class LivechatService {

  public $videoCallInstance = new Subject<any>();
  _avatarList: any = [];
  _questions: any = [];
  _operators: any = {};

  constructor(
    private http: HttpClient,
    private sessionService: SessionService
  ) {
    this.getAvatars()
  }

  get questions(): any {
    this._questions.map(x => {
      x.value = null
    })
    return this._questions
  }

  get operators(): any {
    return this._operators
  }

  addOperators(params: any): any {
    this.operators[params.username] = params.name
  }

  async initQuestions(params: any) {
    let url = environment.serverUrl + '/api/chat/questions';
    this._questions = await this.http.get(url, params).toPromise()
    return this._questions
  }

  async submitSurvey(params: any) {
    let url = environment.serverUrl + '/api/chat/questions';
    return this.http.post(url, params).toPromise()
  }

  async getAvatars() {
    let url = environment.serverUrl + '/api/avatar/all';
    this._avatarList = await this.http.get(url).toPromise()
    return this._avatarList
  }

  getAvatar(avatarName: string) {
    let foundAvatar = _.find(this._avatarList, { avatar_id: avatarName })
    if (foundAvatar && foundAvatar.data) {
      return foundAvatar.data
    }
    return
  }

  async getVideoCallHTML(token: string, isApp: boolean) {
    try {
      let url = environment.serverUrl + `/lvc/videocall?token=${token}`
      // let url =`http://localhost:3001/videocall?token=${token}`
      if (!isApp) url += '&web=true'
      let resp = await this.http.get(url, { responseType: 'text' }).toPromise()
      return resp
    } catch (e) {
      return null
    }
  }

  async getVideoCallURL(token: string) {
    try {
      let url = environment.serverUrl + `/lvc/videocall?token=${token}`
      // let url =`http://localhost:3001/videocall?token=${token}&web=true`
      return url;
    } catch (e) {
      return null
    }
  }

}
