<app-ui-line-breaker *ngIf="model.type ==='line'" [model]="model" [parent]="parent"></app-ui-line-breaker>
<app-ui-iframe *ngIf="model.type ==='iframe'" [model]="model" (emitEvent)="parseEmitEvent($event,parent)"></app-ui-iframe>
<app-ui-text *ngIf="model.type ==='text'" [model]="model" [parent]="parent"></app-ui-text>
<app-ui-hyperlink-list *ngIf="model.type ==='hyperlinkList'" [model]="model" (emitEvent)="parseEmitEvent($event,parent)"></app-ui-hyperlink-list>
<app-ui-folder *ngIf="model.type ==='folder'" [model]="model" [parent]="parent" [context]="parent.context" (emitEvent)="parseEmitEvent($event,parent)"></app-ui-folder>
<app-ui-button-list *ngIf="model.type ==='buttonList'" [model]="model" [parent]="parent" (emitEvent)="parseEmitEvent($event,parent)"></app-ui-button-list>
<app-ui-image-buttons *ngIf="model.type ==='buttonImage'" [model]="model" (emitEvent)="parseEmitEvent($event,parent)"></app-ui-image-buttons>
<app-ui-list *ngIf="model.type ==='list'" [model]="model" (emitEvent)="parseEmitEvent($event,parent)"></app-ui-list>
<app-ui-image-buttons *ngIf="model.type ==='imageButtonList'" [model]="model" [parent]="parent" (emitEvent)="parseEmitEvent($event,parent)"></app-ui-image-buttons>
<app-ui-list-item *ngIf="model.type ==='list-item'" [model]="model" (emitEvent)="parseEmitEvent($event,parent)"></app-ui-list-item>
<app-ui-carousel *ngIf="model.type ==='carouselList'" [model]="model" [parent]="parent" (emitEvent)="parseEmitEvent($event,parent)"></app-ui-carousel>
<app-ui-ticket-list *ngIf="model.type ==='ticketList'" [model]="model" [parent]="parent"></app-ui-ticket-list>
<app-ui-service-list *ngIf="model.type ==='serviceList'" [model]="model" [parent]="parent"></app-ui-service-list>
<app-ui-image *ngIf="model.type ==='image'" [model]="model" [parent]="parent"></app-ui-image>
<app-ui-file *ngIf="model.type ==='file-others'" [model]="model" [parent]="parent"></app-ui-file>
