<div class="modal-container">

  <div class="modal-nav-bar">
    <div class="close-icon" (click)="closeModal()">
      <svg class="svg-cross">
        <path id="svg-cross" d="M 21 0 L 0 21">
        </path>
      </svg>
      <svg class="svg-cross">
        <path id="svg-cross" d="M 0 0 L 21 21">
        </path>
      </svg>
    </div>
    <h5 class="form-title">{{i18nWords['CHANGE_REQUEST.LABEL_CONFIGURATION_CHANGE']}} > {{i18nWords['CHANGE_REQUEST.LABEL_CONNECTIVITY']}}</h5>
  </div>

  <form [formGroup]="changeRequestFormGroup" (ngSubmit)="submitForm()" #formDir="ngForm">
    <ngb-accordion id="step1Accordion" [closeOthers]="true" activeIds="panel-contact" #acc="ngbAccordion" (panelChange)="updateChevron($event, acc)">
      <ngb-panel id='panel-contact' class="panel-list">
        <ng-template ngbPanelTitle>
          <div class="font-weight-bold leftAligned panel-title">{{i18nWords['CHANGE_REQUEST.LABEL_CONTACT_INFORMATION']}}</div>
          <img class="panel-arrow-down" src="/assets/images/chevron-up.svg" [hidden]="!chevronState['panel-contact']" />
          <img class="panel-arrow-down" src="/assets/images/chevron-down.svg" [hidden]="chevronState['panel-contact']" />
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['TICKETING.GID']}}</b></small></div>
            <div class="col-md-9 col-lg-9 col-sm-12 pad-left-20">{{user.userID}}</div>
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['TICKETING.COMPANY_NAME']}}</b></small></div>
            <div class="col-md-9 col-lg-9 col-sm-12 pad-left-20">{{user.company}}</div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
              <small><b>{{i18nWords['TICKETING.CONTACT_NAME']}}</b><sup>*</sup></small>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-12">
              <input type="text" class="form-control inputTextField" formControlName="contactName" [placeholder]="i18nWords['TICKETING.PLACEHOLDER_CONTACT_NAME']" />
            </div>
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
              <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_ADDRESS']}}</b><sup>*</sup></small>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-12">
              <input type="text" class="form-control inputTextField" formControlName="contactAddress" [placeholder]="i18nWords['CHANGE_REQUEST.LABEL_ADDRESS']" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['TICKETING.CONTACT_NUMBER']}}</b><sup>*</sup></small></div>
            <div class="col-md-9 col-lg-9 col-sm-12"><input type="text" class="form-control inputTextField" formControlName="contactNumber" [placeholder]="i18nWords['TICKETING.PLACEHOLDER_CONTACT_NUMBER']" /></div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
              <small><b>{{i18nWords['CHANGE_REQUEST.LABEL_LOCATION_SITE_ID']}}</b></small>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-12 inputTextField">
              <ng-select [items]="locations" multiple="true" placeholder="N/A" formControlName="siteIds"></ng-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 col-lg-3 col-sm-12 inputLabel">
              <small><b>{{i18nWords['TICKETING.EMAIL']}}</b><sup>*</sup></small>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-12">
              <input type="text" class="form-control inputTextField" formControlName="contactEmail" [placeholder]="i18nWords['TICKETING.PLACEHOLDER_EMAIL']" />
            </div>
          </div>
        </ng-template>
      </ngb-panel>

      <ngb-panel id='panel-description' class="panel-list">
        <ng-template ngbPanelTitle>
          <div class="font-weight-bold leftAligned panel-title">{{i18nWords['CHANGE_REQUEST.LABEL_CHANGE_REQUEST_DETAILS']}}</div>
          <img class="panel-arrow-down" src="/assets/images/chevron-up.svg" [hidden]="!chevronState['panel-description']" />
          <img class="panel-arrow-down" src="/assets/images/chevron-down.svg" [hidden]="chevronState['panel-description']" />
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="row">
            <div class="col-md-12 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_CHANGE_REQUEST_DETAILS']}}</b></small></div>
          </div>
          <div class="row">
            <ul class="checkbox-list offset-md-1 col-md-11 offset-lg-3 col-lg-9 col-sm-12">
              <li class="inputLabel" *ngFor="let option of changeRequestFormGroup.controls.configuration.controls;let i = index;">
                <label class="checkbox-label">
                  <input type="checkbox" [formControl]="option">
                  {{connectivityChangeOptions[i].title}}
                </label>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-md-4 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_TARGET_COMPLETION_DATE']}}</b><sup>*</sup></small></div>
            <div class="col-md-8 col-lg-9 col-sm-12">
              <div class="input-group">
                <input type="text" class="form-control inputTextField" formControlName="completionDate" placeholder="YYYY-MM-DD HH:mm" aria-describedby="completionDateLabel" />
                <div class="input-group-append">
                  <span class="input-group-text inputTextField" id="completionDateLabel" (click)="toggleDatePicker()"><i class="fa fa-calendar"></i></span>
                </div>
              </div>
              <dl-date-time-picker [hidden]="hideDatePicker" startView="day" maxView="year" minView="minute" minuteStep="5" [(ngModel)]="selectedDate" (change)="dateEvents($event)" [ngModelOptions]="{standalone: true}">
              </dl-date-time-picker>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4 col-lg-3 col-sm-12 inputLabel"><small><b>{{i18nWords['CHANGE_REQUEST.LABEL_DETAILS_REMARKS']}}</b></small></div>
            <div class="col-md-8 col-lg-9 col-sm-12">
              <textarea name="remark" rows="4" formControlName="remark" cols="26" class="textarea-content inputTextField"></textarea>
            </div>
          </div>
        </ng-template>
      </ngb-panel>


    </ngb-accordion>
  </form>

  <div class="modal-footer">
    <button id="submitBtn" type="submit" class="btn btn-success btn-md pull-right" (click)="submitForm()">{{i18nWords['TICKETING.CREATE']}}<i [ngClass]="loader"></i></button>
  </div>
</div>
