<ng-template #disconnectedModal let-c="close" size="lg">
  <div class="modal-content">
    <div class="modal-body">
      <img src="assets/images/icon.png" class="modal-logo" />
      <div style="height:60px"></div>
      <h5 class="modal-topic"> {{translate('DISCONNECTED_MODAL.THANKS')}} </h5>
      <p class="rating-content" style="padding: '0px 20px 0px 20px'" [innerHTML]="translate('DISCONNECTED_MODAL.CLOSEDESCRIPTION')"></p>
      <br />
    </div>
  </div>
</ng-template>
