import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appContactNumber]'
})
export class ContactNumberDirective {
  constructor(public el: ElementRef) {
    this.el.nativeElement.onkeypress = (evt) => {
      if (evt.key == ';' || evt.which == 46 || evt.key == '-')
        return

      if (evt.which < 48 || evt.which > 57) {
        evt.preventDefault();
      }
    };
  }
}
