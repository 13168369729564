import { Component, OnInit } from '@angular/core';
import {
  ModalService,
  ChatboxService
} from '../../services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateTicketModalContentComponent } from '../create-ticket-modal-content'

@Component({
  selector: 'app-create-ticket-modal',
  templateUrl: './create-ticket-modal.component.html',
  styleUrls: ['./create-ticket-modal.component.css']
})
export class CreateTicketModalComponent implements OnInit {

  private subscribers: any = {};
  private currentModal: any;
  constructor(
    private modalService: ModalService,
    private chatboxService: ChatboxService,
    private ngbModal: NgbModal
  ) {
    this.init()
  }

  async init() {
    this.subscribers['modal'] = this.modalService.$modals.subscribe((modal: any) => {
      if (modal.event === 'open') {
        if (modal.name === 'createTicket') {
          this.chatboxService.$isBackgroundMode.next(true)
          this.currentModal = this.ngbModal.open(CreateTicketModalContentComponent, { size: 'lg', centered: true, keyboard: false, backdrop: 'static' });
        }
      } else if (modal.event === 'close') {
        this.chatboxService.$isBackgroundMode.next(false);
        if (this.currentModal)
          this.currentModal.close();
      }
    })
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    for (let subscriber in this.subscribers) {
      let sub: any = subscriber
      sub.unsubscribe()
    }
  }

}
