import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
declare var $;

@Component({
  selector: 'app-ui-carousel',
  templateUrl: './ui-carousel.component.html',
  styleUrls: ['./ui-carousel.component.css']
})
export class UiCarouselComponent implements OnInit {

  @Input() model: any;
  @Input() parent: any;
  @Output() emitEvent = new EventEmitter<any>();
  hideLeft: boolean = true;
  hideRight: boolean = true;
  scrollMinWidth: any;
  scrollEle: any;

  constructor(
    private el: ElementRef
  ) { }


  ngOnInit() {
    setTimeout(() => {
      let a2Obj = this;
      let width = 160 + 10;
      this.scrollMinWidth = (this.model.child.length * width + 10) + 'px';
      this.scrollEle = this.el.nativeElement.querySelector('.scroll')
      if ($(this.scrollEle).prop('scrollWidth') > Math.ceil($(this.scrollEle).width())) {
        this.hideRight = false;
        this.scrollEle.addEventListener(
          'scroll',
          function(event) {
            var $elm = $(a2Obj.scrollEle);
            let classNames = $elm.attr('class').split(" ");
            let index = -1;
            if ($elm.attr('class').indexOf("scroll") !== -1) {
              let name = $elm.attr('class').substr($elm.attr('class').indexOf("scroll"), 10);
            }
            if ($elm.scrollLeft() <= 0) {
              // most left
              a2Obj.hideLeft = true;
              a2Obj.hideRight = false;

            } else if ($elm.scrollLeft() + Math.ceil($elm.width() + 2) >= $elm.prop('scrollWidth')) {
              // most right
              a2Obj.hideLeft = false;
              a2Obj.hideRight = true;
            } else {
              a2Obj.hideLeft = false;
              a2Obj.hideRight = false;
            }
          },
          true // Capture event
        );
      }
    });
  }

  parseEmitEvent($event: string) {
    this.emitEvent.emit($event)
  }

  scrollLeft() {
    $(this.scrollEle).animate({
      scrollLeft: "-=180px"
    }, 500);
  }

  scrollRight() {
    $(this.scrollEle).animate({
      scrollLeft: "+=180px"
    }, 500);
  }

}
