import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef, NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import *  as moment from 'moment'

import {
  TranslationService,
  ModalService,
  SessionService,
  TicketService,
  ChatboxService,
  McservicesService,
  MessageDecoderService
} from '../../services'

import {
  emailValidator,
} from '../../directives';

@Component({
  selector: 'app-change-request-form-connectivity-content',
  templateUrl: './change-request-form-connectivity-content.component.html',
  styleUrls: ['./change-request-form-connectivity-content.component.scss']
})
export class ChangeRequestFormConnectivityContentComponent implements OnInit {

  @ViewChild('acc') step1Accordion: NgbAccordion;
  loading: boolean = false;
  loader: string = '';
  private i18nKeys: any = [
    'TICKETING.CREATE',
    'TICKETING.GID',
    'TICKETING.COMPANY_NAME',
    'TICKETING.CONTACT_NAME',
    'TICKETING.CONTACT_NUMBER',
    'TICKETING.EMAIL',
    'TICKETING.PLACEHOLDER_EMAIL',
    'TICKETING.PLACEHOLDER_CONTACT_NUMBER',
    'TICKETING.PLACEHOLDER_CONTACT_NAME',

    'CHANGE_REQUEST.LABEL_CONNECTIVITY',
    'CHANGE_REQUEST.LABEL_CONTACT_INFORMATION',
    'CHANGE_REQUEST.LABEL_TARGET_COMPLETION_DATE',
    'CHANGE_REQUEST.LABEL_LOCATION_SITE_ID',
    'CHANGE_REQUEST.LABEL_DETAILS_REMARKS',
    'CHANGE_REQUEST.LABEL_ADDRESS',
    'CHANGE_REQUEST.LABEL_CHANGE_REQUEST_DETAILS',
    'CHANGE_REQUEST.LABEL_CONFIGURATION_CHANGE',

    'CHANGE_REQUEST.OPTIONS_STATIC_ROUTE_MODIFICATION',
    'CHANGE_REQUEST.OPTIONS_NETWORK_PAREMETER_ROUTING',
    'CHANGE_REQUEST.OPTIONS_ONE_TO_ONE_MAPPING_NAT_RECONFIGURATION',
    'CHANGE_REQUEST.OPTIONS_QOS_COS_MODIFICATION',
    'CHANGE_REQUEST.OPTIONS_ACCESS_CONTROL_MODIFICATION',
    'CHANGE_REQUEST.OPTIONS_MC_ACCESS_PASSWORD_MODIFICATION',
    'CHANGE_REQUEST.OPTIONS_OTHERS',
    'CHANGE_REQUEST.CREATING'
  ]
  public locations: any = [];
  public hideDatePicker: boolean = true;
  public chevronState: any = {
    'panel-contact': true
  };
  public i18nWords: any = {};
  public user: any;
  public connectivityChangeOptions: any = []
  public changeRequestFormGroup: FormGroup;
  defaultData: any = {
    contactName: '',
    contactAddress: '',
    siteIds: [],
    contactEmail: '',
    contactNumber: '',
    completionDate: '',
    configuration: this.connectivityChangeOptions,
    remark: '',
  }

  /*
  {
  “gid:”45721”,
  “createrEmailAddress:”askj@qq.com”
    "contactAddress": "成都",
    "completionDate": "2019-06-19T03:22:48.331Z",
    "configuration": {
    "contact_number": "416541234135",
      "modifyAccessControl": true,
      "modifyMCAccesspass": true,
      "modifyNat": true,
      "modifyNetworkParemeter": true,
      "modifyQos": true,
      "modifyStaticRoute": true,
      "others": true,
      "remark": "OK",
      "siteIds": "8435418"
    },
    "domainType": "CEC",
    "email": "kjhj212@qq.com",
    "isDraft": true,
    "local_s": "en_US",
    "name": "小明",
    "timeZone": "GMT+8"
  }
  */

  constructor(
    private modalService: ModalService,
    private translationService: TranslationService,
    private sessionService: SessionService,
    private mcservicesService: McservicesService,
    private fb: FormBuilder,
    private messageDecoderService: MessageDecoderService,
    private chatboxService: ChatboxService,
  ) { }

  ngOnInit() {
    this.user = this.sessionService.getUserSession()
    this.initTranslation()
    this.initForm()
  }

  initTranslation() {
    let loaded = true
    for (const key of this.i18nKeys) {
      if (key === this.translationService.translateText(key)) {
        loaded = false
        return
      }
      this.i18nWords[key] = this.translationService.translateText(key)
    }
    if (!loaded) {
      setTimeout(() => {
        this.initTranslation()
      }, 1000)
    }
  }

  async initForm() {
    this.connectivityChangeOptions = [
      {
        value: 'modifyStaticRoute',
        title: this.i18nWords['CHANGE_REQUEST.OPTIONS_STATIC_ROUTE_MODIFICATION']
      },
      {
        value: 'modifyNetworkParemeter',
        title: this.i18nWords['CHANGE_REQUEST.OPTIONS_NETWORK_PAREMETER_ROUTING']
      },
      {
        value: 'modifyNat',
        title: this.i18nWords['CHANGE_REQUEST.OPTIONS_ONE_TO_ONE_MAPPING_NAT_RECONFIGURATION']
      },
      {
        value: 'modifyQos',
        title: this.i18nWords['CHANGE_REQUEST.OPTIONS_QOS_COS_MODIFICATION']
      },
      {
        value: 'modifyAccessControl',
        title: this.i18nWords['CHANGE_REQUEST.OPTIONS_ACCESS_CONTROL_MODIFICATION']
      },
      {
        value: 'modifyMCAccesspass',
        title: this.i18nWords['CHANGE_REQUEST.OPTIONS_MC_ACCESS_PASSWORD_MODIFICATION']
      },
      {
        value: 'others',
        title: this.i18nWords['CHANGE_REQUEST.OPTIONS_OTHERS'],
        checked: false
      }
    ]
    const configurationOptions = this.connectivityChangeOptions.map(control => new FormControl(false));
    let controls = {
      'configuration': new FormArray(configurationOptions),
      'contactName': new FormControl(this.defaultData.contactName, [Validators.required]),
      'contactNumber': new FormControl(this.defaultData.contactNumber, [Validators.required, Validators.minLength(8)]),
      'siteIds': new FormControl(this.defaultData.siteIds),
      'contactAddress': new FormControl(this.defaultData.contactAddress, [Validators.required]),
      'contactEmail': new FormControl(this.defaultData.contactEmail, [Validators.required, emailValidator()]),
      'completionDate': new FormControl(this.defaultData.completionDate, [Validators.required]),
      'remark': new FormControl(this.defaultData.remark)
    }
    this.changeRequestFormGroup = this.fb.group(controls)
    this.locations = []
    const allSites: any = await this.mcservicesService.getLocationSiteID()
    this.locations = this.locations.concat(allSites)
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  async validateForm() {
    var toExpandPanels = [];
    // expand subsection 1 if necessary
    if (!this.changeRequestFormGroup.get('contactAddress').valid ||
      !this.changeRequestFormGroup.get('contactName').value ||
      !this.changeRequestFormGroup.get('contactEmail').value ||
      !this.changeRequestFormGroup.get('contactNumber').value) {
      toExpandPanels.push('panel-contact');
    }
    // expand subsection 2 if necessary
    if (!this.changeRequestFormGroup.get('completionDate').valid) {
      toExpandPanels.push('panel-description');
    }
    if (toExpandPanels.length > 0) {
      this.step1Accordion.activeIds = toExpandPanels;
    }
  }

  async submitForm() {
    this.markFormGroupTouched(this.changeRequestFormGroup)
    // console.log(this.changeRequestFormGroup)
    this.validateForm()
    if ('VALID' === this.changeRequestFormGroup.status) {
      let changeRequestData = Object.assign({}, this.changeRequestFormGroup.value)
      changeRequestData.configuration = {}
      this.changeRequestFormGroup.value.configuration.map((x, i) => {
        if (x) {
          changeRequestData.configuration[this.connectivityChangeOptions[i].value] = true
          return this.connectivityChangeOptions[i].value
        }
      })
      if (!this.loading) {
        this.loading = true
        this.loader = 'fa fa-circle-o-notch fa-spin'
        let resp: any = await this.mcservicesService.createInternetChangeRequest(changeRequestData)
        if (resp.ok) {
          let output = Object.assign({ fromMe: false }, this.messageDecoderService.generateTextTypeComponent({text:this.i18nWords['CHANGE_REQUEST.CREATING']}))
          this.chatboxService.add(output)
          this.closeModal()
        }
      }
    }
  }

  toggleDatePicker() {
    this.hideDatePicker = !this.hideDatePicker
  }

  closeModal() {
    this.modalService.closeModal('changeRequest-connectivity')
  }

  updateChevron(event, acc) {
    this.chevronState = {}
    this.chevronState[event.panelId] = event.nextState
  }

  dateEvents(event) {
    if (event.value) {
      this.hideDatePicker = true
      this.changeRequestFormGroup.controls['completionDate'].setValue(moment(event.value).format("YYYY-MM-DD HH:mm"))
    }
  }

}
