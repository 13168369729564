import * as asyncClient from 'async';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoggerService } from './logger.service';
import { FolderService } from './folder.service';
import { TranslationService } from './translation.service';
import { SessionService } from './session.service';
import { LivechatService } from './livechat.service';

@Injectable()
export class MessageDecoderService {

  constructor(
    private logger: LoggerService,
    private folderService: FolderService,
    private translationService: TranslationService,
    private sessionService: SessionService,
    private sanitizer: DomSanitizer,
    private livechatService: LivechatService,
  ) {

  }

  async parseResult(obj: any) {

    var messageToPush = JSON.parse(JSON.stringify(obj));
    var fromMe = obj.fromMe || false;

    if (messageToPush.output)
      if (messageToPush.output.json) {
        messageToPush.output = messageToPush.output.json;
        return messageToPush
      }

    if (messageToPush.type === 'disconnected') {
      return messageToPush
    } else if (messageToPush.type === 'iframe') {
      return messageToPush
    } else if (messageToPush.type === 'list') {
      return messageToPush
    } else if (messageToPush.type === 'line') {
      return messageToPush
    } else if (messageToPush.type === 'image') {
      return messageToPush
    } else if (messageToPush.type === 'file-others') {
      return messageToPush
    }

    var contentArray = obj.content;

    if (obj.output)
      contentArray = contentArray || obj.output.text;

    if (!contentArray)
      return;

    var type = obj.type || 'text';

    messageToPush.fromMe = fromMe;
    messageToPush.content = contentArray;
    messageToPush.type = type;

    try {
      // this.logger.info(contentArray)
      if (contentArray[0].type) {
        messageToPush.output = messageToPush.output.text;
        return messageToPush
      }
    } catch (e) {
      // legacy string type contents
      // contentArray = contentArray.join()
      if ((typeof contentArray) != 'string')
        messageToPush.content = contentArray.join()
      // this.logger.info('legacy string type contents', e)
    }

    messageToPush.avatar = this.getAvatarBuffer(messageToPush.avatar)

    if (fromMe || messageToPush.liveChat) {
      messageToPush.type = 'text';
      if (fromMe) {
        messageToPush.fromMe = true;
      }
      messageToPush.content = messageToPush.content.replace(/\n|\\n/gi, '<br />')
      messageToPush.content = messageToPush.content.replace(/\r/gi, '');
      messageToPush.content = messageToPush.content.replace(/<br>/gi, '');
      messageToPush.content = messageToPush.content.replace(/<br \/><br \/>/gi, '<br />');
      let output = [await this.generateTextTypeComponent({ text: messageToPush.content })];
      delete messageToPush.content;
      messageToPush.output = output;
      return messageToPush;
    }

    // this.logger.info(contentArray)

    if (typeof contentArray !== 'string') {
      messageToPush.output = []
      for (let i in contentArray) {
        var content = contentArray[i];

        content = content.replace(/\\n/gi, '<br />')
        content = content.replace(/\r/gi, '');

        if (!messageToPush.isLiveChat) {
          content = content.replace(/<br>/gi, '');
          content = content.replace(/<ul>/gi, '<br /><ul>');
          content = content.replace(/<br \/><br \/>/gi, '<br />');
        }

        let tmpMessage = Object.assign({}, messageToPush);
        this.logger.info(tmpMessage)
        let outputArray = await this.splitAnnotations(content, tmpMessage);
        this.logger.info(outputArray)
        if (outputArray[0])
          messageToPush.type = outputArray[0].type

        this.logger.info(outputArray);
        messageToPush.output = messageToPush.output.concat(outputArray)

        if (+i == contentArray.length - 1)
          return messageToPush
      }
    } else {

      if (!messageToPush.isLiveChat) {
        messageToPush.content = messageToPush.content.replace(/\r/gi, '');
        messageToPush.content = messageToPush.content.replace(/<br>/gi, '');
        messageToPush.content = messageToPush.content.replace(/<ul>/gi, '<br /><ul>');
        messageToPush.content = messageToPush.content.replace(/<br \/><br \/>/gi, '<br />');
      }

      let outputArray = await this.splitAnnotations(messageToPush.content, messageToPush);
      if (outputArray[0])
        messageToPush.type = outputArray[0].type

      messageToPush.output = outputArray;

      this.logger.info(messageToPush);
      return messageToPush
    }
  }

  getAvatarBuffer(avatarName) {
    return this.livechatService.getAvatar(avatarName)
  }

  async splitAnnotations(content: string, messageObj: any) {
    this.logger.info('splitAnnotations', messageObj);
    let output = [];
    let isInitial
    let buttons = [];
    let hasTitle = true;
    let type = '';
    let carouselListOutput, imageButtonListOutput

    if (messageObj) {
      isInitial = messageObj.type === 'initial'
    }

    content = content.replace(/\\n/gi, '<br />')
    content = content.replace(/(?:\r\n|\r|\n|<br>)/gi, '')

    let carouselArray = content.match(/\{%(.*?)\}/gi)
    let imageButtonArray = content.match(/\{\^(.*?)\}/gi)

    if (carouselArray) {
      carouselListOutput = this.generateCarouselListTypeComponent({})
      if (carouselArray.length > 1) {

        carouselArray.forEach(c => {
          content = content.replace(c, '')
          c = c.substr(2, c.length - 3)
          let carouselSections = c.split('%;')
          let carouselButtons
          let carouselChild = []

          if (carouselSections[2]) {
            carouselButtons = carouselSections[2].split('^')
            if (carouselButtons.length > 1) {
              for (let i = 0; i < carouselButtons.length; ++i) {
                if (!carouselButtons[i])
                  continue

                carouselChild.push({
                  type: 'carouselButton',
                  is_core_component: false,
                  action: carouselButtons[i],
                  content: carouselButtons[i]
                })
              }
            }
          }

          carouselListOutput.child.push(this.generateCarouselTypeComponent({
            action: carouselSections[0],
            content: carouselSections[1],
            child: carouselChild
          }))
        })
      }
      content = content.replace(/<br \/>,/gi, '<br />')
      content = content.replace(/<br \/><br \/>/gi, '')
    }

    if (imageButtonArray) {
      imageButtonListOutput = this.generateimageButtonListTypeComponent({})

      if (imageButtonArray.length > 1) {
        imageButtonArray.forEach(c => {
          content = content.replace(c, '')
          c = c.substr(2, c.length - 3)
          let imageButtonSections = c.split('%^')
          let imageButtons
          let imageButtonChild = []

          if (imageButtonSections[1])
            imageButtonListOutput.child.push(this.generateimageButtonTypeComponent({
              action: imageButtonSections[1],
              content: imageButtonSections[0]
            }))
        })
      }

      content = content.replace(/<br \/>,/gi, '<br />')
      content = content.replace(/<br \/><br \/>/gi, '')
    }

    var folderRegex = /<br \/>*[\S\s]\*/gim;
    var folders = content.split(folderRegex).filter(String);

    if (content[0] === '*') {
      folders[0] = folders[0].substr(1)
      folders.unshift('')
    }

    let i = 0;
    if (folders.length === 1) {
      let buttonsArray = content.split('^');
      // this.logger.info('no folder', buttonsArray);

      if (content[0] === '|' || content[0] === '') { // remove | from the text
        buttonsArray.splice(0, 1);
      }

      buttonsArray = buttonsArray.filter(String);
      if (buttonsArray.length > 1) {
        for (let i = 0; i < buttonsArray.length; ++i) {

          buttonsArray[i] = buttonsArray[i].trim()

          if (i === 0) {
            if (content[0] === '^')
              hasTitle = false;
          }

          if (hasTitle && i === 0) {
            if (isInitial) {
              output.push(this.generateTextTypeComponent({ text: buttonsArray[i] }));
              type = 'hyperlink';
              // type = 'dice';
            } else {
              buttons.shift()
              output.push(this.generateTextTypeComponent({ text: buttonsArray[i] }))
            }
            continue;
          }

          if (type === 'hyperlink') {
            output.push(this.generateHyperlinkTypeComponent({ text: buttonsArray[i], action: buttonsArray[i] }));
          } else if (type === 'dice') {
            buttons.push(this.generateHyperlinkTypeComponent({ text: buttonsArray[i], action: buttonsArray[i] }));
          } else {
            buttons.push(this.generateButtonTypeComponent({ text: buttonsArray[i], action: buttonsArray[i] }));
          }
        }

        if (type === 'dice') {
          output.push(this.generateDiceTypeComponent(buttons))
        } else if (type !== 'hyperlink') {
          output.push(this.generateButtonListTypeComponent(buttons));
          messageObj.isButton = true;
        }
      } else {
        output.push(this.generateTextTypeComponent({ text: buttonsArray[0] }));
      }
    } else {
      // do folder splitting
      // this.logger.info('do folder splitting', folders)

      for (let i = 0; i < folders.length; ++i) {
        if (!folders[i])
          continue

        if (i === 0) {
          if (content[0] === '^')
            hasTitle = false
        }

        if (hasTitle && i === 0) {
          output.push(this.generateTextTypeComponent({ text: folders[i] }));
          continue;
        } else {
          output.push(await this.generateFolderTypeComponent({ text: folders[i] }))
        }

        if (i === folders.length - 1) {
          output.push(this.generateFeedbackTypeCompomnent())
        }
      }
    }

    if (carouselListOutput)
      output.push(carouselListOutput)
    if (imageButtonListOutput)
      output.push(imageButtonListOutput)

    this.logger.info(JSON.stringify(output))
    return output;
  }

  generateDiceTypeComponent(diceItems: any) {
    return {
      'type': 'dice',
      'content': '',
      'subcontent': '',
      'child': diceItems,
      'is_core_component': false
    }
  }

  generateLineTypecomponent(title: string) {
    return {
      'fromMe': false,
      'type': 'line',
      'output': [{
        'type': 'line',
        'content': title,
        'subcontent': '',
        'is_core_component': false
      }]
    }
  }

  generateCarouselTypeComponent(params: any) {
    return {
      type: 'carousel',
      action: params.action,
      content: params.content,
      subcontent: '',
      child: params.child || [],
      is_core_component: false
    }
  }

  generateUniPendingTypeComponent(params: any) {
    let messageObj = params.messageObj

    var pendingItems = messageObj.output.text
    var childOutput = []
    var policyNumber;

    for (let i = 0; i < pendingItems.length; ++i) {
      var descriptionArray = pendingItems[i].subcontent.split(/<-->/gi)
      descriptionArray.shift()

      var parsedDescription = ''

      descriptionArray.forEach((desc) => {
        parsedDescription += `<li class='pendDetails'> - ${desc}</li>`
      })

      pendingItems[i].subcontent = parsedDescription
      policyNumber = pendingItems[i].customFields.policyNumber
      childOutput.push(pendingItems[i])
    }

    let uniPendingOutput = {
      type: 'uniPending',
      content: '',
      subcontent: '',
      is_core_component: true,
      child: childOutput,
      customFields: {
        policyNumber: policyNumber
      }
    }
    let newMessageObj = Object.assign({}, messageObj)
    newMessageObj.output = [uniPendingOutput]

    return newMessageObj
  }

  generateimageButtonListTypeComponent(params: any) {
    return {
      type: 'imageButtonList',
      action: '',
      content: '',
      subcontent: '',
      child: params.child || [],
      is_core_component: true
    }
  }

  generateimageButtonTypeComponent(params: any) {
    return {
      type: 'imageButton',
      action: params.action,
      content: params.content,
      subcontent: '',
      child: params.child || [],
      is_core_component: true
    }
  }

  generateCarouselListTypeComponent(params: any) {
    return {
      type: 'carouselList',
      content: '',
      subcontent: '',
      child: params.child || [],
      is_core_component: true
    }
  }

  generateTextTypeComponent(params: any) {
    try {
      let text = params.text

      return {
        'type': 'text',
        'content': text,
        'subcontent': '',
        'is_core_component': false,
        customFields: params.customFields
      }
    } catch (e) {
      console.error(e)
    }
  }

  generateButtonListTypeComponent(buttonTypeComponents: any) {
    return {
      'type': 'buttonList',
      'content': '',
      'subcontent': '',
      'child': buttonTypeComponents,
      'is_core_component': false
    }
  }

  generateButtonTypeComponent(params: any) {
    let text = params.text
    let action = params.action

    return {
      'type': 'button',
      'subcontent': '',
      'content': text,
      'action': action,
      'is_core_component': false
    }
  }

  generateHyperlinkTypeComponent(params: any) {
    let text = params.text
    let action = params.action

    return {
      'type': 'hyperlink',
      'content': text,
      'action': action,
      'subcontent': '',
      'is_core_component': false
    }
  }

  generateFeedbackTypeCompomnent() {
    return {
      'type': 'feedback',
      'content': '',
      'subcontent': '',
      'is_core_component': false
    }
  }

  generateDisconnectTypeComponent() {
    return {
      'fromMe': false,
      'type': 'disconnected',
      'output': [{
        'type': 'disconnected',
        'content': '',
        'subcontent': '',
      }]
    }
  }

  async generateFolderTypeComponent(params: any) {
    let text = params.text
    let messageObj = params.messageObj

    let FOLDER_TYPE_COMPONENT = {
      'type': 'folder',
      'content': '',
      'subcontent': '',
      'is_core_component': false,
      'child': []
    }

    let hyperlinksArray = text.match(/\^(.*?<br \/>)|\^(.*?$)/gi) || []
    let i = 0
    while (i < hyperlinksArray.length) {
      text = params.text = text.replace(hyperlinksArray[i], '')
      hyperlinksArray[i] = hyperlinksArray[i].substr(1)

      FOLDER_TYPE_COMPONENT.child.push(this.generateHyperlinkTypeComponent({ text: hyperlinksArray[i], action: hyperlinksArray[i] }))
      i++
    }

    let folderContent = text.split('<br />')

    let folderTitle = folderContent[0]
    FOLDER_TYPE_COMPONENT.content = folderTitle

    folderContent.shift() // remove the folderTitle
    let childText = folderContent.join('<br />')

    this.generateHyperlinkTypeComponent(params)

    if (childText)
      FOLDER_TYPE_COMPONENT.child.unshift(this.generateTextTypeComponent({ text: childText }))
    // this.logger.info(FOLDER_TYPE_COMPONENT)

    return FOLDER_TYPE_COMPONENT
  }
}
