<div class="main-list" *ngFor="let child of filteredModel">
  <app-ui-ticket [model]="child"></app-ui-ticket>
</div>

<div *ngIf="showMore" class="main-list centered">
  <button class="btn btn-md custom-button" (click)="viewMore()">{{translate('SETTINGS.SHOWMORE')}}</button>
</div>

<div class="main-list centered" style="position:relative;margin-bottom:20px;">
  <div class='timestamp'>
    {{parent.timestamp | date: 'shortTime'}}
  </div>
</div>
