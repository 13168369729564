import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from "rxjs";
import * as async from 'async';
import * as _ from 'underscore'

import {
  TranslationService,
  ChatboxService,
  ModalService,
  SessionService,
  LoggerService,
  DebounceService,
  MessageDecoderService,
  FileService
} from '../../services';

@Component({
  selector: 'app-customer-chatbox',
  templateUrl: './customer-chatbox.component.html',
  styleUrls: ['./customer-chatbox.component.css']
})

export class CustomerChatboxComponent implements OnInit {

  private subs: Array<Subscription> = [];
  chatRecord: Array<any> = this.chatboxService.chatRecord;
  isLoading: boolean = false;
  isLiveChat: boolean = false;
  typingState: string = null;
  hasTimeout: boolean = false;
  showMore: boolean = false; currentPage
  isIOS: boolean = false;
  isAndroid: boolean = false;
  isDebouncing: boolean = false;
  isProd: boolean = this.chatboxService.isProd();
  channel: string = this.sessionService.getSessionChannel();
  public skin: string = '';

  intentConfidence: string = "#null:0";

  constructor(
    private translationService: TranslationService,
    private chatboxService: ChatboxService,
    private modalService: ModalService,
    private sessionService: SessionService,
    private elementRef: ElementRef,
    private debounceService: DebounceService,
    private logger: LoggerService,
    private messageDecoderService: MessageDecoderService,
    private fileService: FileService
  ) {

    if ((navigator.platform.indexOf("iPhone") != -1)
      || (navigator.platform.indexOf("iPod") != -1)
      // || (navigator.platform.indexOf("Mac") != -1)
      || (navigator.platform.indexOf("iPad") != -1))
      this.isIOS = true;
    else
      this.isAndroid = true;

    this.skin = this.sessionService.getSkin();
    this.subs.push(this.chatboxService.$chatRecord.subscribe((res) => {
      // this.logger.info(res);

      if (!this.sessionService.getisDisconnected())
        this.sessionService.resetTimeout();

      if (this.sessionService.getLiveChat()) {
        this.isLiveChat = this.sessionService.getLiveChat();
        this.sessionService.pauseTimeout();
      } else {
        this.isLiveChat = false;
      }

      this.parseResult(res)
    }))

    this.subs.push(this.sessionService.$session.subscribe((timeoutFlag: boolean) => {
      this.hasTimeout = true;
      this.modalService.openModal('timeout');
    }))

    // this.modalService.openModal('changeRequest')

    this.subs.push(this.chatboxService.$isLoading.subscribe((flag: boolean) => {
      if (this.sessionService.getisDisconnected()) {
        this.typingState = null;
        this.isLoading = false;
        return
      }
      if (flag) {
        this.typingState = "typing";
        this.isLoading = flag;
      } else {
        this.typingState = null;
        this.isLoading = flag;
      }
    }))
  }

  translateText(key: string) {
    return this.translationService.translateText(key)
  }

  async parseResult(obj: any) {
    // this.logger.info(obj)
    if (!obj) return;
    let messageToPush = JSON.parse(JSON.stringify(obj));
    let newOutput
    if (messageToPush.actions) {
      switch (messageToPush.actions[0].name) {
        case "runCreateTicket":
          this.modalService.openModal("createTicket")
          return
        case "runCreateConnectivityChangeRequestForm":
          this.modalService.openModal("changeRequest-connectivity")
          return
        case "runCreateTrustChangeRequestForm":
          this.modalService.openModal("changeRequest-trust")
          return
        case "runCreateCloudChangeRequestForm":
          this.modalService.openModal("changeRequest-cloud")
          return
        case "triggerLiveChat":
          if (!this.sessionService.getLiveChat()) {
            newOutput = await this.messageDecoderService.parseResult(messageToPush);
            this.renderMultipleResponseType(newOutput);
            this.chatboxService.requestLiveChat()
            return
          }
        case "autoCreateTicket":
          if (!this.sessionService.getLiveChat()) {
            this.chatboxService.requestLiveChat()
            // return
          }
      }
    }

    if (messageToPush.type === "disconnected") {
      return this.renderMultipleResponseType(messageToPush);
    } else if (messageToPush.type === "iframe") {
      return this.renderMultipleResponseType(messageToPush);
    } else if (messageToPush.type === "line") {
      return this.renderMultipleResponseType(messageToPush);
    }

    var contentArray = obj.content;
    if (messageToPush.type === 'file') {
      let fileType = (this.fileService.isImageType(obj.filename) ? 'image' : 'file-others')
      messageToPush.output = [{
        type: fileType,
        content: obj.content,
        subcontent: obj.filename,
      }]
      messageToPush.type = fileType
    }

    if (obj.output)
      contentArray = obj.output.json || obj.output.text;

    if (!contentArray)
      return;

    newOutput = await this.messageDecoderService.parseResult(messageToPush);

    this.logger.info("customer decoder", newOutput);
    if (!newOutput) {
      return this.renderMultipleResponseType(messageToPush)
    }
    this.renderMultipleResponseType(newOutput);
  }

  renderMultipleResponseType(messageToPush) {
    for (let output of messageToPush.output) {
      let duplicated = this.checkDuplicated({ type: output.type, initial: messageToPush.initial }, this.chatRecord.length - 1)
      let obj: any = {
        fromMe: messageToPush.fromMe || false,
        output: [output],
        context: messageToPush.context,
        id: messageToPush.id,
        status: messageToPush.status,
        memory: messageToPush.memory,
        isButton: messageToPush.isButton,
        liveChat: messageToPush.liveChat,
        duplicated: duplicated,
        type: output.type,
        actions: messageToPush.actions,
        timestamp: messageToPush.timestamp,
        sender: messageToPush.sender,
        avatar: messageToPush.avatar,
        event: messageToPush.event
      }

      switch (obj.type) {
        case "iframe":
          obj.isIframe = true
          break
        case "carouselList":
        case "buttonList":
        case "list":
        case "line":
        case "imageButtonList":
        case "ticketList":
        case "serviceList":
          obj.isMultiple = true
          obj.customComponent = true
          break
        default:
          obj.isMultiple = true
        // obj.isOthers = true
      }
      this.chatRecord.push(obj);
    }
  }

  async parseEmitEvent(params: any) {
    try {
      let text, messageObj

      if (typeof params === "string") {
        text = params
      } else {
        text = params.text
        messageObj = params.messageObj
        messageObj.customFields = params.customFields
        messageObj.isButton = params.isButton
      }
      this.logger.info(messageObj)

      let tmpMessageObj = JSON.parse(JSON.stringify(messageObj))

      text = text.replace(/(?:\r\n|\r|\n|<br \/>)/gi, "")

      tmpMessageObj.content = text
      tmpMessageObj.memory = Object.assign({}, tmpMessageObj)
      tmpMessageObj.fromMe = true
      tmpMessageObj.output = await this.messageDecoderService.generateTextTypeComponent({ text: text })
      delete tmpMessageObj.output

      this.sendMessage(tmpMessageObj)
    } catch (e) {
      this.logger.error(e)
    }
  }
  ngOnInit() {

  }

  ngOnDestroy() {
    for (let sub of this.subs) {
      sub.unsubscribe()
    }
  }

  sendMessage(messageObj: any) {
    var newMessageObj = JSON.parse(JSON.stringify(messageObj));
    newMessageObj.fromMe = true;
    this.chatboxService.sendRequest(newMessageObj);
  }

  /**
   * to be used on the HTML page to apply the from-me or from-them class to the message
   */
  addClassFromMe(row: any) {
    const isFileType = row.type === 'image' || row.type === 'file-others'
    let fileContainer = ''
    if (isFileType) {
      fileContainer = 'file-container'
    }

    if (row.fromMe) {
      return `from-me ${this.skin} offset-1 ${fileContainer}`
    } else {
      return `from-them ${fileContainer}`
    }
  }

  isArray(param: any) {
    if (param instanceof Array)
      return true;
    else
      return false;
  }

  /**
   * call the modalService to trigger 'open' the modal component
   */
  openModal(modalName: string) {
    this.modalService.openModal(modalName);
  }

  async disconnectLivechat() {
    let resp = await this.chatboxService.disconnectLiveChat("disconnect")
    this.logger.info('disconnectLiveChat', resp)
  }

  refreshSession() {
    // if it's disconnected
    // create a new session // means reauthenticate the user based on the current information
    // if the user sesison has been deleted then how can i retireve the users information?
    // use the refreshID to re-login
    if (!this.isDebouncing) {
      this.isDebouncing = true
      this.debounceService.debounce(() => {
        var lang = this.sessionService.getLanguage();
        this.sessionService.refreshSession(lang);
      }, true, 2000)()
    }
  }

  call(fn: string) {
    eval("this." + fn);
  }

  checkDuplicated(obj: any, index: number) {
    try {
      if (obj.type === 'line')
        return true
      if (obj.type === 'carouselList')
        return true
      if (obj.type === 'buttonList')
        return true
      if (obj.type === 'initial')
        return true

      if (index > 0) {
        if (this.chatRecord[index].type === 'line') // check previous record
          return false
      }
      return false;
    } catch (e) {
      return false
    }
  }
  toggleShowMore(index: number) {
    let ele: any = this.elementRef.nativeElement.querySelector(`#chat-${index}`);
    let eleClass: any = ele.className.split(" ");

    if (eleClass.indexOf("expanded") === -1) {
      ele.className += " " + "expanded";
      this.chatRecord[index].showMore = true;
    } else {
      ele.className = ele.className.replace("expanded", "collpased");
      this.chatRecord[index].showMore = false;
    }
  }

}
