import { Component, OnInit, Injectable } from '@angular/core';
import { environment } from '../../environments/environment'
import { Subject } from "rxjs";
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoggerService } from './logger.service';
import { SessionService } from './session.service';

@Injectable()
export class ModalService {

  public $modals = new Subject<any>();

  private _iframeSrc: string = null;
  private _title: string = "";

  constructor(
    private http: HttpClient,
    private logger: LoggerService,
    private sessionService: SessionService,
  ) {
  }

  openModal(modalName: string) {
    this.$modals.next({ name: modalName, event: "open" });
  }

  closeModal(modalName: string) {
    this.$modals.next({ name: modalName, event: "close" });
  }

  postReview(payload: any): void {
    this.logger.info(payload);
    let url = environment.serverUrl + '/api/review';
    this.http.post(url, payload).subscribe((res) => {
      this.logger.info(res);
    }, (err) => {
      this.logger.error(err);
    });
  }

  get iframeSrc(): string { return this._iframeSrc; }
  set iframeSrc(setIframSrc: string) { this._iframeSrc = setIframSrc; }
  get title(): string { return this._title; }
  set title(setTitle: string) { this._title = setTitle; }
}
