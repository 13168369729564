<div class="enlarge-container" [ngClass]="enlarged?'show':'hide'">
  <div class="enlarged-bar">
    <div class="modal-button" (click)="downloadFile();false">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75 103" class="enlarged-bar-icons">
        <g>
          <g>
            <rect x="31" width="13" height="56" />
            <polygon points="37.46 86 9 56 65.92 56 37.46 86" />
            <rect y="92" width="75" height="11" />
          </g>
        </g>
      </svg>
    </div>

    <div class="modal-button" (click)="closeEnlarge()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.16 159.16" class="enlarged-bar-icons">
        <g>
          <g>
            <line class="cls-1" x1="2.83" y1="2.83" x2="156.33" y2="156.33" />
            <line class="cls-1" x1="156.33" y1="2.83" x2="2.83" y2="156.33" />
          </g>
        </g>
      </svg>
    </div>
  </div>

  <div class="image-container" (click)="closeEnlarge()">
    <div class="enlargeModal">
      <img [src]="filepath" (click)="$event.stopPropagation()" />
    </div>
  </div>
</div>
