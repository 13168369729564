import { Injectable } from '@angular/core';

@Injectable()
export class FolderService {

  private folderId: number = 0;

  constructor() {

  }

  nextFolderId(): number {
    return this.folderId++;
  }

}
