import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ui-list-item',
  templateUrl: './ui-list-item.component.html',
  styleUrls: ['./ui-list-item.component.css']
})
export class UiListItemComponent implements OnInit {
  @Input() model: any;
  @Input() context: any;
  @Output() emitEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  parseEmitEvent($event:string){
    this.emitEvent.next($event)
  }

}
