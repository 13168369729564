import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { TranslationService } from '../../services/translation.service'

@Component({
  selector: 'app-ui-service',
  templateUrl: './ui-service.component.html',
  styleUrls: ['./ui-service.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiServiceComponent implements OnInit {

  @Input() model: any;
  @Input() parent: any;
  info: any;
  time: any;
  rack_number_or_remarks: string = '';
  id_or_remarks: string = '';
  constructor(
    private elementRef: ElementRef,
    private translationService: TranslationService,
  ) { }

  ngOnInit() {
    switch (this.parent.customFields.type) {
      case 'mpls':
        this.id_or_remarks = this.model.customFields.mc_site_remark || this.model.customFields.site_id
        this.transformNullValues()
        this.info = {
          is_core_component: false,
          content: this.translateText('MC_DB.DETAILED_TITLE'),
          subcontent: '',
          child: [{
            content: `
            ${this.generateGridHTML(this.translateText('MC_DB.SITE_ID'), this.model.customFields.site_id)}
            ${this.generateGridHTML(this.translateText('MC_DB.LOCATION'), this.renderLanguageProperties('pop_name'))}
            ${this.generateGridHTML(this.translateText('MC_DB.DISTRICT'), this.renderLanguageProperties('district'))}
            ${this.generateGridHTML(this.translateText('MC_DB.CE_WAN'), this.model.customFields.ce_wan_ip)}
            ${this.generateGridHTML(this.translateText('MC_DB.REMARKS'), this.model.customFields.mc_site_remark)}
          `,
            subcontent: null,
            is_core_component: false,
            type: 'text'
          }],
          type: 'folder-item'
        }
        break;
      case 'internet':
        this.id_or_remarks = this.model.customFields.mc_site_remark || this.model.customFields.source_system_reference_id_for_traffic
        this.transformNullValues()
        this.info = {
          is_core_component: false,
          content: this.translateText('MC_DB.DETAILED_TITLE'),
          subcontent: '',
          child: [{
            content: `
            ${this.generateGridHTML(this.translateText('MC_DB.PORT_ID'), this.model.customFields.source_system_reference_id_for_traffic)}
            ${this.generateGridHTML(this.translateText('MC_DB.REMARKS'), this.model.customFields.mc_site_remark)}
            ${this.generateGridHTML(this.translateText('MC_DB.LOCATION'), this.renderLanguageProperties('pop_name'))}
            ${this.generateGridHTML(this.translateText('MC_DB.GID'), this.model.customFields.gid)}
          `,
            subcontent: null,
            is_core_component: false,
            type: 'text'
          }],
          type: 'folder-item'
        }
        break;
      case 'datahouse':
        this.rack_number_or_remarks = this.model.customFields.mc_site_remark || this.model.customFields.rack_number
        this.transformNullValues()
        this.info = {
          is_core_component: false,
          content: this.translateText('MC_DB.DETAILED_TITLE'),
          subcontent: '',
          child: [{
            content: `
              ${this.generateGridHTML(this.translateText('MC_DB.RACK_NUMBER'), this.model.customFields.rack_number)}
              ${this.generateGridHTML(this.translateText('MC_DB.REMARKS'), this.model.customFields.mc_site_remark)}
              ${this.generateGridHTML(this.translateText('MC_DB.GID'), this.model.customFields.gid)}
            `,
            subcontent: null,
            is_core_component: false,
            type: 'text'
          }],
          type: 'folder-item'
        }
        break;

      case 'cloud':
        this.transformNullValues()
        this.info = {
          is_core_component: false,
          content: this.translateText('MC_DB.DETAILED_TITLE'),
          subcontent: '',
          child: [{
            content: `
              ${this.generateGridHTML(this.translateText('MC_DB.RESOURCE_POOL'), this.model.customFields.resourcepool_name)}
            `,
            subcontent: null,
            is_core_component: false,
            type: 'text'
          }],
          type: 'folder-item'
        }
        break;
      case 'security':
        break;
      case 'maintenance':
      case 'maintenanceResolved':
        if (this.model.customFields.emailInfo)
          this.info = {
            is_core_component: false,
            content: this.translateText('MC_DB.DETAILED_TITLE'),
            subcontent: '',
            child: [{
              content: `
            ${this.model.customFields.emailInfo.emailContent}
          `,
              subcontent: null,
              is_core_component: false,
              type: 'text'
            }],
            type: 'folder-item'
          }
        break;
    }
  }

  transformNullValues() {
    for (let key in this.model.customFields) {
      if (!this.model.customFields[key]) {
        this.model.customFields[key] = '-'
      }
    }
  }

  generateGridHTML(label, content) {
    if (!content) {
      if (content != 0) {
        content = '-'
      }
    }
    let str = `
      <div class="row">
        <div class="col-4 label">
          <p class="noMargin">${label}</p>
        </div>
        <div class="col"><p class="noMargin">${content}</p></div>
      </div>
      `
    return str
  }

  translateText(text: string) {
    return this.translationService.translateText(text)
  }

  renderLanguageProperties(key: string) {
    let langs = ['chn_sim', 'chn_tra', 'english']
    for (let lang of langs) {
      if (this.model.customFields.hasOwnProperty(key + '_' + lang))
        return this.model.customFields[key + '_' + lang]
    }
    return null
  }

}
