import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ui-iframe',
  templateUrl: './ui-iframe.component.html',
  styleUrls: ['./ui-iframe.component.css']
})
export class UiIframeComponent implements OnInit {

  @Input() model: any
  @Output() emitEvent = new EventEmitter<any>()


  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.model.action = this.sanitizer.bypassSecurityTrustResourceUrl(this.model.action)
  }

}
