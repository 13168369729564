declare const require;
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoggerService } from './logger.service';

@Injectable()
export class SessionService {

  private conversationID: string;
  public $liveChatListener: Observable<any>;
  private session: any;
  private isDisconnected: boolean = false;
  public $session = new Subject<any>();
  public $isQueing = new Subject<any>();
  public $isLiveChat = new Subject<any>();
  private userName: string;
  private entryPointLabel: string;
  private userID: string;
  private sessionChannel: string = '';
  private email: string = '';
  private timeZone: string = '';
  private refreshToken: string;
  private liveChat: boolean = false;
  private isQueing: boolean = false;
  private TIMEOUT_MINUTES: number = 1;
  private LIST_ITEM_PER_PAGE: number = 5;
  private language: string;
  private sessionToken: string;
  private company: string;
  private skin: string;

  constructor(
    private cookieService: CookieService,
    private http: HttpClient,
    private logger: LoggerService,
  ) {
    if (this.sessionChannel.indexOf('corp') < 0)
      this.initCompany()
  }

  initSession(token: string): void {
    this.sessionToken = token
  }

  async initCompany() {
    setTimeout(async () => {
      let resp: any = await this.getCompanyName()
      this.setCompany(resp.company)
    }, 2000)
  }

  getSessionToken(): string {
    return this.sessionToken
  }
  getLanguage(): string {
    let lang: any = this.language || this.cookieService.get('lang') || 'ZH';
    if (lang) {
      lang = lang.toUpperCase() || 'ZH';
    }
    return lang;
  }

  getLiveChat(): boolean {
    return this.liveChat;
  }

  getConversationID(): string {
    return this.conversationID;
  }

  getisDisconnected(): boolean {
    return this.isDisconnected;
  }

  getRefreshToken(): string {
    return this.refreshToken;
  }

  getSessionChannel(): string {
    return this.sessionChannel;
  }


  setRefreshToken(token: string): void {
    this.refreshToken = token;
  }

  setConversationID(id: string): void {
    this.conversationID = id;
  }

  setUserName(name: string): void {
    this.userName = name;
  }

  setCompany(name: string): void {
    this.company = name;
  }

  setUserID(id: string): void {
    this.userID = id;
  }

  setEmail(email: string): void {
    this.email = email
  }

  setTimeZone(timeZone: string): void {
    this.timeZone = timeZone
  }

  setSessionChannel(str: string): void {
    this.entryPointLabel = str;
    switch (str) {
      case 'corp-cpc':
        this.sessionChannel = 'corp-cpc';
        break;
      case 'corp-cec':
        this.sessionChannel = 'corp-cec';
        break;
      case 'cpc':
        this.sessionChannel = 'cpc';
        break;
      case 'cec':
        this.sessionChannel = 'cec';
        break;
      default:
        this.sessionChannel = 'cpc';
    }
    this.getSystemSettings();
  }

  setLiveChat(flag: boolean): void {
    this.liveChat = flag;
  }

  setIsQueing(flag: boolean): void {
    this.isQueing = flag;
    this.$isQueing.next(flag);
  }

  getIsQueing(): boolean {
    return this.isQueing
  }

  async getSession() {
    var url = environment.serverUrl + '/api/login/me';
    return this.http.get(url).toPromise()
  }

  async getCompanyName() {
    var url = environment.serverUrl + '/api/login/me/company';
    return this.http.get(url).toPromise()
  }

  setLanguage() {
    var url = environment.serverUrl + '/api/login';
    return this.http.patch(url, { lang: this.getLanguage() }, {
      observe: 'response'
    });
  }

  setInitialLanguage(language: any) {
    if (language) {
      this.language = language.toUpperCase();
      this.cookieService.put('lang', language.toUpperCase());
    }
  }

  getUserSession(): any {
    return {
      userID: this.userID,
      userName: this.userName,
      conversationID: this.conversationID,
      liveChat: this.liveChat,
      company: this.company,
      channel: this.sessionChannel,
      email: this.email,
      timeZone: this.timeZone,
      lang: this.getLanguage(),
      refreshToken: this.refreshToken
    }
  }

  redirectToError(): void {
    window.location.href = environment.serverUrl + '/404.html';
  }

  async closeSession() {
    var url = environment.serverUrl + '/api/login';
    this.isDisconnected = true;
    this.pauseTimeout();
    return this.http.delete(url).toPromise()
  }

  refreshSession(lang: string): any {
    let url = environment.serverUrl + '/api/login';
    return this.http.put(url, { lang: lang, refreshToken: this.getRefreshToken() }).subscribe((res: any) => {
      this.logger.info(res);
      if (res.url)
        window.location.href = res.url;
    }, (e) => {
      this.logger.error(e);
    })
  }

  async getSystemSettings() {
    try {
      let url = environment.serverUrl + '/api/general/settings';
      let resp: any = await this.http.get(url).toPromise()
      this.TIMEOUT_MINUTES = parseInt(resp.CHATBOT_TIME_OUT);
      this.LIST_ITEM_PER_PAGE = parseInt(resp.LIST_ITEM_PER_PAGE);
      this.resetTimeout();
      return resp
    } catch (e) {
      this.logger.error(e)
    }
  }

  getListItemPerPage() {
    return this.LIST_ITEM_PER_PAGE
  }

  resetTimeout() {
    clearTimeout(this.session);
    let aObj = this;
    this.session = setTimeout(() => {
      aObj.$session.next(true);
      clearTimeout(aObj.session);
    }, 1000 * 60 * this.TIMEOUT_MINUTES)
  }

  pauseTimeout() {
    let aObj = this;
    clearTimeout(aObj.session);
  }

  getSkin(): string {

    if (this.skin)
      return this.skin

    switch (this.sessionChannel) {
      case 'cpc':
      case 'cec':
        this.skin = 'mc';
        break

      case 'corp-cpc':
      case 'corp-cec':
        this.skin = 'corp';
        break;
    }
    return this.skin
  }

}
