import { Component, OnInit } from '@angular/core';
import { UploaderService } from '../../services/uploader.service'

@Component({
  selector: 'app-enlarged-image-modal',
  templateUrl: './enlarged-image-modal.component.html',
  styleUrls: ['./enlarged-image-modal.component.css']
})
export class EnlargedImageModalComponent implements OnInit {


  public enlarged: boolean = false;
  public filepath: string = '';
  private subs: any = [];

  constructor(
    private uploaderService: UploaderService
  ) { }

  ngOnInit() {
    this.subs.push(this.uploaderService.$isEnlarged.subscribe(res => {
      if (!res) {
        this.enlarged = null;
        this.filepath = ''
      } else {
        this.enlarged = res.enlarged;
        this.filepath = res.filepath;
      }
    }))
  }

  closeEnlarge() {
    this.uploaderService.$isEnlarged.next(null)
  }

  downloadFile() {
    window.open(`${this.filepath}&download=true`, '_blank')
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe()
    })
  }

}
