import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { TranslationService } from '../../services/translation.service'
import { SessionService } from '../../services/session.service'
import * as moment from 'moment'

@Component({
  selector: 'app-ui-ticket',
  templateUrl: './ui-ticket.component.html',
  styleUrls: ['./ui-ticket.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiTicketComponent implements OnInit {

  @Input() model: any;
  fault: any;
  service: any;
  info: any;
  time: any;
  user: any;
  constructor(
    private elementRef: ElementRef,
    private translationService: TranslationService,
    private sessionService: SessionService,
  ) {
    this.user = this.sessionService.getUserSession()
  }

  ngOnInit() {
    this.info = {
      is_core_component: false,
      content: this.translateText('TICKETING.TITLE_INFO'),
      subcontent: '',
      child: [{
        content: `
            ${this.generateGridHTML(this.translateText('TICKETING.CREATE_TIME'), this.parseTime(this.model.customFields.create_TIME))}
            ${this.generateGridHTML(this.translateText('TICKETING.RESOLVED_TIME'), this.parseTime(this.model.customFields.resolved_TIME))}
            ${this.generateGridHTML(this.translateText('TICKETING.GID'), this.model.customFields.gid_)}
            ${this.generateGridHTML(this.translateText('TICKETING.CASE_TYPE'), this.model.customFields.case_TYPE)}
            ${this.generateGridHTML(this.translateText('TICKETING.COMPANY_NAME'), this.model.customFields.company_NAME)}
            ${this.generateGridHTML(this.translateText('TICKETING.FAULT_REASON'), this.model.customFields.fault_REASON)}
            ${this.generateGridHTML(this.translateText('TICKETING.FAULT_SYMPTOM'), this.model.customFields.fault_SYMPTOM)}
          `,
        subcontent: null,
        is_core_component: false,
        type: 'text'
      }],
      type: 'folder-item'
    }
  }

  generateGridHTML(label, content) {
    if (!content) {
      content = '-'
    }

    content = content.replace(/\n/gi, '<br />')
    let str = `
      <div class="row">
        <div class="col-4 label">
          <p class="noMargin">${label}</p>
        </div>
        <div class="col"><p class="noMargin">${content}</p></div>
      </div>
      `
    return str
  }

  translateText(text: string) {
    return this.translationService.translateText(text)
  }

  parseTime(date: any) {
    if (!date) {
      return date
    }
    let d = moment(date)
    return moment(d).utcOffset(this.user.timeZone).format('YYYY-MM-DD HH:mm:ss') + ` (${this.user.timeZone})`
  }

}
