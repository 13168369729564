import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UploaderService } from '../../services/uploader.service'


@Component({
  selector: 'app-ui-image',
  templateUrl: './ui-image.component.html',
  styleUrls: ['./ui-image.component.css']
})
export class UiImageComponent implements OnInit {

  @Input() model: any;
  @Input() parent: any;
  public enlarged: boolean = false;

  constructor(
    private uploaderService: UploaderService
  ) { }


  ngOnInit() {
  }

  enlarge() {
    this.uploaderService.$isEnlarged.next({ enlarged: true, filepath: this.model.content })
  }

}
