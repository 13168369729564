<div class="modal-content">

  <div class="modal-header">
    <h5 class="modal-title">Create Ticket</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="exit()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="ticketFormGroup" (ngSubmit)="createTicket()" #formDir="ngForm">
      <div class="form-group">
        <label for="customerID">{{translate('TICKETING.GID')}}</label>
        <p class="readonly">{{userID}}</p>
      </div>

      <div class="form-group">
        <label for="companyName">{{translate('TICKETING.COMPANY_NAME')}}</label>
        <p class="readonly">{{company}}</p>
      </div>

      <div class="form-group">
        <label for="caseType">{{translate('TICKETING.CASE_TYPE')}} <sup class="required">*</sup></label>
        <select id="caseType" class="form-control" formControlName="caseType" (change)="onCaseChange($event.target.value)">
          <option *ngFor="let type of caseType" [value]="type">{{type}}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="serviceType">{{translate('TICKETING.TYPE')}} <sup class="required">*</sup></label>
        <select id="serviceType" class="form-control" formControlName="serviceType">
          <option value="" disabled selected>{{translate('TICKETING.PLACEHOLDER_SELECT')}}</option>
          <option *ngFor="let type of serviceTypes[selectedCaseType]" [value]="type">{{type}}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="contactName">{{translate('TICKETING.CONTACT_NAME')}} <sup class="required">*</sup></label>
        <input type="text" class="form-control" id="contactName" [placeholder]="translate('TICKETING.PLACEHOLDER_CONTACT_NAME')" formControlName="contactName" required maxlength="34">
      </div>

      <div class="form-group">
        <label for="contactNumber">{{translate('TICKETING.CONTACT_NUMBER')}} <sup class="required">*</sup></label>
        <textarea class="form-control" id="contactNumber" rows="2" appContactNumber [placeholder]="translate('TICKETING.PLACEHOLDER_CONTACT_NUMBER')" formControlName="contactNumber" required maxlength="500"></textarea>
      </div>

      <div class="form-group">
        <label for="email">{{translate('TICKETING.EMAIL')}} <sup class="required">*</sup></label>
        <textarea class="form-control" id="email" rows="2" [placeholder]="translate('TICKETING.PLACEHOLDER_EMAIL')" formControlName="contactEmail" required maxlength="500"></textarea>
      </div>

      <div class="form-group">
        <label for="faultSymtom">{{translate('TICKETING.FAULT_SYMPTOM')}} <sup class="required">*</sup></label>
        <textarea class="form-control" id="faultSymtom" rows="3" maxlength="499" [placeholder]="translate('TICKETING.FAULT_SYMPTOM')" formControlName="faultSymtom" required></textarea>
      </div>

      <div class="form-group">
        <label for="testResult">{{translate('TICKETING.TEST_RESULT')}}</label>
        <textarea class="form-control" id="testResult" rows="3" maxlength="3499" [placeholder]="translate('TICKETING.TEST_RESULT')" formControlName="testResult"></textarea>
      </div>
    </form>

  </div>

  <div class="modal-footer">
    <button id="submitBtn" type="submit" class="btn btn-success btn-md pull-right" (click)="createTicket()">{{translate('TICKETING.CREATE')}} <i [ngClass]="loader"></i></button>
  </div>
</div>
