import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-ui-line-breaker',
  templateUrl: './ui-line-breaker.component.html',
  styleUrls: ['./ui-line-breaker.component.css']
})
export class UiLineBreakerComponent implements OnInit {

  @Input() model: any
  @Input() parent: any
  content: string = ''

  constructor() { }

  ngOnInit() {
    this.content = this.model.content
  }

}
