import { Injectable } from '@angular/core';
import * as mime from 'mime';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private imageExtentions: any = ['image/png', 'image/jpg', 'image/jpeg']

  constructor() { }

  isImageType(filename: string) {
    return this.imageExtentions.indexOf(this.getFileExt(filename)) > -1 
  }

  getFileExt(filename: string) {
    return mime.getType(filename);
  }
}
