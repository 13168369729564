import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoggerService, RedirectService } from '../../services';
declare var window;

@Component({
  selector: 'app-ui-text',
  templateUrl: './ui-text.component.html',
  styleUrls: ['./ui-text.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiTextComponent implements OnInit {

  @Input() model: any;
  @Input() parent: any;
  content: any;
  subcontent: any;

  constructor(
    private logger: LoggerService,
    private sanitizer: DomSanitizer,
    private redirectService: RedirectService,
    private changeDectectionRef: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    let a2Obj = this
    window.navigateExternal = function (link) {
      a2Obj.navigateExternal(link)
    }
    // this.logger.info(this.model);
    let contents
    let parsedContents = []
    let found = false
    if (this.model.content && this.parent && this.parent.fromMe === true) {
      contents = this.model.content.split(' ')
      contents.map(x => {
        if (x.length > 20) {
          parsedContents.push(`<span style="word-break:break-all;">${x}</span>`)
          found = true
        } else {
          parsedContents.push(x)
        }
      })
    }
    if (this.parent && !this.parent.fromMe) {
      let urlRegx = new RegExp(/href[^>]*/gi)
      let match
      match = this.model.content.match(urlRegx)
      if (match) {
        for (let urlsWithTag of match) {
          let urlArray = urlsWithTag.match(/(https?:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_ \+.~;#?()&//=]*)/gi)
          if (urlArray) {
            urlArray = urlArray.map(x => {
              if (x)
                return x.trim()
            })
            urlArray = this.excludeNonURL(urlArray)
            for (let url of urlArray) {
              url = encodeURI(url);
              this.model.content = this.model.content.replace(urlsWithTag, ` class="hyperlinks" onclick="navigateExternal('${url}')" target="_system"`);
            }
          }
        }
      }
    }

    if (found) {
      this.content = this.sanitizer.bypassSecurityTrustHtml(parsedContents.join(' '))
    } else {
      this.content = this.sanitizer.bypassSecurityTrustHtml(this.model.content)
    }
  }

  excludeNonURL(urlArray: any) {
    let newArray = []
    if (urlArray)
      urlArray.map(x => {
        if (x.indexOf('mailto') < 0) {
          newArray.push(x)
        }
      })
    return newArray
  }

  navigateExternal(link) {
    this.redirectService.navigateExternal(link)
    if (this.model.customFields) {
      if (this.model.customFields.dissapearOnClick) {
        this.parent.hidden = true;
        this.changeDectectionRef.detectChanges()
      }
    }
  }

}
