<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('TICKETING.CASE_ID_')}} </p>
      </div>
      <div class="col">
        <p class="noMargin"> {{model.customFields.case_ID_}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('TICKETING.STATUS')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.status_MC_DISPLAY}}</p>
      </div>
    </div>


    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('TICKETING.SERVICE')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.service}}</p>
      </div>
    </div>

    <app-ui-folder-item [model]="info"></app-ui-folder-item>
    <!-- <app-ui-folder-item [model]="time"></app-ui-folder-item> -->
    <!-- <app-ui-folder-item [model]="fault"></app-ui-folder-item> -->
  </div>
</div>
