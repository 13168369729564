<div class="statusBarContainer" id="mcStatusBar" *ngIf="skin === 'mc'">
  <div class="menuBar">
    <div ngbDropdown placement="bottom-left">
      <div class="box leftBox">
        <span class='language-info'>
          <img class="corp_global icon" src="./assets/images/corp_global.png" ngbDropdownToggle />
        </span>
        <span class='version-info'>{{version}}</span>
      </div>

      <div class="box middleBox">
      </div>

      <div class="box rightBox " style="width:5%;min-width:40px;">
      </div>

      <div ngbDropdownMenu class="menu ">
        <div *ngFor="let menu of menuSettings ">
          <div *ngIf="menu.divider " class="dropdown-divider ">
          </div>

          <div *ngIf="!menu.divider ">
            <div *ngIf="menu.child;else noChild ">
              <button class="dropdown-item " [disabled]="menu.disabled " [hidden]="!menu.show " (click)="fn(menu.fn) ">
                <small>{{menu.content}}</small>
              </button>

              <div *ngFor="let child of menu.child ">
                <button class="dropdown-item selectedBackground " [disabled]="child.disabled " [hidden]="!child.checked ">
                  <small class="selectedLanguage ">{{child.title}}</small>
                  <i class="fa fa-check " aria-hidden="false "></i>
                </button>
                <button class="dropdown-item " [disabled]="child.disabled " (click)="changeLanguage(child.lang) " [hidden]="child.checked ">
                  <small>{{child.title}}</small>
                </button>

              </div>
            </div>

            <ng-template #noChild>

              <div *ngIf="menu .type==='others' ">
                <button class="dropdown-item " [disabled]="menu.disabled " [hidden]="!menu.show " (click)="fn(menu.fn) ">
                  <small>{{menu.content}}</small>
                </button>
              </div>

              <div *ngIf="menu.type==='showStatus' ">
                <button class="dropdown-item " [disabled]="menu.disabled " [hidden]="!menu.show ">
                  <small> BOT </small>
                  <i style="color:lime " class="fa fa-circle " aria-hidden="true " [hidden]="!status.WCS "></i>
                  <i style="color:red " class="fa fa-circle " aria-hidden="true " [hidden]="status.WCS "></i>
                  <small> CMC </small>
                  <i style="color:lime " class="fa fa-circle " aria-hidden="true " [hidden]="!status.CMC "></i>
                  <i style="color:red " class="fa fa-circle " aria-hidden="true " [hidden]="status.CMC "></i>
                </button>
              </div>
            </ng-template>

            <div *ngIf="menu.type==='sound' ">
              <button class="dropdown-item " [ngClass]="menu.class " [disabled]="menu.disabled " [hidden]="!menu.show ">
                <small>Sound
                  <mat-slide-toggle class="example-margin" [checked]="soundOn " (change)="toggleSound() ">
                  </mat-slide-toggle>
                </small>
              </button>
            </div>

            <div *ngIf="menu.type==='controlFontSize' ">
              <button class="dropdown-item " [ngClass]="menu.class " [disabled]="menu.disabled " [hidden]="!menu.show ">
                <span class="smallerFont ">{{menu.content[0]}}</span>
                <mat-slider [min]="min " [max]="max " [step]="step " [thumbLabel]="thumbLabel " [tickInterval]="tickInterval " [ngModel]="defaultFontSize " (input)="controlFontSize($event) " [vertical]="vertical "></mat-slider>
                <span class="largerFont ">{{menu.content[1]}}</span>
              </button>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<div class="statusBarContainer" id="corpStatusBar" *ngIf="skin === 'corp'">
  <div class="menuBar">
    <div ngbDropdown placement="bottom-left">
      <div class="box leftBox">
        <span class='language-info'>
          <img class="corp_global icon" src="./assets/images/corp_global.png" ngbDropdownToggle />
        </span>
      </div>

      <div ngbDropdownMenu class="menu ">
        <div *ngFor="let menu of menuSettings ">
          <div *ngIf="menu.divider " class="dropdown-divider ">
          </div>

          <div *ngIf="!menu.divider ">
            <div *ngIf="menu.child;else noChild ">
              <!-- <button class="dropdown-item " [disabled]="menu.disabled " [hidden]="!menu.show " (click)="fn(menu.fn) ">
                <small>{{menu.content}}</small>
              </button> -->

              <div *ngFor="let child of menu.child ">
                <button class="dropdown-item selectedBackground " [disabled]="child.disabled " [hidden]="!child.checked ">
                  <span class="selectedLanguage ">{{child.title}}</span>
                  <i class="fa fa-check " aria-hidden="false "></i>
                </button>
                <button class="dropdown-item " [disabled]="child.disabled " (click)="changeLanguage(child.lang) " [hidden]="child.checked ">
                  <span>{{child.title}}</span>
                </button>

              </div>
            </div>

            <ng-template #noChild>

              <div *ngIf="menu .type==='others' ">
                <button class="dropdown-item " [disabled]="menu.disabled " [hidden]="!menu.show " (click)="fn(menu.fn) ">
                  <small>{{menu.content}}</small>
                </button>
              </div>

              <div *ngIf="menu.type==='showStatus' ">
                <button class="dropdown-item " [disabled]="menu.disabled " [hidden]="!menu.show ">
                  <small> BOT </small>
                  <i style="color:lime " class="fa fa-circle " aria-hidden="true " [hidden]="!status.WCS "></i>
                  <i style="color:red " class="fa fa-circle " aria-hidden="true " [hidden]="status.WCS "></i>
                  <small> CMC </small>
                  <i style="color:lime " class="fa fa-circle " aria-hidden="true " [hidden]="!status.CMC "></i>
                  <i style="color:red " class="fa fa-circle " aria-hidden="true " [hidden]="status.CMC "></i>
                </button>
              </div>
            </ng-template>

            <div *ngIf="menu.type==='sound' ">
              <button class="dropdown-item " [ngClass]="menu.class " [disabled]="menu.disabled " [hidden]="!menu.show ">
                <small>Sound
                  <mat-slide-toggle class="example-margin" [checked]="soundOn " (change)="toggleSound() ">
                  </mat-slide-toggle>
                </small>
              </button>
            </div>

            <div *ngIf="menu.type==='controlFontSize' ">
              <button class="dropdown-item " [ngClass]="menu.class " [disabled]="menu.disabled " [hidden]="!menu.show ">
                <span class="smallerFont ">{{menu.content[0]}}</span>
                <mat-slider [min]="min " [max]="max " [step]="step " [thumbLabel]="thumbLabel " [tickInterval]="tickInterval " [ngModel]="defaultFontSize " (input)="controlFontSize($event) " [vertical]="vertical "></mat-slider>
                <span class="largerFont ">{{menu.content[1]}}</span>
              </button>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</div>
