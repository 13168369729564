import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  LoggerService
} from '../../services';

@Component({
  selector: 'app-ui-button-list',
  templateUrl: './ui-button-list.component.html',
  styleUrls: ['./ui-button-list.component.css']
})
export class UiButtonListComponent implements OnInit {

  @Input() model: any;
  @Input() parent: any;
  @Output() emitEvent = new EventEmitter<any>();

  constructor(
    private logger: LoggerService
  ) { }

  ngOnInit() {
    // this.logger.info(this.model);
  }

  sendText($event: any) {
    if ($event.text)
      this.emitEvent.next({
        text: $event.text,
        isButton: $event.isButton,
        customFields: $event.customFields,
        condition: $event.condition
      })
    else
      this.emitEvent.next($event)
  }

}
