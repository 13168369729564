import { Injectable } from '@angular/core';
declare var window: any
@Injectable({
  providedIn: 'root'
})
export class RedirectService {

  isNative: boolean = false;
  isAndroid: boolean = false;
  isIOS: boolean = false;

  constructor(
  ) {

    let w: any = window;
    var userAgent = navigator.userAgent || navigator.vendor || w.opera;

    if ((navigator.platform.indexOf('iPhone') != -1)
      || (navigator.platform.indexOf('iPod') != -1)
      // || (navigator.platform.indexOf('Mac') != -1)
      || (navigator.platform.indexOf('iPad') != -1)) {
      this.isIOS = true;
      this.isNative = true;
    }

    if (/android/i.test(userAgent)) {
      this.isAndroid = true;
      this.isNative = true;
    }
  }

  appendExternalHashtag(url: string) {
    let str = '#external';
    try {
      if (url && url.indexOf("http") < 0) {
        url = 'https://' + url
      }
      if (url.indexOf('#external') > -1) {
        return url
      }
      if (this.isNative) {
        return url + str
      }
    } catch (e) {
      return url
    }
    return url;
  }

  navigateExternal(url: string) {
    url = this.appendExternalHashtag(url);
    window.open(url, '_blank')
  }
}
