import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ui-file',
  templateUrl: './ui-file.component.html',
  styleUrls: ['./ui-file.component.css']
})
export class UiFileComponent implements OnInit {

  @Input() model: any;
  @Input() parent: any;

  constructor() { }


  ngOnInit() {
  }

  downloadFile() {
    if (this.model.content.indexOf('base64') < 0) {
      window.open(`${this.model.content}&download=true`, '_blank')
    } else {
      window.open(`${this.model.content}`, '_blank')
    }
  }
}
