import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class McservicesService {

  constructor(
    private http: HttpClient
  ) {

  }


  async getMPLSService(page) {
    let url = `${environment.serverUrl}/api/mcservices/mpls`
    if (page)
      url += `?page=${page}`
    return this.http.get(url).toPromise()
  }

  async getInternetService(page) {
    let url = `${environment.serverUrl}/api/mcservices/internet`
    if (page)
      url += `?page=${page}`
    return this.http.get(url).toPromise()
  }

  async getDataHouseService(page) {
    let url = `${environment.serverUrl}/api/mcservices/datahouse`
    if (page)
      url += `?page=${page}`
    return this.http.get(url).toPromise()
  }

  async getSecurityService(page) {
    let url = `${environment.serverUrl}/api/mcservices/security`
    if (page)
      url += `?page=${page}`
    return this.http.get(url).toPromise()
  }

  async generateServiceListComponent(params) {
    let data = params.data
    let metadata = Object.assign(params)
    delete metadata.data
    let output = []
    let childOutput = []

    let serviceListComponent = {
      is_core_component: true,
      content: null,
      subcontent: null,
      child: [],
      type: 'serviceList',
      customFields: metadata
    }

    for (let item of data) {
      let defaultStructure = {
        is_core_component: true,
        content: null,
        subcnotent: null,
        type: 'service',
        customFields: item
      }
      childOutput.push(defaultStructure)
    }
    serviceListComponent.child = childOutput
    output.push(serviceListComponent)
    return output
  }

  async getSmartCloudServiceActions() {
    const url = `${environment.serverUrl}/api/mcservices/smartcloud/getServiceType`
    return this.http.get(url).toPromise()
  }

  async getTrustChangeType() {
    const url = `${environment.serverUrl}/api/mcservices/trust/getChangeType`
    return this.http.get(url).toPromise()
  }

  async getTrustServiceType() {
    const url = `${environment.serverUrl}/api/mcservices/trust/getServiceType`
    return this.http.get(url).toPromise()
  }

  async getLocationSiteID() {
    const url = `${environment.serverUrl}/api/mcservices/internet/getSiteList`
    return this.http.get(url).toPromise()
  }

  async createSmartCloudChangeRequest(params) {
    const url = `${environment.serverUrl}/api/mcservices/changeRequest/createSmartCloud`
    return this.http.post(url, params).toPromise()
  }

  async createTrustChangeRequest(params) {
    const url = `${environment.serverUrl}/api/mcservices/changeRequest/createTrustCSI`
    return this.http.post(url, params).toPromise()
  }

  async createInternetChangeRequest(params) {
    const url = `${environment.serverUrl}/api/mcservices/changeRequest/createTrueCONNECT`
    return this.http.post(url, params).toPromise()
  }

}
