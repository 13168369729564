import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import {
  ModalService,
  SessionService,
  TranslationService,
} from '../../services'

@Component({
  selector: 'app-empty-input-modal',
  templateUrl: './empty-input-modal.component.html',
  styleUrls: ['./empty-input-modal.component.css']
})

export class EmptyInputModalComponent implements OnInit {
  private subscriptions: any = [];
  @ViewChild('modal') contentModal: ElementRef;
  private currentModal: any;
  private emptyTextString = '';
  private okTextString = '';

  constructor(
    private modalService: ModalService,
    private ngbModal: NgbModal,
    private translationService: TranslationService,
    private sessionService: SessionService,
  ) {
    this.subscriptions.push(this.modalService.$modals.subscribe((modal: any) => {
      if (modal.event === "open") {
        if (modal.name == 'emptyInput') {
          this.initTranslation()
          this.currentModal = this.ngbModal.open(this.contentModal, { size: 'sm', centered: true, keyboard: false, backdrop: "static" });
          this.currentModal.result.then((result) => { sessionService.resetTimeout(); });
          sessionService.pauseTimeout();
        }
      }
    }));
  }

  ngOnInit() {

  }

  initTranslation() {
    this.emptyTextString = this.translateText("SETTINGS.BLANK_MESSAGE_ERROR");
    this.okTextString = this.translateText("SETTINGS.OK");
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => {
      x.unsubscribe();
    })
  }

  translateText(text: string) {
    return this.translationService.translateText(text);
  }

}
