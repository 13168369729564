import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator, ValidatorFn } from '@angular/forms';

export function emailValidator(isRequired = true): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (control.value) {
      var email = control.value.trim();
      email = email.toLowerCase();
      let valid = true;
      let emails = email.split(';')
      let re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      emails.map(x => {
        if (x) {
          x = x.trim()
          if (x.length > 0) {
            valid = valid && re.test(x)
            if (valid) {
              if (x.match(re)[0].length !== x.length) {
                valid = false
              }
            }
          }
        }
      })

      return valid ? null : { 'msgKey': 'FORMAT' };
    } else {
      if (isRequired)
        return { 'invalid': true };
      else
        return null;
    }
  };
}

@Directive({
  selector: '[appEmailValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }]
})

export class EmailValidatorDirective implements Validator {

  validate(control: AbstractControl): { [key: string]: any } {
    return emailValidator()(control);
  }
}
