<ng-template #timeoutModal let-c="close" size="sm" let-d="dismiss">
    <div class="modal-body" style="padding=15px;">
      <!-- <img src="assets/images/icon.png" class="rating-logo" /> -->
      <p class="rating-topic"> {{translate('TIMEOUT_MODAL.NOTE')}} </p>
      <p class="rating-content" style="padding: '0px 20px 0px 20px'"> {{translate('TIMEOUT_MODAL.TIMEOUTDESCRIPTION')}}</p>

      <div class="btn-rating-container">
        <button id="disconnectBtn" class="btn btn-sm btn-orange btn-rating" (click)="disconnect()">{{translate('TIMEOUT_MODAL.STOPSESSION')}}</button>
        <button id="continueBtn" class="btn btn-sm btn-outline-success btn-rating" (click)="continue()">{{translate('TIMEOUT_MODAL.CONTINUESESSION')}}</button>
      </div>
    </div>
</ng-template>
