import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslationService } from '../../services/translation.service'
import { TicketService } from '../../services/ticket.service'
import { SessionService } from '../../services/session.service'

@Component({
  selector: 'app-ui-ticket-list',
  templateUrl: './ui-ticket-list.component.html',
  styleUrls: ['./ui-ticket-list.component.css']
})
export class UiTicketListComponent implements OnInit {

  @Input() model: any;
  @Input() parent: any;
  filteredModel: any = [];
  currentLength: number = 0;
  maxLength: number = 0;
  limit: number = 5;
  showMore: boolean = false;
  constructor(
    private translationService: TranslationService,
    private ticketService: TicketService,
    private sessionService: SessionService,
  ) { }


  ngOnInit() {
    if (this.model) {
      this.limit = this.sessionService.getListItemPerPage()
      this.maxLength = this.model.child.length
      this.runPagination()
    }
  }

  viewMore() {
    this.runPagination()
  }

  async runPagination() {
    for (let i = this.currentLength; i < this.currentLength + this.limit; ++i) {
      if (this.model.child[i]) {
        this.filteredModel.push(this.model.child[i])
      }
    }
    this.currentLength = Math.min(this.model.child.length, this.currentLength + this.limit)
    if (this.model.child.length === this.currentLength && this.model.customFields.next) {
      let moreContent
      let moreModel
      moreContent = await this.ticketService.getMoreTickets(this.model.customFields.page)
      moreModel = this.ticketService.generateTicketListComponent(moreContent)
      moreModel = moreModel[0]

      this.model.customFields.next = moreContent.next
      this.model.customFields.page = (+this.model.customFields.page) + 1
      this.model.child = this.model.child.concat(moreModel.child)
      this.maxLength = this.model.child.length
    } else {
      if (this.currentLength < this.model.child.length) {
        this.showMore = true
      } else {
        this.showMore = false
      }
    }
  }


  translate(key: string) {
    return this.translationService.translateText(key)
  }

}
