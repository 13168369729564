import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import {
  ModalService,
  LoggerService,
  TranslationService,
  ChatboxService,
  SessionService
} from '../../services';

import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-customer-statusbar',
  templateUrl: './customer-statusbar.component.html',
  styleUrls: ['./customer-statusbar.component.css']
})

export class CustomerStatusbarComponent implements OnInit {
  OPTIONS: any = {}
  LANGUAGES = [
    { title: '繁體', lang: 'ZH', checked: this.getSelectedLang('ZH') },
    { title: '简体', lang: 'CN', checked: this.getSelectedLang('CN') },
    { title: 'English', lang: 'EN', checked: this.getSelectedLang('EN') },
    { title: '日本語', lang: 'JP', checked: this.getSelectedLang('JP') },
    { title: 'Eestlane', lang: 'ET', checked: this.getSelectedLang('ET') },
    { title: 'русский', lang: 'RU', checked: this.getSelectedLang('RU') }
  ];
  private defaultFontSize: number = 1;
  private translationSubscription: Subscription;
  menuSettings: Array<any> = [];
  status: any = {
    CMC: false,
    WCS: false
  };
  showStatus: boolean = false;
  userID: string = this.sessionService.getUserSession().userID;
  userName: string = this.sessionService.getUserSession().userName;
  channel: string = "";

  autoTicks: boolean = false;
  max = 1.4;
  min = 0.8;
  showTicks: boolean = true;
  step = 0.2;
  thumbLabel: boolean = false;
  vertical: boolean = false;
  soundOn: boolean = false;
  version: string = 'v' + environment.version;
  public skin: string = '';

  constructor(
    private translationService: TranslationService,
    private modalService: ModalService,
    private elementRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private cookieService: CookieService,
    private sessionService: SessionService,
    private chatboxService: ChatboxService,
    private logger: LoggerService,
  ) {
  }

  ngOnInit() {
    this.channel = this.sessionService.getSessionChannel();
    if (this.channel.indexOf('corp-cec') > -1) {
      this.LANGUAGES = [
        { title: '简体', lang: 'CN', checked: this.getSelectedLang('CN') },
        { title: 'English', lang: 'EN', checked: this.getSelectedLang('EN') }
      ]
    } else if (this.channel.indexOf('corp-cpc') > -1) {
      this.LANGUAGES = [
        { title: '繁體', lang: 'ZH', checked: this.getSelectedLang('ZH') },
        { title: '简体', lang: 'CN', checked: this.getSelectedLang('CN') },
        { title: 'English', lang: 'EN', checked: this.getSelectedLang('EN') }
      ]
    } else if (this.channel.indexOf('cec') > -1) {
      this.LANGUAGES = [
        { title: '简体', lang: 'CN', checked: this.getSelectedLang('CN') },
        { title: '繁體', lang: 'ZH', checked: this.getSelectedLang('ZH') },
        { title: 'English', lang: 'EN', checked: this.getSelectedLang('EN') },
        { title: '日本語', lang: 'JP', checked: this.getSelectedLang('JP') },
        { title: 'Eestlane', lang: 'ET', checked: this.getSelectedLang('ET') },
        { title: 'русский', lang: 'RU', checked: this.getSelectedLang('RU') }
      ]
    }

    this.skin = this.sessionService.getSkin()
    this.reinitMenu();
    this.initFontSize();

    this.chatboxService.getSystemStatus().subscribe((res) => {
      this.status = res;
      this.showStatus = true;
      this.reinitMenu();
    })

    this.translationSubscription = this.translationService.$instance.subscribe((obj: any) => {
      this.reinitMenu();
    })
  }

  initFontSize() {
    this.defaultFontSize = parseFloat(this.cookieService.get('fontSize')) || 1;
    this.controlFontSize({ value: this.defaultFontSize })
  }

  getSelectedLang(lang: string) {
    var selectedLang = this.cookieService.get('lang') || 'ZH';
    return lang === selectedLang;
  }

  reinitMenu() {
    this.OPTIONS = {
      language: this.translationService.translateText('SETTINGS.LANGUAGE'),
      version: this.translationService.translateText('SETTINGS.VERSION'),
      // review: this.translationService.translateText('SETTINGS.REVIEW'),
      liveChat: 'Test LiveChat',
    }

    this.menuSettings = [
      // { show: true, content: 'AA', fn: "controlFontSize()", class: 'smallerFont', type: 'controlFontSize' },
      // { show: true, divider: true },
      { show: true, content: this.OPTIONS.language, child: this.LANGUAGES, disabled: true, type: 'others' },
      // { show: true, divider: true, type: 'others' },
      // { show: true, content: this.OPTIONS.review, fn: 'showReviewModal()', type: 'others' },
      // { show: true, type: 'sound' },
      // { show: true, disabled: true, content: `${this.OPTIONS.version} ${environment.version}`, type: 'others' },
      // { show: true, fn: "showStatus()", disabled: true, type: 'showStatus' },
      // { show: !environment.production, content: 'followup-outofofficehour', fn: 'showFollowupOutOfOfficeHourModal()', type: 'others' },
      // { show: !environment.production, content: 'followup-max-queue', fn: 'showFollowupMaxQueueModal()', type: 'others' },
      // { show: true, content: 'TimeoutModal', fn: 'showTimeoutModal()', type: 'others' },
      // { show: true, content: 'DCModal', fn: 'showDisconnectedModal()', type: 'others' },
      // { show: true, content: 'Add Ticket', fn: 'showCreateTicket()', type: 'others' },
      // { show: !environment.production, content: this.OPTIONS.liveChat, fn: 'requestLiveChat()', type: 'others' },
    ];
  }

  changeLanguage(lang: string) {
    this.cookieService.put('lang', lang);
    this.sessionService.refreshSession(lang);
  }


  showReviewModal() {
    this.modalService.openModal('review');
  }

  showTimeoutModal() {
    this.modalService.openModal('timeout');
  }

  showDisconnectedModal() {
    this.modalService.openModal('disconnected');
  }

  showFollowupOutOfOfficeHourModal() {
    this.modalService.openModal('followup_office_hour');
  }

  showFollowupMaxQueueModal() {
    this.modalService.openModal('followup_max_queue');
  }

  showCreateTicket() {
    this.modalService.openModal('createTicket');
  }

  requestLiveChat() {
    this.chatboxService.requestLiveChat();
  }


  fn(fn: string) {
    eval("this." + fn);
  }

  controlFontSize(event: any) {
    this.cookieService.put('fontSize', event.value)

    let panel: any;
    let homeMenu: any;
    let settingsMenu: any;
    let navigation: any;

    panel = document.getElementsByClassName('main')[0];
    homeMenu = document.querySelector('.intentMenu');
    settingsMenu = document.querySelector('.menu');
    navigation = document.querySelector('.navigation');

    if (!panel) {
      setTimeout(() => {
        this.initFontSize()
      }, 500)
      return;
    }

    if (event.value < 1) {
      if (homeMenu)
        homeMenu.style["fontSize"] = `1rem`;
      if (settingsMenu)
        settingsMenu.style["fontSize"] = `1rem`;
      if (navigation)
        navigation.style["fontSize"] = `${event.value}rem`;

    } else {
      if (homeMenu)
        homeMenu.style["fontSize"] = `${event.value}rem`;
      if (settingsMenu)
        settingsMenu.style["fontSize"] = `${event.value}rem`;
      if (navigation)
        navigation.style["fontSize"] = `1rem`;
    }
    panel.style["fontSize"] = `${event.value}rem`;
  }

  closeChat() {
    let url = "";
    if (this.getMobileOperatingSystem()) {
      // this.sessionService.closeSession();
      window.location.href = url;
    } else {
      this.logger.info("no native API found");
    }
  }

  getMobileOperatingSystem() {
    let w: any = window;
    var userAgent = navigator.userAgent || navigator.vendor || w.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !w.MSStream) {
      return "iOS";
    }

    return undefined;
  }

  toggleSound() {
    this.soundOn = !this.soundOn;
    this.chatboxService.$soundOn.next(this.soundOn);
  }

}
