<div class="container">
  <div *ngIf="isLoggedIn()">
    <app-chatboxpanel></app-chatboxpanel>
    <app-inputbox></app-inputbox>
    <app-review-modal></app-review-modal>
    <app-timeout-modal></app-timeout-modal>
    <app-disconnected-modal></app-disconnected-modal>
    <app-create-ticket-modal></app-create-ticket-modal>
    <app-change-request-form-modal></app-change-request-form-modal>
    <app-empty-input-modal></app-empty-input-modal>
    <app-customer-statusbar></app-customer-statusbar>
    <app-followup-modal></app-followup-modal>
    <app-enlarged-image-modal></app-enlarged-image-modal>
    <app-video-call-panel></app-video-call-panel>
  </div>
</div>
