import { Component, ViewChild, ElementRef, OnInit, Input } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import {
  emailValidator,
} from '../../directives';
// import * as Entities from 'html-entities';
// const entities = new Entities.XmlEntities();

import {
  TranslationService,
  ModalService,
  SessionService,
  TicketService,
  ChatboxService,
} from '../../services'

@Component({
  selector: 'app-create-ticket-modal-content',
  templateUrl: './create-ticket-modal-content.component.html',
  styleUrls: ['./create-ticket-modal-content.component.css']
})
export class CreateTicketModalContentComponent implements OnInit {

  public ticketFormGroup: any;
  public userID: string = '';
  public company: string = '';
  public caseType = [
    'Fault'
  ];
  loading: boolean = false;
  loader: string = '';
  public selectedCaseType: string = 'fault';

  // public serviceTypes = {
  //   'Fault': [
  //     'Direct Access',
  //     'FM',
  //     'FM - Port',
  //     'FM - Rack',
  //     'MetroConnect Server Port',
  //     'smartCLOUD Compute',
  //     'smartCLOUD DaaS',
  //     'smartCLOUD EPS',
  //     'smartCLOUD M@il',
  //     'smartCLOUD TAB',
  //     'smartCLOUD VC',
  //     'TMS',
  //     'TrueCONNECT Express',
  //     'TrueCONNECT Premium',
  //     'TrueCONNECT Remote',
  //     'TrustCSI MAS',
  //     'TrustCSI MSS',
  //     'TrustCSI UTM',
  //     'non-HK Access - Broadband / Metro Resell',
  //     'non-HK Access - DDS',
  //     'non-HK Access - Leased Line',
  //     'non-HK Hostiing - FM',
  //     'non-HK Hosting - Leaded Line',
  //   ]
  // };
  public serviceTypes: any = {
    'fault': []
  }
  private defaultData: any = {
    caseType: 'General Inquiry',
    serviceType: 'Others',
    contactName: 'chatbot',
    contactEmail: 'chatbot@19934.com',
    contactNumberArea: '852',
    contactNumber: '85212341234',
    faultSymtom: 'fault symptom',
    testResult: 'test result data',
  }

  constructor(
    private translationService: TranslationService,
    private modalService: ModalService,
    private sessionService: SessionService,
    private ticketService: TicketService,
    private chatboxService: ChatboxService,
  ) {

    // if(this.isProd){
    this.defaultData = {
      caseType: '',
      serviceType: '',
      contactName: '',
      contactEmail: '',
      contactNumberArea: '',
      contactNumber: '',
      faultSymtom: '',
      testResult: '',
    }
    // }
  }

  async ngOnInit() {
    let user = this.sessionService.getUserSession()
    this.userID = user.userID;
    this.company = user.company;
    this.initForm();
    this.serviceTypes['fault'] = await this.ticketService.getAllServices()
  }

  initForm() {
    this.loader = '';
    this.ticketFormGroup = new FormGroup({
      'caseType': new FormControl('Fault', [Validators.required]),
      'serviceType': new FormControl(this.defaultData.serviceType, [Validators.required]),
      'contactName': new FormControl(this.defaultData.contactName, [Validators.required]),
      'contactEmail': new FormControl(this.defaultData.contactEmail, [Validators.required, emailValidator()]),
      'contactNumber': new FormControl(this.defaultData.contactNumber, [Validators.minLength(8)]),
      'faultSymtom': new FormControl(this.defaultData.faultSymtom, [Validators.required]),
      'testResult': new FormControl(this.defaultData.testResult)
    })
  }

  markFormGroupTouched(formGroup: FormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  translate(key: string) {
    return this.translationService.translateText(key)
  }

  exit() {
    this.modalService.closeModal('createTicket')
  }

  onCaseChange(value: string) {
    this.selectedCaseType = value
  }

  async createTicket() {
    this.markFormGroupTouched(this.ticketFormGroup)
    if ('VALID' === this.ticketFormGroup.status) {
      if (!this.loading) {
        this.loading = true
        this.loader = 'fa fa-circle-o-notch fa-spin'
        let ticketData = Object.assign({ gid: this.userID }, this.ticketFormGroup.value)
        let resp: any = await this.ticketService.createTicket(ticketData)
        if (resp.ok) {
          let output = Object.assign({ fromMe: false }, this.generateTextComponent(this.translate('TICKETING.CREATING')))
          this.chatboxService.add(output)
          this.modalService.closeModal('createTicket')
        } else {
          this.loading = false;
          this.loader = ''
        }
      }
    }
  }

  generateTextComponent(text: string) {
    return {
      type: 'text',
      content: text,
      subcontent: null,
      is_core_component: false,
    }
  }

}
