import { Component, HostListener } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { TranslationService } from './services';
import * as jwtDecode from 'jwt-decode';

import {
  SessionService,
  LoggerService,
  ChatboxService,
  LivechatService,
} from './services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  private loggedIn: boolean = false;
  private routeSubscription: any;
  private channel = "";
  public output;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslationService,
    private sessionService: SessionService,
    private logger: LoggerService,
    private chatboxService: ChatboxService,
    private livechatService: LivechatService,
  ) {

    if (!this.routeSubscription) {
      this.routeSubscription = this.route.queryParamMap;
      this.routeSubscription.subscribe(async (q) => {
        if (q.has('token')) {
          var decoded = jwtDecode(q.get('token'));
          this.sessionService.initSession(q.get('token'))
          this.logger.info(decoded);
          if (decoded.token) {
            if (new Date() <= new Date(decoded.exp * 1000)) {
              let user = decoded.token;
              this.sessionService.setConversationID(user.conversationID);
              this.sessionService.setUserID(user.userID);
              this.sessionService.setSessionChannel(user.entryPointLabel);
              this.sessionService.setRefreshToken(user.refreshToken);
              this.sessionService.setEmail(user.email);
              this.sessionService.setTimeZone(user.timeZone);
              this.channel = this.sessionService.getSessionChannel();

              document.body.style.backgroundColor = "#FAF9F3";
              this.livechatService.initQuestions(user.language);
              // this.livechatService.addOperators({name:'admin',username:'admin'});
              // this.livechatService.addOperators({name:'nicholas',username:'nicholas'});
              // this.livechatService.addOperators({name:'nicholas3',username:'nicholas3'});
              this.sessionService.setInitialLanguage(user.language);
              if (user.userName) {
                this.sessionService.setUserName(decodeURIComponent(user.userName));
              }
              this.translationService.initLanguage(user.language);
              this.loggedIn = true;

            } else {
              //expired, redirect to failed login
              // this.sessionService.redirectToError();
            }
          } else {
            // no token found, redirect
            // this.logger.error("authentication error");
            // this.sessionService.redirectToError();
          }
        } else {
          // this.logger.error("authentication error");
          // this.sessionService.redirectToError();
        }
      });
    }
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
  ngOnInit() {
    document.body.addEventListener('touchmove', function(e: any) {
      e = e || window.event;
      var target: any = e.target || e.srcElement;
      if (target.className.match(/select/)) {
        e.returnValue = false;
        e.cancelBubble = true;
        if (e.preventDefault) {
          e.preventDefault();
          e.stopPropagation();
        }
        return false;//or return e, doesn't matter
      }
    }, false);
  }

  isLoggedIn() {
    return this.loggedIn;
  }

}
