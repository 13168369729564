<div class="contents" *ngFor="let r of chatRecord;let i = index">
  <div *ngIf="r.output" class="row">
    <div class='animation-from-them' *ngIf="!r.fromMe && !r.duplicated && !r.hidden else leftSpacing">
      <img src="assets/images/corp_chatbot.png" class="bot-icon corp animation-typing" *ngIf="(!r.liveChat || r.event === 'liveChatResult')" />
      <img src="{{r.avatar}}" class="bot-icon" *ngIf="r.liveChat && r.avatar" />
      <ngx-avatar class="bot-icon" name="{{r.sender}}" size="54" *ngIf="r.liveChat && !r.avatar"></ngx-avatar>
    </div>

    <ng-template #leftSpacing>
      <div class='left-spacing-without-icon' *ngIf="!r.fromMe"></div>
    </ng-template>

    <div class="col hyper-link-container bot-col-width" *ngIf="!r.fromMe && !r.hidden">
      <div class='animation-from-them' *ngIf="r.isMultiple && r.customComponent">
        <div class="sender" *ngIf="r.sender">{{r.sender}}</div>
        <ng-container *ngFor="let child of r.output">
          <app-ui-models [model]="child" [parent]="r" (emitEvent)="parseEmitEvent($event,r)"></app-ui-models>
        </ng-container>
      </div>

      <div [ngClass]="addClassFromMe(r)" *ngIf="r.isMultiple && !r.customComponent">
        <div class="sender" *ngIf="r.sender">{{r.sender}}</div>
        <ng-container *ngFor="let child of r.output">
          <app-ui-models [model]="child" [parent]="r" (emitEvent)="parseEmitEvent($event,r)"></app-ui-models>
          <div *ngIf="r.type == 'disconnected'">
            {{translateText("LIVECHAT.DISCONNECTED1")}} <span class='hyper-link-send-message underline' (click)="refreshSession()">{{translateText('LIVECHAT.DISCONNECTED2')}}</span>{{translateText('LIVECHAT.DISCONNECTED3')}}
          </div>
          <div class="col-12 no-col-margin">
            <div [ngClass]="skin" class="timestamp other pull-right">{{r.timestamp | date: 'shortTime'}}</div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="col hyper-link-container" *ngIf="r.fromMe">

      <div [ngClass]="addClassFromMe(r)">
        <ng-container *ngFor="let child of r.output">
          <app-ui-models [model]="child" [parent]="r" (emitEvent)="parseEmitEvent($event,r)"></app-ui-models>
          <div class="col" *ngIf="r.type == 'disconnected'">
            {{translateText("LIVECHAT.DISCONNECTED1")}} <span class='hyper-link-send-message' (click)="refreshSession()">{{translateText('LIVECHAT.DISCONNECTED2')}}</span>{{translateText('LIVECHAT.DISCONNECTED3')}}
          </div>
          <div class="col-12 no-col-margin">
            <div [ngClass]="skin" class="timestamp me pull-right">{{r.timestamp | date: 'shortTime'}}</div>
            <div [ngClass]="skin" class="message-status pull-right" *ngIf="r.status">
              <i class="fa fa-times" *ngIf="r.status === 'failed'"></i>
              <i class="fa fa-clock-o" *ngIf="r.status === 'pending'"></i>
              <i class="fa fa-check" *ngIf="r.status === 'success'"></i>
            </div>
          </div>
        </ng-container>
      </div>

    </div>
  </div>
</div>

<div class="row animation-typing" *ngIf="isLoading && !isLiveChat">
  <img src="assets/images/corp_chatbot.png" class="bot-icon corp animation-typing" />
  <div class="col-10 content">
    <div [ngClass]="addClassFromMe({fromMe:false})" *ngIf="skin === 'mc'">
      <span style="display:inline-block">{{translateText("SETTINGS.TYPING")}} <img src="assets/images/Typing.gif" /></span>
    </div>
    <div [ngClass]="addClassFromMe({fromMe:false})" *ngIf="skin === 'corp'">
      <span style="display:inline-block"><img src="assets/images/corp_typing.png" class="typing-icon corp"/></span>
    </div>
  </div>
</div>

<div *ngIf="chatRecord.length < 5">
  <div class="row justify-content-center" style="height:5rem;">
    <div class="col"> </div>
  </div>
</div>

<div *ngIf="chatRecord.length > 5">
  <div class="row justify-content-center" style="height:1rem;">
    <div class="col"> </div>
  </div>
</div>
