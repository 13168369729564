<div class="footer panel" *ngIf="skin === 'mc'">
  <div class="customer-survey col">
    <div style="float:left;margin-top:8px;" *ngIf="showLiveChatMessage">
      <div class="navigation">{{translateText("LIVECHAT.QUEUE1")}}
        <a (click)="cancelLiveChat()" class="hyperlinks">{{translateText("LIVECHAT.CANCEL")}}</a> {{translateText("LIVECHAT.QUEUE2")}}
      </div>
    </div>
  </div>

  <div class="file-preview-container">
    <div class="file-preview-item" *ngFor="let file of tempFiles; let i = index" matTooltip="{{file.filename}}" [ngClass]="file.class">
      <div class="file-content" *ngIf="file.status === 'success'">
        <img [src]="file.content | safeUrlResource" class="preview-item" *ngIf="file.type === 'image'" />
        <img src="./assets/images/file.svg" class="other-file-content" *ngIf="file.type !== 'image' && file.type !== 'invalid' ">
        <img src="./assets/images/stopu.svg" class="other-file-content" *ngIf="file.type === 'invalid'">
        <span class="file-caption" *ngIf="file.type !== 'invalid'">{{file.filename}}</span>
        <span class="file-caption" *ngIf="file.type === 'invalid'">{{translateText("SETTINGS.SCAN_INSECURE")}}</span>
      </div>
      <img src="./assets/images/img-loading-spinner.svg" class="preview-item-loading" *ngIf="file.status === 'pending'" />
      <img src="./assets/images/stopu.svg" class="preview-item-loading" *ngIf="file.status === 'failed'" />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.82 71.82" class="remove-preview" (click)="removePreview(i)" *ngIf="file.type !== 'invalid'">
        <g>
          <g>
            <circle class="cls-1" cx="35.91" cy="35.91" r="34.91" />
            <path class="cls-2" d="M13.2,37.41H59.08a1.5,1.5,0,0,0,0-3H13.2a1.5,1.5,0,0,0,0,3Z" />
          </g>
        </g>
      </svg>
    </div>
  </div>

  <div class="inputBoxContainer row no-col-margin">
    <div class="col-12 no-col-margin">
      <span class="homeMenu">
        <img src="assets/images/home-01.png" class="icons icons-group-left" (click)="sendMessage('hello')" />
      </span>

      <input [ngClass]="inputBoxClass" [placeholder]="INPUTPLACEHOLDER" [disabled]="isDisabled" [(ngModel)]="userInput" (keypress)="validate($event)" maxlength="1000" />
      <span [hidden]="!micCompatibility || isProd">
        <span *ngIf="!isRecording">
          <svg id="microphone" class=" mic icons icons-group-right" width="25" height="25" viewBox="0 0 12 15" (click)="activateSpeechRecognition()">
            <path class="mic-base" d="M9,12.86 L9,14 L9.5,14 C10.15,14 10.69,14.42 10.9,15 L5.1,15 C5.31,14.42 5.85,14 6.5,14 L7,14 L7,12.86 C5.28,12.41 4,10.86 4,9 L4,7 L5,7 L5,9 C5,10.66 6.34,12 8,12 C9.66,12 11,10.66 11,9 L11,7 L12,7 L12,9 C12,10.86 10.72,12.41 9,12.86 L9,12.86 L9,12.86 Z" />
            <path class="mic-top" d="M8,11 C6.9,11 6,10.1 6,9 L6,3 C6,1.9 6.9,1 8,1 C9.1,1 10,1.9 10,3 L10,9 C10,10.1 9.1,11 8,11 L8,11 L8,11 Z" />
          </svg>
        </span>

        <span *ngIf="isRecording">
          <svg id="microphone" class="active mic icons icons-group-right" width="25" height="25" viewBox="0 0 12 15" (click)="stopSpeechRecognition()">
            <path class="mic-base" d="M9,12.86 L9,14 L9.5,14 C10.15,14 10.69,14.42 10.9,15 L5.1,15 C5.31,14.42 5.85,14 6.5,14 L7,14 L7,12.86 C5.28,12.41 4,10.86 4,9 L4,7 L5,7 L5,9 C5,10.66 6.34,12 8,12 C9.66,12 11,10.66 11,9 L11,7 L12,7 L12,9 C12,10.86 10.72,12.41 9,12.86 L9,12.86 L9,12.86 Z" />
            <path class="mic-top" d="M8,11 C6.9,11 6,10.1 6,9 L6,3 C6,1.9 6.9,1 8,1 C9.1,1 10,1.9 10,3 L10,9 C10,10.1 9.1,11 8,11 L8,11 L8,11 Z" />
          </svg>
        </span>
      </span>

      <input id="fileUpload" hidden type="file" accept=".jpg, .png, .svg, .gif, .pdf" ng2FileSelect [uploader]="uploader" multiple="" />
      <span class="attachments-upload" (click)="promptUpload()" *ngIf="isLiveChat && !isEnded">
        <i class="fa fa-paperclip" aria-hidden="true"></i>
      </span>
      <img src="assets/images/send-01.png" class=" icons icons-group-right" (click)="sendMessage('')" />
    </div>
  </div>

  <div class="surveyForm" *ngIf="isLiveChat">
    <a (click)="openSurveyModal()">{{translateText('SETTINGS.CUSTOMERSURVEYLINK')}}</a>
  </div>
</div>


<div class="footer panel" *ngIf="skin === 'corp'">
  <div class="customer-survey col">
    <div style="float:left;margin-top:8px;" *ngIf="showLiveChatMessage">
      <div class="navigation">{{translateText("LIVECHAT.QUEUE1")}}
        <a (click)="cancelLiveChat()" class="hyperlinks">{{translateText("LIVECHAT.CANCEL")}}</a> {{translateText("LIVECHAT.QUEUE2")}}
      </div>
    </div>
  </div>

  <div class="file-preview-container">
    <div class="file-preview-item" *ngFor="let file of tempFiles; let i = index" matTooltip="{{file.filename}}" [ngClass]="file.class">
      <div class="file-content" *ngIf="file.status === 'success'">
        <img [src]="file.content | safeUrlResource" class="preview-item" *ngIf="file.type === 'image'" />
        <img src="./assets/images/file.svg" class="other-file-content" *ngIf="file.type !== 'image' && file.type !== 'invalid' ">
        <img src="./assets/images/stopu.svg" class="other-file-content" *ngIf="file.type === 'invalid'">
        <span class="file-caption" *ngIf="file.type !== 'invalid'">{{file.filename}}</span>
        <span class="file-caption" *ngIf="file.type === 'invalid'">{{translateText("SETTINGS.SCAN_INSECURE")}}</span>
      </div>
      <img src="./assets/images/img-loading-spinner.svg" class="preview-item-loading" *ngIf="file.status === 'pending'" />
      <img src="./assets/images/stopu.svg" class="preview-item-loading" *ngIf="file.status === 'failed'" />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.82 71.82" class="remove-preview" (click)="removePreview(i)" *ngIf="file.type !== 'invalid'">
        <g>
          <g>
            <circle class="cls-1" cx="35.91" cy="35.91" r="34.91" />
            <path class="cls-2" d="M13.2,37.41H59.08a1.5,1.5,0,0,0,0-3H13.2a1.5,1.5,0,0,0,0,3Z" />
          </g>
        </g>
      </svg>
    </div>
  </div>

  <div class="inputBoxContainer row no-col-margin">
    <div class="col-12 no-col-margin">
      <span class="homeMenu">
        <img src="assets/images/corp_home.png" class="icons icons-group-left" (click)="sendMessage('hello')" />
      </span>

      <input [ngClass]="inputBoxClass" [placeholder]="INPUTPLACEHOLDER" [disabled]="isDisabled" [(ngModel)]="userInput" (keypress)="validate($event)" maxlength="1000" />
      <span [hidden]="!micCompatibility || isProd">
        <span *ngIf="!isRecording">
          <svg id="microphone" class=" mic icons icons-group-right" width="25" height="25" viewBox="0 0 12 15" (click)="activateSpeechRecognition()">
            <path class="mic-base" d="M9,12.86 L9,14 L9.5,14 C10.15,14 10.69,14.42 10.9,15 L5.1,15 C5.31,14.42 5.85,14 6.5,14 L7,14 L7,12.86 C5.28,12.41 4,10.86 4,9 L4,7 L5,7 L5,9 C5,10.66 6.34,12 8,12 C9.66,12 11,10.66 11,9 L11,7 L12,7 L12,9 C12,10.86 10.72,12.41 9,12.86 L9,12.86 L9,12.86 Z" />
            <path class="mic-top" d="M8,11 C6.9,11 6,10.1 6,9 L6,3 C6,1.9 6.9,1 8,1 C9.1,1 10,1.9 10,3 L10,9 C10,10.1 9.1,11 8,11 L8,11 L8,11 Z" />
          </svg>
        </span>

        <span *ngIf="isRecording">
          <svg id="microphone" class="active mic icons icons-group-right" width="25" height="25" viewBox="0 0 12 15" (click)="stopSpeechRecognition()">
            <path class="mic-base" d="M9,12.86 L9,14 L9.5,14 C10.15,14 10.69,14.42 10.9,15 L5.1,15 C5.31,14.42 5.85,14 6.5,14 L7,14 L7,12.86 C5.28,12.41 4,10.86 4,9 L4,7 L5,7 L5,9 C5,10.66 6.34,12 8,12 C9.66,12 11,10.66 11,9 L11,7 L12,7 L12,9 C12,10.86 10.72,12.41 9,12.86 L9,12.86 L9,12.86 Z" />
            <path class="mic-top" d="M8,11 C6.9,11 6,10.1 6,9 L6,3 C6,1.9 6.9,1 8,1 C9.1,1 10,1.9 10,3 L10,9 C10,10.1 9.1,11 8,11 L8,11 L8,11 Z" />
          </svg>
        </span>
      </span>

      <input id="fileUpload" hidden type="file" accept=".jpg, .png, .svg, .gif, .pdf" ng2FileSelect [uploader]="uploader" multiple="" />
      <span class="attachments-upload" (click)="promptUpload()" *ngIf="isLiveChat && !isEnded">
        <i class="fa fa-paperclip" aria-hidden="true"></i>
      </span>
      <img src="assets/images/corp_send.png" class=" corp_send_icon icons icons-group-right" (click)="sendMessage('')" />
    </div>
  </div>

  <div class="surveyForm" *ngIf="isLiveChat">
    <a (click)="openSurveyModal()">{{translateText('SETTINGS.CUSTOMERSURVEYLINK')}}</a>
  </div>
</div>
