<div class="card" *ngIf="parent.customFields.type === 'mpls'">
  <div class="card-body">
    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.ID_OR_REMARKS')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{id_or_remarks}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.YOUR_SITE_ID')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.site_id_customer_assigned}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.TYPE')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.product_code}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.SUBSCRIBED_BANDWIDTH')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.vpn_port_bandwidth}}</p>
      </div>
    </div>

    <app-ui-folder-item [model]="info"></app-ui-folder-item>
  </div>
</div>

<div class="card" *ngIf="parent.customFields.type === 'internet'">
  <div class="card-body">

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.ID_OR_REMARKS')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{id_or_remarks}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.SERVICE')}} </p>
      </div>
      <div class="col">
        <p class="noMargin"> {{model.customFields.display_name_of_service_id}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.SUBSCRIBED_BANDWIDTH')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.bandwidth_string}}</p>
      </div>
    </div>
    <app-ui-folder-item [model]="info"></app-ui-folder-item>
  </div>
</div>

<div class="card" *ngIf="parent.customFields.type === 'datahouse'">
  <div class="card-body">
    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.DATA_CENTER')}} </p>
      </div>
      <div class="col">
        <p class="noMargin"> {{model.customFields.location}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.RACK_NUMBER_OR_REMARKS')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{rack_number_or_remarks}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.SUBSCRIBED_POWER')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.basic_porwer_consumption}}</p>
      </div>
    </div>
    <app-ui-folder-item [model]="info"></app-ui-folder-item>
  </div>
</div>


<div class="card" *ngIf="parent.customFields.type === 'cloud'">
  <div class="card-body">

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.SERVICE')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.service}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.LOCATION')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.location}}</p>
      </div>
    </div>

    <app-ui-folder-item [model]="info"></app-ui-folder-item>

  </div>
</div>


<div class="card" *ngIf="parent.customFields.type === 'security'">
  <div class="card-body">

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.DEVICE')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.name}}</p>
      </div>
    </div>

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.IP_ADDRESS')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.ip}}</p>
      </div>
    </div>

  </div>
</div>


<div class="card" *ngIf="parent.customFields.type === 'maintenance' || parent.customFields.type === 'maintenanceResolved'">
  <div class="card-body">

    <div class="row">
      <div class="col-4 label">
        <p class="noMargin"> {{translateText('MC_DB.EMAIL_SUBJECT')}} </p>
      </div>
      <div class="col">
        <p class="noMargin">{{model.customFields.emailInfo.subject}}</p>
      </div>
    </div>


    <app-ui-folder-item [model]="info"></app-ui-folder-item>

  </div>
</div>
