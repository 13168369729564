<div class="hyper-link-container">
  <div class="hyper-link-send-message">
    <div class="row" (click)="sendText(model.action)">
      <div class="col-1">
        <span class="fa fa-chevron-right" title="chevron right" aria-hidden="true" style="margin-left:1rem"></span>
      </div>
      <div class="col content">
        <span [innerHtml]="model.content"></span>
      </div>
    </div>
  </div>
</div>
