import { Injectable } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import i18next from 'i18next';
import { CookieService } from 'ngx-cookie';
import { LoggerService } from './logger.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from './session.service'
import { environment } from '../../environments/environment';

@Injectable()
export class TranslationService {

  public $instance = new Subject<any>();
  private EN: any = {};
  private CN: any = {};
  private ZH: any = {};
  private JP: any = {};
  private ET: any = {};
  private RU: any = {};

  constructor(
    private cookieService: CookieService,
    private logger: LoggerService,
    private http: HttpClient,
    private sessionService: SessionService,
  ) {
    // this.init()
    i18next.on('languageChanged', () => {
      // this.updateContent();
    });
  }

  async init() {
    let glossary = await this.getGlossary()
    for (let lang in glossary) {
      this[lang] = glossary[lang]
    }
  }

  async getGlossary() {
    try {
      let cms = await this.http.get(`${environment.serverUrl}/api/general/settings/mapping_table`).toPromise()
      let uiText = await this.http.get(`${environment.serverUrl}/api/i18n/locale`).toPromise()
      for (let lang in uiText) {
        uiText[lang] = Object.assign(uiText[lang], { KEY_VALUE: cms[lang] })
      }
      return uiText

    } catch (e) {
      this.logger.error(e)
      throw new Error(e)
    }
  }

  async initLanguage(lang) {
    await this.init()
    await i18next
      .init({
        lng: lang,
        // debug: true,
        resources: {
          EN: {
            translation: this.EN
          },
          CN: {
            translation: this.CN
          },
          ZH: {
            translation: this.ZH
          },
          JP: {
            translation: this.JP
          },
          ET: {
            translation: this.ET
          },
          RU: {
            translation: this.RU
          }
        }
      }, (err, t) => {
        if (err)
          this.logger.error(err);
      });
    return
  }

  updateContent() {
    var keys = Object.keys(i18next.store.data.en.translation)
    keys.forEach((key) => {
      var txed = i18next.t(key);
      this.$instance.next({ key: key, value: txed });
    })
  }

  isInitialised() {
    return i18next.isInitialized;
  }

  changeLng(lang: string) {
    lang = lang.toUpperCase();
    this.cookieService.put('lang', lang);
    i18next.changeLanguage(lang);
    this.$instance.next({ lang: lang });
  }

  translateText(key: string): string {
    return i18next.t(key);
  }

  translateIndex(key_index: string) {
    return i18next.t("KEY_VALUE." + key_index);
  }

}
