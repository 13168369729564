import { Component, OnInit, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { LoggerService } from '../services/logger.service';

@Component({
  selector: 'app-ui-models',
  templateUrl: './ui-models.component.html',
  styleUrls: ['./ui-models.component.css']
})
export class UiModelsComponent implements OnInit {

  @Input() model: any;
  @Input() context: any;
  @Input() parent: any;
  @Output() emitEvent = new EventEmitter<any>();

  constructor(
    private elementRef: ElementRef,
    private logger: LoggerService,
  ) { }
  ngAfterViewInit() {
    // this.logger.info(this.elementRef.nativeElement.parentElement.offsetHeight)
  }

  ngOnInit() {

  }

  parseEmitEvent($event: any) {
    this.logger.info($event, this.parent)
    if ($event.text) {
      if ($event.condition) {
        this.parent.context = Object.assign(this.parent.context, $event.condition)
      }
      this.emitEvent.next({
        text: $event.text,
        messageObj: this.parent,
        customFields: $event.customFields,
        isButton: $event.isButton,
        actions: this.parent.actions
      })
    } else {
      this.emitEvent.emit({ text: $event, messageObj: this.parent })
    }
  }

}
