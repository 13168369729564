<div class="card" style="padding: 15px;">
  <ng-container *ngFor="let child of model.child">
    <div class="row">
      <div class="col-sm-8">
        <app-ui-text *ngIf="child.type === 'text'" [model]="child"></app-ui-text>
        <app-ui-folder *ngIf="child.type === 'folder'" [model]="child" (emitEvent)="parseEmitEvent($event)"></app-ui-folder>
      </div>
      <div *ngIf="child.type ==='img'" class="col-sm-4">
        <img src="/assets/images/customer-64px.png" />
      </div>
    </div>
    <!-- <div class="center">
      <app-ui-ranking *ngIf="child.type ==='ranking'" [model]="model"></app-ui-ranking>
    </div> -->
  </ng-container>
  <br/>
</div>
