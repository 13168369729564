import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ui-list',
  templateUrl: './ui-list.component.html',
  styleUrls: ['./ui-list.component.css']
})
export class UiListComponent implements OnInit {

  @Input() model: any;
  @Input() context: any;
  @Output() emitEvent = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  parseEmitEvent($event:string){
    this.emitEvent.next($event)
  }


}
