import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from "rxjs";
import { LoggerService } from '../../services/logger.service';
import { ChatboxService } from '../../services/chatbox.service';
import { SessionService } from '../../services/session.service';
import { DebounceService } from '../../services/debounce.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-chatboxpanel',
  templateUrl: './chatboxpanel.component.html',
  styleUrls: ['./chatboxpanel.component.css']
})
export class ChatboxpanelComponent implements OnInit {

  chatboxSubscription: Subscription;
  channel: string = "";
  isBackground = false;
  subscriptionsList: Array<any> = [];
  doingDebounce: boolean = false;
  public skin: string = '';

  constructor(
    private chatboxService: ChatboxService,
    private logger: LoggerService,
    private elementRef: ElementRef,
    private sessionService: SessionService,
    private debounceService: DebounceService,
  ) {

  }

  ngOnInit() {
    this.channel = this.sessionService.getSessionChannel();
    this.skin = this.sessionService.getSkin()
    let ele: any = document.getElementsByTagName("html")[0];
    if(this.skin === 'mc'){
      ele.style.backgroundColor = "#FAF9F3";
    }else{
      ele.style.backgroundColor = "#f7f7f7";
    }

    this.subscriptionsList.push(this.chatboxService.$isBackgroundMode.subscribe((res) => {
      this.isBackground = res;
    }));

    this.subscriptionsList.push(this.chatboxService.$chatRecord.subscribe((res) => {
      this.logger.info(res);

      if (this.chatboxService.chatRecord.length < 2)
        return
      if (res.fromMe) {
        this.scrollToBottom(true);
      } else {
        if (!this.doingDebounce) {
          this.doingDebounce = true;
          this.debounceService.debounce(() => {
            if (res.bottomMost) {
              this.scrollToBottom(true);
            } else {
              this.scrollToBottom(false);
            }
            this.doingDebounce = false;
          }, 500, true)()
        }
      }

    }))
  }

  //bottomMost
  scrollToBottom(bottomMost: boolean) {
    try {
      let ele: any = this.elementRef.nativeElement.querySelector('.aimchatbot-main');

      if (!ele) {
        setTimeout(() => {
          this.scrollToBottom(bottomMost);
        }, 500)
        return;
      }

      setTimeout(() => {
        if (bottomMost) {
          // document.documentElement.scrollTop = ele.scrollHeight;
          ele.scrollTop = ele.scrollHeight;
        } else {
          // document.documentElement.scrollTop += 150;
          ele.scrollTop += 150;
        }
      }, 150)
      setTimeout(() => {
        if (bottomMost) {
          // document.documentElement.scrollTop = ele.scrollHeight;
          ele.scrollTop = ele.scrollHeight;
        } else {
          // document.documentElement.scrollTop += 150;
          ele.scrollTop += 150;
        }
      }, 300)
    } catch (err) {
      this.logger.error(err);
    } finally {

    }
  }

  ngOnDestroy() {
    this.subscriptionsList.forEach((subscriber: any) => {
      subscriber.unsubscribe();
    })
  }

  isCustomerOrPD() {
    return this.channel === "cpc" || this.channel === "cec";
  }


}
