import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ComponentRef } from '@angular/core'

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);

// platformBrowserDynamic().bootstrapModule(AppModule).then((ng2ModuleInjector: any) => {
//   let ng2Loader = new DynamicNg2Loader(ng2ModuleInjector);
//   let container = document.getElementById('angular-container');
//   // container.classList += "js-flex-container";
//   document.getElementById('non-angular').hidden = false;
// });
