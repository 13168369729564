import { Component, OnInit } from '@angular/core';
import {
  ModalService,
  ChatboxService
} from '../../services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeRequestFormConnectivityContentComponent } from '../change-request-form-connectivity-content'
import { ChangeRequestFormTrustContentComponent } from '../change-request-form-trust-content'
import { ChangeRequestFormCloudContentComponent } from '../change-request-form-cloud-content'

@Component({
  selector: 'app-change-request-form-modal',
  templateUrl: './change-request-form-modal.component.html',
  styleUrls: ['./change-request-form-modal.component.scss']
})
export class ChangeRequestFormModalComponent implements OnInit {

  private subscribers: any = {};
  private currentModal: any;
  constructor(
    private modalService: ModalService,
    private chatboxService: ChatboxService,
    private ngbModal: NgbModal
  ) {
    this.init()
  }

  async init() {
    // setTimeout(() => {
    //   this.modalService.openModal('changeRequest-cloud')
    // }, 2000)
    this.subscribers['modal'] = this.modalService.$modals.subscribe((modal: any) => {
      if (modal.event === 'open') {
        if (modal.name.indexOf('changeRequest') > -1) {
          this.chatboxService.$isBackgroundMode.next(true)
          switch (modal.name) {
            case 'changeRequest-connectivity':
              this.currentModal = this.ngbModal.open(ChangeRequestFormConnectivityContentComponent,
                { size: 'lg', centered: true, keyboard: false, backdrop: 'static' });
              break;
            case 'changeRequest-trust':
              this.currentModal = this.ngbModal.open(ChangeRequestFormTrustContentComponent,
                { size: 'lg', centered: true, keyboard: false, backdrop: 'static' });
              break
            case 'changeRequest-cloud':
              this.currentModal = this.ngbModal.open(ChangeRequestFormCloudContentComponent,
                { size: 'lg', centered: true, keyboard: false, backdrop: 'static' });
              break
          }
        }

      } else if (modal.event === 'close') {
        this.chatboxService.$isBackgroundMode.next(false);
        if (this.currentModal)
          this.currentModal.close();
      }
    })
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    for (let subscriber in this.subscribers) {
      let sub: any = subscriber
      sub.unsubscribe()
    }
  }

}
