import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  ModalService,
  ChatboxService,
  SessionService,
  TranslationService,
} from '../../services'

@Component({
  selector: 'app-followup-modal',
  templateUrl: './followup-modal.component.html',
  styleUrls: ['./followup-modal.component.css']
})
export class FollowupModalComponent implements OnInit {
  private currentModal: any;
  @ViewChild('followupModal') followupModal: ElementRef;
  content = '';
  topic: string = '';
  description: string = '';
  followUpOptions: any = [];
  type: string = '';
  reply_placeholder: string = '';
  reply: string = ''
  invalid_reply: boolean = true;
  no_content: boolean = true;
  isDoingSubmit: boolean = false;

  constructor(
    private ngbModal: NgbModal,
    private modalService: ModalService,
    private chatboxService: ChatboxService,
    private sessionService: SessionService,
    private translationService: TranslationService,
  ) {
  }

  ngOnInit() {
    this.modalService.$modals.subscribe((modal: any) => {
      this.initTranslation()
      if (modal.event === 'open') {
        let user = this.sessionService.getUserSession()
        if (modal.name === 'followup_office_hour') {

          this.description = this.translationService.translateIndex(`${user.channel}_follow_up_out_of_office_hour`.toUpperCase())
          this.currentModal = this.ngbModal.open(this.followupModal, { centered: true, keyboard: false, backdrop: 'static' });
        } else if (modal.name === 'followup_max_queue') {
          this.description = this.translationService.translateIndex(`${user.channel}_follow_up_max_queue`.toUpperCase())
          this.currentModal = this.ngbModal.open(this.followupModal, { centered: true, keyboard: false, backdrop: 'static' });
        }
        this.sessionService.pauseTimeout();
      } else if (modal.event === 'close') {
        if (this.currentModal)
          this.currentModal.close();
      }
    })
  }

  initTranslation() {
    if (!this.translate('FOLLOWUP_MODAL.CHOICE')) {
      return setTimeout(() => {
        this.initTranslation()
      }, 1000)
    }
    this.generateDropdownOptions()
  }

  generateDropdownOptions() {
    const user = this.sessionService.getUserSession()
    switch (user.channel) {
      case 'cpc':
      case 'cec':
        this.followUpOptions = [{
          title: this.translate('FOLLOWUP_MODAL.CHOICE'),
          value: '',
          disabled: true,
        },
        {
          title: this.translate('FOLLOWUP_MODAL.OPTION_FAULT'),
          value: 'fault'
        },
        {
          title: this.translate('FOLLOWUP_MODAL.OPTION_ENQUIRY'),
          value: 'enquiry'
        },
        {
          title: this.translate('FOLLOWUP_MODAL.OPTION_OTHERS'),
          value: 'others'
        }]
        return
      case 'corp-cpc':
      case 'corp-cec':
        this.followUpOptions = [{
          title: this.translate('FOLLOWUP_MODAL.CHOICE'),
          value: '',
          disabled: true,
        },
        {
          title: this.translate('FOLLOWUP_MODAL.OPTION_PRODUCTS_SERVICES'),
          value: 'product_services'
        },
        {
          title: this.translate('FOLLOWUP_MODAL.OPTION_NEW_OFFERINGS'),
          value: 'new_offering'
        },
        {
          title: this.translate('FOLLOWUP_MODAL.OPTION_TROUBLESHOOT'),
          value: 'troubleshoot'
        },
        {
          title: this.translate('FOLLOWUP_MODAL.OPTION_CONTACT_US'),
          value: 'contact_us'
        },
        {
          title: this.translate('FOLLOWUP_MODAL.OPTION_ABOUT_COMPANY'),
          value: 'about_company'
        }]
        return
    }
  }

  change_placeholder($event) {
    this.reply_placeholder = this.type
  }

  validator(field, input) {
    let type = this.type
    this[field] = input

    if (field == 'reply') {
      if (type == 'email') {
        var regex = new RegExp('^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$', 'i')
        if (regex.test(input)) this.invalid_reply = false
        else this.invalid_reply = true
      } else if (type == 'phone') {
        this.invalid_reply = input.length < 8
      }
    }

    if (field == 'content') {
      if (input.length != 0) this.no_content = false
      else this.no_content = true
    }
  }

  disable() {
    return this.no_content || this.invalid_reply
  }

  disconnect() {
    this.chatboxService.$isDisabled.next(true);
    this.chatboxService.$isBackgroundMode.next(false);
    this.sessionService.closeSession();
    if (this.currentModal)
      this.currentModal.close();
    this.modalService.openModal('disconnect');
  }

  continue() {
    this.content = ''
    this.type = ''
    this.reply = ''

    this.sessionService.resetTimeout();
    this.currentModal.close();
    this.chatboxService.$isBackgroundMode.next(false);
  }

  async submit() {
    if (!this.isDoingSubmit) {
      this.isDoingSubmit = true
      let send_obj = {
        topic: this.topic.toLowerCase(),
        message: this.content,
        contactType: this.type.toLowerCase(),
        contactMethod: this.reply
      }

      await this.chatboxService.sendFollowup(send_obj)
      this.resetForm()
      this.sessionService.resetTimeout();
      this.currentModal.close();
      this.chatboxService.$isBackgroundMode.next(false);
    }
  }

  resetForm() {
    this.content = '';
    this.type = '';
    this.topic = '';
    this.reply = '';
    this.isDoingSubmit = false;
  }

  translate(key: string) {
    return this.translationService.translateText(key);
  }

}
