
import { throwError as observableThrowError, Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators'
import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { environment } from '../../environments/environment'
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private sessionService: SessionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const cid: string = this.sessionService.getConversationID()
    const token: string = this.sessionService.getSessionToken();
    if (cid && request.url.indexOf(environment.serverUrl) > -1) {
      request = request.clone({
        withCredentials: true,
        setHeaders: {
          Authorization: `Bearer ${token}`,
          cid: `${cid}`,
        },
      });
    } else {
      request = request.clone({
      });
    }
    return next.handle(request).pipe(tap(event => {
      if (event instanceof HttpResponse) {
      }
    }), catchError(error => {
      console.error('ERROR STATUS', error.status);
      return observableThrowError(error);
    }))
  }

  private get router(): Router {
    return this.injector.get(Router);
  }

}
