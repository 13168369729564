import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  LoggerService
} from '../../services';


@Component({
  selector: 'app-ui-folder',
  templateUrl: './ui-folder.component.html',
  styleUrls: ['./ui-folder.component.css']
})
export class UiFolderComponent implements OnInit {

  constructor(private logger: LoggerService) { }

  @Input() model: any;
  @Input() parent: any;
  @Input() context: any;
  @Output() emitEvent = new EventEmitter<any>();

  ngOnInit() {
    this.logger.info(this.model)
    if (!this.parent)
      this.parent = { context: {} }
  }
  parseEmitEvent($event: any) {
    if ($event.text)
      this.emitEvent.next({ text: $event.text, isButton: $event.isButton })
    else
      this.emitEvent.next($event)
  }

}
