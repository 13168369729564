import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { LoggerService } from './logger.service'

interface IWindow extends Window {
  webkitSpeechRecognition: any;
  SpeechRecognition: any;
}

@Injectable()
export class MicrophoneService {

  constructor(
    private zone: NgZone,
    private cookieService: CookieService,
    private logger: LoggerService,
  ) {
  }

  // The Mic module is designed to handle
  // the microphone behaviour
  speechRecognition: any;
  compatible: boolean = true;

  record(): Observable<string> {

    return Observable.create(observer => {
      const { webkitSpeechRecognition, navigator }: IWindow = <IWindow>window;
      this.speechRecognition = new webkitSpeechRecognition();
      this.speechRecognition.continuous = true;
      this.speechRecognition.interimResults = true;
      var lang = this.cookieService.get("lang") || "ZH";
      if (this.cookieService.get("isTW")) {
        lang = 'ZHTW'
      }

      if (lang == 'ZH') {
        this.speechRecognition.lang = 'yue-Hant-TW';
      } else if (lang == 'CN') {
        this.speechRecognition.lang = 'cmn-Hans-CN';
      } else if (lang == 'ZHTW') {
        this.speechRecognition.lang = 'cmn-Hant-TW';
      } else {
        this.speechRecognition.lang = 'en-US';
      }
      // this.speechRecognition.maxAlternatives = 1;

      this.speechRecognition.onresult = speech => {
        let term: string = "";
        // this.logger.info(speech.results[0][0].transcript);
        term = speech.results[0][0].transcript;
        // if (speech.results) {
        //   var result = speech.results[speech.resultIndex];
        //   this.logger.info(result);
        //   var transcript = result[0].transcript;
        //   if (result.isFinal) {
        //     if (result[0].confidence < 0.3) {
        //       // this.logger.info("Unrecognized result - Please try again");
        //     }
        //     else {
        //       // term = _.trim(transcript);
        //       term = transcript;
        //       // this.logger.info("Did you said? -> " + term + " , If not then say something else...");
        //     }
        //   }
        // }
        this.zone.run(() => {
          observer.next(term);
        });
      };

      this.speechRecognition.onerror = error => {
        observer.error(error);
        this.DestroySpeechObject();
      };

      this.speechRecognition.onend = () => {
        observer.complete();
      };

      this.speechRecognition.start();
    });
  }

  DestroySpeechObject() {
    if (this.speechRecognition)
      this.speechRecognition.stop();
  }

  checkCompatibility() {
    if (navigator.vendor !== 'Google Inc.') {
      this.logger.warn(navigator.vendor + " speech to text not supported!");
      return false;
    } else {
      return true;
    }
  }
}
