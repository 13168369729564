import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-ui-button',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.css']
})
export class UiButtonComponent implements OnInit {

  @Input() model: any;
  @Input() parent: any;
  @Output() emitEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    if (!this.model.customFields)
      this.model.customFields = {}
  }

  sendText($event: string) {
    this.emitEvent.next({
      customFields: this.model.customFields,
      text: $event, isButton: true,
      condition: this.model.customFields.condition
    })
  }

}
