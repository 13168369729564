import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from './session.service';
import { LoggerService } from './logger.service';
import { SocketService } from './socket.service';
import { ModalService } from './modal.service'
import { DebounceService } from './debounce.service'
import { MessageDecoderService } from './message-decoder.service'
import { LivechatService } from './livechat.service'
import * as _ from 'underscore'

@Injectable()
export class ChatboxService {

  public $chatRecord = new Subject<any>();
  public $isDisabled = new Subject<any>();
  public $isLoading = new Subject<any>();
  public $soundOn = new Subject<any>();
  public $isBackgroundMode = new Subject<any>();
  public chatRecord: Array<any> = [];
  private responsePayload: any;
  private init: boolean = false;
  private isTyping: boolean = false;
  private parsedArray: Array<any> = [];
  private doingDebounce = false;
  private livechatDebounce = false;
  constructor(
    private http: HttpClient,
    private sessionService: SessionService,
    private logger: LoggerService,
    private socketService: SocketService,
    private modalService: ModalService,
    private debounceService: DebounceService,
    private messageDecoderService: MessageDecoderService,
    private livechatService: LivechatService,
  ) {
    this.initSocket()
  }

  async initSocket() {
    await this.socketService.init()
    this.sendRequest({ content: 'hello', type: 'initial' })
    let userObj = this.sessionService.getUserSession();
    this.socketService.getInstance().subscribe(async (res: any) => {
      this.sessionService.setLiveChat(true);
      this.logger.info(res);
      if (res) {
        if (res.sender && res.username) {
          this.livechatService.addOperators({ name: res.sender, username: res.username });
        }
        if (res.type === 'text' || res.type === 'file') {
          res.liveChat = true;
          res.fromMe = false;
          this.add(res);
        }

        if (res.content) {
          res.content = res.content.replace(/\n|\\n/gi, '<br />')
        }

        if ('videoCall' === res.event) {
          if ('start' === res.type) {
            this.sessionService.pauseTimeout();
            // if (!res.isApp) {
            //   this.livechatService.$videoCallInstance.next(res)
            // } else {
            let url = await this.livechatService.getVideoCallURL(res.token)
            this.add({
              output: {
                json: [{
                  content: `請<a class = "hyperlinks" onclick="navigateExternal('${url}')">按此</a>啟動視頻。`,
                  type: 'text',
                  isCoreComponent: false,
                  customFields: {
                    dissapearOnClick: true
                  }
                }]
              },
              fromMe: false,
            })
            // }
          } else {
            this.sessionService.resetTimeout();
            if (!res.isApp) {
              this.livechatService.$videoCallInstance.next(null)
            }
          }
          // if (res.isApp) {
          //   if (parent && parent.window && typeof parent.window.postMessage === 'function') {
          //     parent.window.postMessage(JSON.stringify({
          //       type: `native-webrtc-${res.type}`,
          //       conversationId: userObj.conversationID,
          //       sender: res.sender,
          //       token: res.token,
          //       appId: null,
          //     }), '*')
          //   }
          //   window.postMessage(JSON.stringify({
          //     type: `native-webrtc-${res.type}`,
          //     conversationId: userObj.conversationID,
          //     sender: res.sender,
          //     token: res.token,
          //     appId: null,
          //   }), '*')
          // }

        } else if (res.event === 'timeout' || res.event === 'closeEvent' || res.event === 'close') {
          // set the state to timeout and doesn't allow continue,
          this.$isDisabled.next(true);
          this.sessionService.$isLiveChat.next('ended');

          if (Object.keys(this.livechatService.operators).length > 0) {
            this.modalService.openModal('review')
          }
          this.sessionService.setLiveChat(false);
          this.sessionService.setIsQueing(false);
          this.modalService.closeModal('followup_max_queue');
          this.modalService.closeModal('followup_office_hour');

          let resp = await this.sessionService.closeSession()
          // this.logger.info(resp)
          this.add(this.messageDecoderService.generateDisconnectTypeComponent())
        } else if (res.event === 'isBotOn') {
          if (!res.isBotOn) {
            this.sessionService.setIsQueing(false);
            this.sessionService.$isLiveChat.next(true);
          } else {
            this.sessionService.setLiveChat(false);
            this.sessionService.$isLiveChat.next(false);
          }
        } else if (res.event === 'liveChatResult') {
          let statusCheck = res.status
          if (statusCheck === 0) { // same userID is already in queue
            this.sessionService.setLiveChat(false); // defaults to false
            this.sessionService.setIsQueing(false);
          } else if (statusCheck === 1) { // success
            this.sessionService.setLiveChat(true);
            this.sessionService.setIsQueing(true);
          } else if (statusCheck === 2) { // out of office hours
            this.sessionService.setLiveChat(false);
            this.sessionService.setIsQueing(false);
            this.modalService.openModal('followup_office_hour');
          } else if (statusCheck === 3) { // max queue
            this.sessionService.setLiveChat(false);
            this.sessionService.setIsQueing(false);
            this.modalService.openModal('followup_max_queue');
          }
        } else if (res.event === 'followupResponse') {
          this.sessionService.setLiveChat(false);
          this.sessionService.setIsQueing(false);
        }
      }
    })
  }
  getContext(): string {
    return this.responsePayload.context.action
  }

  add(params: any) {
    if (params) {
      params.timestamp = Math.floor(<any>new Date());
    }
    if (params.content || params.output) {
      this.$chatRecord.next(params);
    }
  }

  async sendRequest(messageObj: any) {

    if (!this.doingDebounce) {
      this.$isLoading.next(true);
      this.doingDebounce = true;

      if (this.sessionService.getisDisconnected()) {
        this.add(this.messageDecoderService.generateDisconnectTypeComponent())
        return;
      }

      this.debounceService.debounce(async () => {
        // this.logger.info('doing debounce')
        this.doingDebounce = false;

        let memory = messageObj.memory;
        let lang = this.sessionService.getLanguage();
        lang = lang.toUpperCase();
        let alternate_intents = true;
        let responsePayload = this.responsePayload;
        // this.logger.info(responsePayload);
        let url = environment.serverUrl + '/api/message';
        let userObj = this.sessionService.getUserSession();

        if (!messageObj.id) {
          messageObj.id = `text-${this.chatRecord.length}`
        }
        if (!messageObj.status) {
          messageObj.status = `pending`
        }

        if (!this.init) {
          responsePayload = {
            'entities': [],
            'input': { 'text': '' },
            'output': { 'text': [], 'nodes_visited': [], 'log_messages': [] },
            'context': {
              'userName': userObj.userName,
              'userID': userObj.userID,
              'conversation_id': userObj.conversationID,
              'channel': this.sessionService.getSessionChannel(),
              'lang': lang,
              'rootNode': true,
              'system': { 'dialog_stack': [{ dialog_node: 'root' }], 'dialog_turn_counter': 1, 'dialog_request_counter': 1, '_node_output_map': {} }
            }
          }
          this.responsePayload = responsePayload;
          if (this.chatRecord.length === 1) {
            this.add(messageObj);
          }
          this.init = true;
        } else {
          messageObj.fromMe = true;
          if (!messageObj.isMainMenu)
            this.add(messageObj);
        }

        // Build request payload
        let payload: any = {
          alternate_intents: alternate_intents
        };

        //get the context from last response
        if (responsePayload) {
          payload.context = responsePayload.context;
          if (payload.context) {
            payload.context.memory = memory;
            payload.context.lang = lang;
            payload.intents = messageObj.intents;
            payload.context.isButton = messageObj.isButton;
            payload.context.resetAll = messageObj.resetAll;
          }
          payload.input = {
            text: (messageObj.content ? messageObj.content.trim() : (messageObj.input.text ? messageObj.input.text.trim() : ''))
          };
        }

        if (messageObj.actions) {
          switch (messageObj.actions[0].name) {
            case 'runGetAllServices':
              payload.context.selectedServiceItem = payload.input.text
              break;
            case 'getServiceItems':
              payload.context.selectedSubServiceItem = messageObj.customFields
              if (messageObj.customFields && messageObj.customFields.type === 'others') {
                payload.context.selectedLocationServiceItem = messageObj.customFields
              }
              break;
            case 'getLocationFilteredServiceItems':
              payload.context.selectedLocationServiceItem = messageObj.customFields
              break;
          }
        }

        if (this.sessionService.getLiveChat())
          payload.liveChat = true;

        try {
          let res: any = await this.http.post(url, payload).toPromise()
          console.log(res)
          let updated = _.find(this.chatRecord, { id: messageObj.id })
          if (updated) {
            updated.status = 'success';
          }
          if (messageObj.isMainMenu) {
            res.bottomMost = true
          }
          // this.add(this.messageDecoderService.generateLineTypecomponent(res.context.sys_banking_services))
          this.responsePayload = res;
          // this.logger.info(res)

          this.$isLoading.next(false);

          if (this.chatRecord.length === 0)
            res.type = 'initial';
          this.add(res);
          if (res.context.disableInput) {
            this.$isDisabled.next(true);
          } else {
            this.$isDisabled.next(false);
          }
          setTimeout(() => {
            this.parseMessageToLiveChat()
          }, 500)
        } catch (e) {
          let updated = _.find(this.chatRecord, { id: messageObj.id })
          if (updated) {
            updated.status = 'failed';
          }
          this.$isLoading.next(false);
          // this.add(this.messageDecoderService.generateDisconnectTypeComponent())
          // this.$isDisabled.next(true);
        }

      }, 400, false)();
    }
  }

  requestLiveChat() {
    return new Promise((resolve, reject) => {
      if (!this.livechatDebounce) {
        this.livechatDebounce = true;
        this.debounceService.debounce(() => {
          this.livechatDebounce = false;
          let url = environment.serverUrl + '/api/chat/start';
          this.sessionService.setLiveChat(true);
          this.sessionService.setIsQueing(true);

          this.http.post(url, {})
            .subscribe((res: any) => {
              this.sessionService.resetTimeout();
              this.sessionService.pauseTimeout();
              resolve();
              // this.logger.info(JSON.stringify(res, null, 2));
            })
        }, 400, false)();
      }
    })

  }

  async parseMessageToLiveChat() {

    let index = this.chatRecord.length

    if (this.chatRecord[index - 1])
      if (this.chatRecord[index - 1].liveChat) {
        this.chatRecord[index - 1].sent = true
        return;
      }

    if (this.sessionService.getLiveChat() && this.chatRecord[index - 1].fromMe) {
      if (this.chatRecord[index - 1].type === 'text') {
        this.chatRecord[index - 1].sent = true
        return;
      }
    }

    let url = environment.serverUrl + '/api/chat/send';
    let userObj = this.sessionService.getUserSession();
    for (let i = 0; i < this.chatRecord.length; ++i) {
      let row = this.chatRecord[i]
      if (row.sent || row.liveChat) {
        continue
      } else {
        let res = await this.getChild(row)
        res = _.uniq(res)
        let messageObj: any = {};
        let fromChannel = 'webchat'
        let isButton = false
        row.sent = true
        // this.logger.info(row, res)
        messageObj.fromMe = row.fromMe
        messageObj.type = row.type
        messageObj.content = res
        if (row.context) {
          if (this.chatRecord[i - 1] && this.chatRecord[i - 1].fromMe) {
            if (this.parsedArray[this.parsedArray.length - 1]) {
              if (row.context.toneShouldAlert)
                this.parsedArray[this.parsedArray.length - 1].tones = Object.keys(row.context.toneShouldAlert)
            }
          }
        }
        this.parsedArray.push(messageObj)
      }
    }
    this.logger.info(this.parsedArray);
    await this.http.post(url, { messageArray: this.parsedArray }).toPromise()
    this.parsedArray = [];
  }

  async getChild(row) {
    let childArray = []
    let components = []
    if (!row.output && row.child) {
      if (row.child.length > 0) {
        components = row.output
      }
    } else if (!row.output) {
      components = row
    } else {
      components = row.output
    }

    if (!components) {
      components = []
    }

    for (let component of components) {
      // this.logger.info(component)
      if (component.child) {
        // this.logger.info('child', component.child)
        let parentStr = this.generateResponseFromComponent(row, component)
        if (parentStr)
          childArray.push(parentStr);
        let cComponent = await this.getChild(component.child)
        // this.logger.info(cComponent)
        let str = this.generateResponseFromComponent(component, cComponent)
        if (str)
          childArray.push(str);
        if (cComponent)
          childArray = childArray.concat(cComponent);
      } else if (component.type === 'image' || component.type === 'file-others') {
        childArray.push({ type: component.type, content: component.content, subcontent: component.subcontent });
      } else {
        // this.logger.info(row, component)
        let str = this.generateResponseFromComponent(row, component)
        if (str)
          childArray.push(str);
      }
    }
    return childArray
  }

  generateResponseFromComponent(parent, component) {
    let str = ''
    let type = component.type

    if (parent.isButton) {
      if (type === "text")
        type = "button"
    }

    if (component.customFields) {
      str += `${type} - `

      for (let key in component.customFields) {
        switch (key) {
          default:
            if (component.customFields[key])
              str += ` ${key} : ${component.customFields[key]} `;
        }
      }
    }

    if (component.content) {
      if (!str)
        str += `${type} - `
      str += ` ${component.content}`
      if (component.subcontent)
        str += ` & ${component.subcontent}`;
    }
    return str
  }

  async triggerTypingEvent() {
    if (!this.isTyping) {
      this.isTyping = true;
      let url = environment.serverUrl + '/api/chat/typing';
      let resp = await this.http.put(url, {}).toPromise()

      setTimeout(() => {
        this.isTyping = false
      }, 2500)
    }
  }

  disconnectLiveChat(reason: string) {
    this.sessionService.setLiveChat(false);
    let url = environment.serverUrl + '/api/chat/send';
    let params = {
      event: 'close',
      reason: reason
    }
    return this.http.post(url, params).toPromise()
  }

  cancelLiveChat() {
    this.sessionService.setLiveChat(false);
    this.chatRecord.forEach(record => {
      record.sent = false;
    })
    let url = environment.serverUrl + '/api/chat/send';
    let params = {
      event: 'cancel',
    }
    this.sessionService.$isLiveChat.next(false)
    return this.http.post(url, params)
  }

  getSystemStatus() {
    let url = environment.serverUrl + '/api/message/status';
    return this.http.get(url)
  }

  isProd(): boolean {
    return environment.production === true;
  }

  //function sending followup modal data
  sendFollowup(send_obj) {
    let url = environment.serverUrl + '/api/chat/followup';
    return this.http.post(url, send_obj).toPromise()
  }
}
